import {React, useState} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { images } from "../../../constants/icons";
import { Twirl as Hamburger } from "hamburger-react";
import Modal from "../../../containers/modals/Modal";

const DashboardHeader = ({ isOpen, setOpen }) => {
  const [showModal, setShowModal] = useState(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      searchValue: "",
    },
  });

  const {
    formState: { errors },
  } = methods;

  /* Handle submit */
  const onSubmit = async (payload, event) => {
    event?.stopPropagation();
  };



  return (
    <header className=" xl:flex items-center xl:justify-between xl:pb-[14px]">
      <div className="hidden lg:block">
        <p className="text-black font-bold text-lg">Hello Segun 👋</p>
        <p className="text-xs text-[#999999] font-[28px] font-Montserrat">
          Let’s check your stats todays
        </p>
      </div>
      <div className="hidden w-[60%] xl:flex items-center justify-end ">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="mr-[12px] "
          >
            <div className="flex border-solid   bg-white  rounded-lg border-white border-2  p-2 items-center ">
              <input
                name="search"
                type="search"
                onError={errors}
                placeholder="Type here..."
                className="pl-[10px] bg-transparent pr-[10px] py-[5px] !w-[288px] font-Poppins text-[#DCDCDC] text-[10px] rounded-lg placeholder-primary::placeholder hover:bg-white  cursor-pointer appearance-none focus:outline-none "
              />

              <div className="w-[18px]">
                <img src={images.searchIcon} alt="" className="w-[100%]" />
              </div>
            </div>
          </form>
        </FormProvider>

        <div className="flex items-center mr-[12px] w-[40px]">
          <button onClick={() => alert("nofified")}>
            <img src={images.nofityIcon} alt="" />
          </button>
        </div>
        <div className="flex items-center w-[40px]">
          <button onClick={() => setShowModal(true)}>
            <img src={images.powerIcon} alt="" />
          </button>
        </div>
        
        {showModal && <Modal setOpenModal={setShowModal} />}
      </div>
      <div className="xl:hidden fixed left-0">
        <Hamburger
          color="#0b211a"
          size={20}
          label="Show menu"
          toggled={isOpen}
          toggle={setOpen}
        />
      </div>
    </header>
  );
};

export default DashboardHeader;
