import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { msgImages } from "../../constants/images";

import { imageLoader } from "../../utils/helpers";

const Schedule = ({data}) => {

  const navigate = useNavigate();

  return (
    <div>
      {data?.slice(0, 5).map((item) => (
        <div
          className="flex space-x-4 rounded-lg justify-between w-[318px] mb-4 bg-white items-center cursor-pointer"
          key={item}
          onClick={() =>
            navigate(`/health-worker-appointment/${item?.contract_id}`)
          }
        >
          <div className="flex space-x-1 items-center px-3 w-[70px]">
            <div className="text-[9px]">
              <p className="font-bold px-2">{item.start_time}</p>
              <p className="font-bold px-2">{item?.start_time}</p>
            </div>
          </div>

          <div className="flex space-x-2 bg-[#F2F2F2] w-[230px] rounded-lg p-[10px]">
            <div>
              <img
                src={imageLoader(item?.patient?.imageUrl)}
                alt={item?.patient?.first_name}
                className="w-8 h-8 rounde-full"
              />
            </div>
            <div>
              <p className="text-[12px]">
                {item?.patient?.first_name} {item?.patient?.last_name}
              </p>
              <p className="text-[10px] text-textgray">
                {item?.service_type?.name}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Schedule;
