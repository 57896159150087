import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../authProvider";

export const ProtectedRoute = () => {
  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;

    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null;
  // Check if the user is authenticated
  // if (!accessToken || !token) {
  //   return <Navigate to="/" />;
  // }
  // If authenticated, render the child routes
  return <Outlet />;
};
