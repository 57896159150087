import React, { useState, useEffect, useRef } from 'react'
import { MdLogout } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { images } from "../../../constants/icons";
import { logo, healthWorkerImages, uploads } from '../../../constants/images'
import { useStateProvider } from '../../../manager/StateContext';

import { profileDetail } from "../../../services/data/NavigationData";
import { userLogout } from '../../../services/redux/auth/authAction/authAction';
import { useGetUserDetailsQuery } from '../../../services/redux/auth/authAction/authService';
import { setCredentials, logout } from '../../../services/redux/auth/authSlice/authSlice';

const UserProfileHeader = (props) => {


  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const [{user_data, health_worker_details}] = useStateProvider();
  const [sidebarState, setSidebarState] = useState(false);
  const sidebarRef = useRef(null);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const handleLogoutModalToggle = () => {
    setSidebarState(false);
    setShowLogoutModal(!showLogoutModal);
  };

  // automatically authenticate user if token is found
  const { data, isFetching } = useGetUserDetailsQuery('userDetails', {
    // perform a refetch every 15mins
    //   pollingInterval: 900000,
  })

    useEffect(() => {
      if (data) dispatch(setCredentials(data))
      // console.log('data', data)
    }, [data, dispatch])

    const navigate = useNavigate();
    const handleLogout = () => {
      dispatch(userLogout());
      navigate("/")
    }

    const toggleDropdown = () => {
      setSidebarState(!sidebarState);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          sidebarRef.current &&
          !sidebarRef.current.contains(event.target)
        ) {
          setSidebarState(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

      return (
        <div className=" w-[100%] bg-[#F7F8FA] mx-auto px-4 pt-4 md:px-8">
          <div className="items-start justify-between gap-x-4 py-4  sm:flex">
            <div className="max-w-lg">
              <img src={logo.mmbLogo} alt="" />
              <p className="text-black-600 mt-4 divide-x">
                {props.Breadcrumb}
              </p>
            </div>

            <div className="mt-6 sm:mt-0 bg-white rounded-lg gap-2.5 shadow-lg  w-auto lg:h-[51px] ">
              <div className="relative">
                <div className="flex items-center py-2 px-4">
                  <img
                    className="w-8 h-8 rounded-full"
                    src={uploads.avatar}
                    alt="profile"
                  />
                  <div className="font-medium dark:text-white">
                    <div className="px-2 font-Montserrat text-sm font-medium leading-[16px]">
                      {user_data?.email}
                    </div>
                    <div className="px-2 text-sm font-Poppins text-gray-400 dark:text-gray-400">
                      {isFetching
                        ? `Fetching your profile...`
                        : userInfo !== null
                        ? userInfo?.data?.account_type
                        : "h"}
                    </div>
                  </div>
                  <div className="relative w-[10px]" ref={sidebarRef}>
                    <button onClick={toggleDropdown} className="w-[100%]">
                      <img
                        src={profileDetail.logo}
                        alt="dropdown"
                        className="w-[10rem]"
                      />
                    </button>

                    {sidebarState && (
                      <div className="absolute top-10 -right-3 z-20 bg-[#f7f5f5] rounded-lg w-[188px] p-[10px]  text-black">
                        <div>
                          <div className="flex flex-row items-center py-1">
                            <MdLogout size="14" color="red" />
                            <button
                              onClick={() => handleLogoutModalToggle()}
                              className="text-xs font-bold ml-2"
                            >
                              Log out
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {showLogoutModal && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="relative w-full max-w-sm p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3 sm:flex">
                        <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 text-red-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <h4 className="text-lg font-medium text-gray-800">
                            Are you Sure?
                          </h4>
                          <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                            Do you realize you are Logging Out?
                          </p>
                          <div className="items-center gap-3 mt-3 sm:flex">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                              onClick={() => handleLogout()}
                            >
                              Logout
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                              onClick={() => handleLogoutModalToggle()}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }


export default UserProfileHeader;