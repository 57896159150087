import React from "react";
import { LoaderIcon } from "react-hot-toast";

import { formatAppointmentTime } from "../../utils/helpers";

const ConfirmationModal = ({
  title,
  description,
  buttonText,
  onConfirm,
  onClose,
  data,
  isLoading,
}) => {
  return (
    <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-sm p-4 mx-auto bg-white rounded-md shadow-lg">
        <div className="mt-3 sm:flex">
          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-blue-100 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-blue-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="mt-2 text-center sm:ml-4 sm:text-left">
            <h4 className="text-lg font-medium text-gray-800">{title}</h4>
            <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
              {description}
            </p>

            <div className="my-2">
              {data.from_date && (
                <div className="flex items-center justify-between">
                  <p className="font-bold">From date:</p>
                  <p>{data.from_date}</p>
                </div>
              )}

              {data.to_date !== null && (
                <div className="flex items-center justify-between">
                  <p className="font-bold">To date:</p>
                  <p>{data.to_date}</p>
                </div>
              )}

              {data.from_time && (
                <div className="flex items-center justify-between">
                  <p className="font-bold">From time:</p>
                  <p>{formatAppointmentTime(data.from_time)}</p>
                </div>
              )}

              {data.to_time && (
                <div className="flex items-center justify-between">
                  <p className="font-bold">To time:</p>
                  <p>{formatAppointmentTime(data.to_time)}</p>
                </div>
              )}
            </div>

            <div className="items-center gap-3 mt-3 sm:flex">
              <button
                className="w-full mt-2 p-2.5 flex-1 text-white bg-blue-600 rounded-md outline-none ring-offset-2 ring-blue-600 focus:ring-2"
                onClick={onConfirm}
              >
                {isLoading ? (
                  <div className="flex justify-center">
                    <LoaderIcon size={20} color="white" />
                  </div>
                ) : (
                  <p> {buttonText}</p>
                )}
              </button>
              <button
                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
