import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Login from './Login';
import Register from './Register';
import { LoginImage, logo, phrImages, uploads } from '../../../constants/images';
import Modal from '../../../containers/modals/LogoutModal';
import ResetModal from '../../../containers/modals/ResetModal';
import { useStateProvider } from '../../../manager/StateContext';
import HomeFooter from '../../HomeFooter';

import { useQuery } from '../../../utils/helpers';
import { userLogout } from '../../../services/redux/auth/authAction/authAction';

const Navbar = () => {
    const [state, setState] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [activeModal, setActiveModal] = useState("");
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);

    const dispatch = useDispatch();

    const query = useQuery();

    const {accessToken} = useSelector((state) => state.auth);
    const [{user_data}] = useStateProvider();

    const navigate = useNavigate();

    const handleChange = (e) => {
        // Handle form input changes here
    };

    const navigation = [
      { title: "About", path: "https://mymedicalbank.com/#about-us" },
      { title: "Contact", path: "https://mymedicalbank.com/contact-us" },
    ];

    const handleLogout = () => {
      dispatch(userLogout());
      setShowLogoutModal(false);
    };

    const setOpenModal  = () => {
      setShowLogoutModal(!showLogoutModal);
    }

    useEffect(() => {
      document.onclick = (e) => {
        const target = e.target;
        if (!target.closest(".menu-btn")) setState(false);
      };
      const queryTab = query.get("tab");
      if (queryTab) setActiveModal(queryTab);
    }, [query]);

    const Redirect = () => {
        window.location.href = '/emergency';
    };

    const Brand = () => (
      <div className="flex items-center justify-between py-5 md:block">
        <a href="https://mymedicalbank.com/">
          <img
            src={logo.mmbLogo}
            width={150}
            height={60}
            alt="Mymedical Bank logo"
          />
        </a>
        <div className="md:hidden">
          <button
            className="menu-btn text-gray-500 hover:text-gray-800"
            onClick={() => setState(!state)}
          >
            {state ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    );

    const handleDashboard = () => {
      navigate('/health-worker-dashboard')
    }
    
    useEffect(() => {
        if(activeModal){
          if(activeModal === "login"){
            setShowLoginModal(true);
          }else{
            setShowModal(true);
          }
        }
    }, [activeModal]);

    const handleResetPasswordModal = () => {
      setShowLoginModal(false);
      setShowModal(false);
      setShowResetModal(!showResetModal);
    };

    return (
      <div
        className="relative"
        style={{
          backgroundImage: "url('/src/global/assets/background.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
          zIndex: 50,
        }}
      >
        <div
          className="absolute inset-0 blur-xl h-[580px]"
          style={{ background: "#E5EFFB" }}
        ></div>
        <div className="relative h-screen">
          <header>
            <div className={`md:hidden ${state ? "mx-2 pb-5" : "hidden"}`}>
              <Brand />
            </div>
            <nav
              className={`pb-5 md:text-sm ${
                state
                  ? "absolute top-0 inset-x-0 bg-white h-full shadow-lg rounded-xl border mx-2 mt-2 md:shadow-none md:border-none md:mx-0 md:mt-0 md:relative md:bg-transparent"
                  : ""
              }`}
            >
              <div className="gap-x-14 items-center max-w-screen-xl mx-auto px-4 md:flex md:px-8">
                <Brand />
                <div
                  className={`flex-1 items-center mt-8 md:mt-0 md:flex ${
                    state ? "block" : "hidden"
                  } `}
                >
                  <ul className="flex-1 justify-center items-center space-y-6 md:flex md:space-x-6 md:space-y-0">
                    {navigation.map((item, idx) => (
                      <li
                        key={idx}
                        className="text-gray-700 hover:text-gray-900"
                      >
                        <a href={item.path} className="block">
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="flex-1 items-center justify-end gap-x-6 space-y-3 md:flex md:space-y-0 mt-1">
                    {accessToken ? (
                      <div className="flex items-center space-x-1">
                        <button
                          onClick={handleDashboard}
                          className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-[#47A7FD] hover:bg-gray-700 active:bg-gray-900 rounded-xl md:inline-flex"
                        >
                          Dashboard
                        </button>

                        <button
                          onClick={setOpenModal}
                          className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-[#47A7FD] hover:bg-gray-700 active:bg-gray-900 rounded-xl md:inline-flex"
                        >
                          Logout
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-3">
                        <button
                          onClick={() => setShowLoginModal(true)}
                          className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-[#47A7FD] hover:bg-gray-700 active:bg-gray-900 rounded-xl md:inline-flex"
                        >
                          Login
                        </button>
                        <button
                          onClick={() => setShowModal(true)}
                          className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-[#47A7FD] hover:bg-gray-700 active:bg-gray-900 rounded-xl md:inline-flex"
                        >
                          Register
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </header>

          <div className="flex flex-col items-center justify-center">
            <div className="my-2">
              <p className="font-Montserrat text-center text-[12px]">
                Welcome to the
              </p>
              <p className="font-bold text-center text-bgbutton text-[20px]">
                Provider's Dashboard
              </p>
            </div>
            <div>
              <img
                src={phrImages.phrImage}
                className="bg-cover rounded-full w-[300px] h-[300px]"
                alt="bg"
              />
            </div>
            <div className="mt-8">
              <p className="font-Montserrat text-sm px-5 w-full sm:w-[650px] text-center">
                We value you as a health provider; that is why we have created
                this platform to help you meet people that need your services.
              </p>
            </div>

            <button
              onClick={() => setShowModal(true)}
              className="flex items-center justify-center gap-x-1 py-2 px-4 my-8 text-white font-medium bg-[#47A7FD] hover:bg-gray-700 active:bg-gray-900 rounded-xl md:inline-flex"
            >
              Create your own account
            </button>
          </div>

          <div>
            <HomeFooter />
          </div>
        </div>

        {showModal && (
          <>
            <Register
              handleChange={handleChange}
              setShowModal={setShowModal}
              setShowLoginModal={setShowLoginModal}
            />
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
        {showLoginModal && (
          <>
            <Login
              handleOnChange={handleChange}
              setShowModal={setShowModal}
              setShowLoginModal={setShowLoginModal}
              handleResetPassword={handleResetPasswordModal}
            />
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}

        {showLogoutModal && (
          <Modal setOpenModal={setOpenModal} handleLogout={handleLogout} />
        )}

        {showResetModal && (
          <ResetModal
            setOpenModal={setShowResetModal}
            handleResetPasswordModal={handleResetPasswordModal}
          />
        )}
      </div>
    );
};

export default Navbar;
