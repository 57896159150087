import React from 'react'
import EmergencyHero from "../../components/corporatedashboard/EmergencyHero"
import Register from '../../components/Users/LandingPage/Register';

const Emergency = () => {
  return (
    <div>
       <div className="h-auto lg:h-auto bg-cover bg-no-repeat bg-[#F3F4F4] "  >
        <EmergencyHero/>
    </div>
    </div>
  )
}

export default Emergency
