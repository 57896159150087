import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { reducerCases } from "../../manager/constants";
import { useStateProvider } from "../../manager/StateContext";
import LoadingModal from "../Modal/LoadingModal";

import { sendError } from "../../utils/helpers";

const AppointmentContext = createContext();

export const useAppointmentContext = () => {
  const context = useContext(AppointmentContext);
  if (!context) {
    throw new Error(
      "useAppointmentContext must be used within an AppointmentProvider"
    );
  }
  return context;
};

export const AppointmentProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");

  const [{ state_message, stage_token }, dispatch] = useStateProvider();

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [errors, setErrors] = useState({});

  const [inputs, setInputs] = useState({
    contract_id: "",
    summary: "",
    recommend: "",
    upload: [],
    drugs: [],
    test: [],
  });
  const [previews, setPreviews] = useState([]);

  useEffect(() => {
    if (inputs.contract_id !== selectedBooking) {
      setInputs((prevData) => ({
        ...prevData,
        contract_id: selectedBooking,
      }));
    }
  }, [selectedBooking, inputs.contract_id]);

  const handleReportClick = (id, contract_id) => {
    setSelectedBooking(contract_id);
    setReportModal(!reportModal);
  };

  if (isLoading) {
    return (
      <LoadingModal
        open={isLoading}
        setOpen={setIsLoading}
        title={state_message}
      />
    );
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    previews.forEach((preview) => URL.revokeObjectURL(preview));
    setInputs((prevInputs) => ({
      ...prevInputs,
      upload: files,
    }));

    const imagePreviews = files
      .filter((file) => file.type.startsWith("image/"))
      .map((file) => URL.createObjectURL(file));

    setPreviews(imagePreviews);
  };

  const handleFileDelete = (indexToDelete) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      upload: prevInputs.upload.filter((_, index) => index !== indexToDelete),
    }));

    setPreviews((prevPreviews) =>
      prevPreviews.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleInputChange = (text, input) => {
    setInputs((prevState) => ({ ...prevState, [input]: text }));
  };

  const handleBlur = (value, input) => {
    setErrors({});
  };

  const handleError = (errorMessage, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: errorMessage }));
  };

  const uploadDocuments = async (files, contract_id) => {
    try {
      dispatch({
        type: reducerCases.SET_MESSAGE,
        state_message: "Uploading documents for report...",
      });

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${stage_token}`,
        },
      };

      const formData = new FormData();
      formData.append("contract_id", contract_id);
      files.forEach((file) => {
        formData.append("upload[]", file, file.name);
      });

      const url = `${process.env.REACT_APP_API_LIVE_URL}/api/upload-telemedreport`;
      const response = await axios.post(url, formData, config); //
      console.log(response);
      setPreviews([]);
      return response.data.status.code === 100;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const submitReport = async (inputs) => {
    try {
      dispatch({
        type: reducerCases.SET_MESSAGE,
        state_message: "Submitting report for appointment...",
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${stage_token}`,
        },
      };

      const url = `${process.env.REACT_APP_API_LIVE_URL}/api/submit-telemedreport`;
      const response = await axios.post(url, { ...inputs }, config);
      const { data } = response;
      toast.success(data.message);

      dispatch({
        type: reducerCases.SET_MESSAGE,
        state_message: "",
      });

      setInputs({
        contract_id: "",
        summary: "",
        recommend: "",
        upload: [],
        drugs: [],
        test: [],
      });

      setReportModal(false);
    } catch (error) {
      console.error(error);
      const message = sendError(error);
      console.log(message);
      toast.error("There was an error submitting the report!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitReport = async () => {
    setIsLoading(true);
    try {
      const { upload, contract_id } = inputs;

      if (upload.length > 0) {
        const fileUploadSuccess = await uploadDocuments(upload, contract_id);
        if (!fileUploadSuccess) {
          throw new Error("File upload failed");
        }
      }

      await submitReport(inputs);
    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: reducerCases.SET_MESSAGE,
        state_message: "",
      });
      toast.error(error.message || "There was an error submitting the report!");
    }
  };

  const validateInput = () => {
    let valid = true;

    if (!inputs.summary) {
      handleError("What is you view about the appointment?!", "summary");
      valid = false;
    }

    if (!inputs.recommend) {
      handleError("What is the patient's recommendation?", "recommend");
      valid = false;
    }

    if (valid) {
      handleSubmitReport();
    }
  };

  return (
    <AppointmentContext.Provider
      value={{
        isLoading,
        setIsLoading,
        reportModal,
        setReportModal,
        selectedBooking,
        setSelectedBooking,
        openDropdownIndex,
        setOpenDropdownIndex,
        errors,
        setErrors,
        inputs,
        setInputs,
        previews,
        setPreviews,
        handleFileChange,
        handleInputChange,
        handleBlur,
        handleFileDelete,
        handleReportClick,
        validateInput,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
