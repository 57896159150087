import {React, useState} from "react";
import axios from "axios";
import toast, { LoaderIcon } from "react-hot-toast";
import { FaExclamationCircle } from "react-icons/fa";
import { useSelector } from "react-redux";

import StatusMessage from "../../components/StatusMessage";
import TextInput from "../../components/TextInput";
import { reducerCases } from "../../manager/constants";
import { useStateProvider } from "../../manager/StateContext";

import { sendError, ValidateForm } from "../../utils/helpers";

export default function ResetModal({ setOpenModal, handleResetPasswordModal }) {
  // const { accessToken } = useSelector((state) => state.auth);
  const [{ user_data, stage_token, token }, dispatch] = useStateProvider();

    const [inputs, setInputs] = useState({
      email: "",
      newPassword: "",
      confirmPassword: "",
      resetToken: "",
    });
    
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (e) => {
        if (e.target) {
          const { name, value } = e.target;
          setInputs((refData) => ({
            ...refData,
            [name]: value,
          }));
        }
    }

    const [activeTab, setActiveTab] = useState("send_email");

    const handleSendCode = () => {
      try {
        setIsLoading(true);
        const keys = ["email"];

        const allow = ValidateForm(inputs, keys);

        if (allow.status) {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          const url = `${process.env.REACT_APP_API_LIVE2_URL}/api/v1/user/reset-password`;

          axios
            .post(
              url,
              {
                email: inputs.email,
              },
              config
            )
            .then((response) => {
              setIsLoading(false);
              const { data } = response;
              console.log(data.data);
              toast.success(data.message);
              setActiveTab("update_password");
            })
            .catch((error) => {
              setIsLoading(false);
              const message = sendError(error);
              toast.error("There was an error sending reset password token.");
              console.log(message);
            });
        }else{
          setIsLoading(false);
          toast.error(allow.message)
        }
      } catch (error) {
        setIsLoading(false);
        const message = sendError(error);
        console.log(message);
        toast.error("There was an error sending reset password token.");
      }
    };

    const handleUpdatePassword = () => {
        try {
            setIsLoading(true);
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            const keys = [
                "email",
                "newPassword",
                "confirmPassword",
                "resetToken",
            ];

            const allow = ValidateForm(inputs, keys);

            if (allow.status) {
                const url = `${process.env.REACT_APP_API_LIVE2_URL}/api/v1/user/reset-password/${inputs.resetToken}`;
                axios
                .put( url, {
                    ...inputs,
                },
                config
                )
                .then((response) => {
                    setIsLoading(false);
                    const { data } = response;
                    toast.success(data.message);
                    handleResetPasswordModal();
                })
                .catch((error) => {
                    setIsLoading(false);
                    const message = sendError(error);
                    dispatch({
                      type: reducerCases.SET_MESSAGE_STATUS,
                      message_status: {
                        type: "error",
                        message:
                          "There was an error sending reset password token.",
                      },
                    });
                    console.log(message);
                });
            }else{
                setIsLoading(false);
                dispatch({
                  type: reducerCases.SET_MESSAGE_STATUS,
                  message_status: {
                    type: "error",
                    message: allow.message,
                  },
                });
            }
        } catch (error) {
            setIsLoading(false);
            const message = sendError(error);
            console.log(message);
            dispatch({
              type: reducerCases.SET_MESSAGE_STATUS,
              message_status: {
                type: "error",
                message: "There was an error sending reset password token.",
              },
            });
        }
    };

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div
        className="fixed inset-0 w-full h-full bg-black opacity-40"
        onClick={() => setOpenModal(false)}
      ></div>

      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative w-full max-w-md p-4 mx-auto bg-white rounded-md shadow-lg">
          <div className="mt-3">
            <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-blue-100 rounded-full">
              <FaExclamationCircle color="blue" />
            </div>
            <div className="mt-2 text-center sm:ml-4 sm:text-left">
              <div>
                <h5 className="font-bold text-center">Forgot Password</h5>
                <p className="text-[12px] text-center">
                  Set your new password. Check you inbox/spam for token
                  generated.
                </p>

                <StatusMessage />

                {activeTab === "send_email" ? (
                  <div className="mt-8">
                    <div>
                      <TextInput
                        label="Enter Email"
                        type="email"
                        id="email"
                        name="email"
                        value={inputs.email}
                        className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        onClick={handleSendCode}
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-bgbutton rounded-md outline-none ring-offset-2  focus:ring-2 flex items-center space-x-2 justify-center"
                      >
                        {isLoading && <LoaderIcon />}
                        <p>Send</p>
                      </button>
                      <button
                        onClick={() => handleResetPasswordModal()}
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-8">
                    <div>
                      <TextInput
                        label="Enter Password"
                        password
                        id="newPassword"
                        name="newPassword"
                        value={inputs.newPassword}
                        className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <TextInput
                        label="Confirm Password"
                        password
                        id="confirmPassword"
                        name="confirmPassword"
                        value={inputs.confirmPassword}
                        className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <TextInput
                        label="Token"
                        type="number"
                        id="resetToken"
                        name="resetToken"
                        value={inputs.resetToken}
                        className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="items-center gap-2 mt-3 sm:flex z-30">
                      <button
                        type="button"
                        onClick={handleUpdatePassword}
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-bgbutton rounded-md outline-none ring-offset-2  focus:ring-2 flex items-center justify-center space-x-2"
                      >
                        {isLoading && <LoaderIcon />}
                        <p>Submit</p>
                      </button>

                      <button
                        onClick={() => handleResetPasswordModal()}
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 