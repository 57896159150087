import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/set-up', 

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;

   

      if (token) {
        // Include token in the request header
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers; 
   
    },
 
  }),

  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => ({
        url: '/',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetUserDetailsQuery } = authApi;
