import React, { useState } from "react";
import AcountInfo from "../../components/corporatedashboard/AcountInfo";
import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
import AppointmentTable from "../../components/corporatedashboard/AppointmentTable";
import ScheduleContainer from "../../containers/corporateContainer/ScheduleContainer";
import ClientSidebar from "../../components/global/ClientSidebar";
import HealthWorkerID from "../../components/Users/HealthWorkersUsers/healthWorker/HealthWorkerID";
import ClientBookingContainer from "../../containers/clientBookingContainer/ClientBookingContainer";
import ClientBookFromHome from "../../components/client/ClientBookFromHome";

export const delQuery = (asPath) => {
  return asPath.split("?")[0];
};

const ClientDashborad = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat">
      <ClientSidebar isOpen={isOpen} setOpen={setOpen} statusStyle={'mt-[50vh]'} />
      <div className="w-[calc(100%-212px)] xl:w-[calc(100%-212px)]  pl-[36px] pt-[22.5px] pr-[20px]">
        <CorporateHeader isOpen={isOpen} setOpen={setOpen} />

        <>
          <div className="flex justify-between w-[100%] ">
            <div className="mr-[10px]">
              <div className="mb-[74px]">
                <AcountInfo />
              </div>

              <div className="mb-[30px]">
                <ClientBookFromHome />
              </div>

              <ClientBookingContainer />

              <AppointmentTable className={"w-[100%] mt-[30px]"} />
            </div>
            <div>
              <HealthWorkerID />
              <ScheduleContainer />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ClientDashborad;
