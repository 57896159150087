import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

// Fetch User details by Id
export const fetchUserById = createAsyncThunk(
  'user/fetchUserById', 
  async (userId, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const url = `${BASE_URL}/api/v1/user/get-user/${userId}`;

      const response = await axios.get(url, config);

      return response.data;
    } catch (error) {
      // Return backend error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
