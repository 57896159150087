
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Table, { StatusPill } from "../../Table"; 

function Apps() {
  const [loading, setLoading] = useState(true);
  const [superAdmindata, setData] = useState({ data: { users: [] } }); // Initialize as an empty object

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin/dashboard`;
    axios
      .get(apiUrl)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the Axios GET request:", error);
        setLoading(false);
      });
  }, []);

  const dataV = superAdmindata?.data?.users || []; // Guard against missing properties
  console.log('cus', dataV)

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: (name) => `${name.first_name} ${name.last_name}`
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Mobile",
      accessor: "mobile",
    },
    {
      Header: "Date Created",
      accessor: (item) => moment(item.createdAt).format("DD, MMMM YY"),
    },
    // {
    //   Header: "Date Created",
    //   accessor: "updatedAt",
    //   Cell: StatusPill,
    // },
    {
      Header: "Referral Code",
      accessor: "referral",
    },
  ], []);

  return (
    <div className="min-h-screen bg-white text-black mt-6">
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
        <div className="">
          <h1 className="text-xl font-semibold font-Roboto ">User Login</h1>
        </div>
        <div className="mt-6">
          <Table columns={columns} data={dataV} />
        </div>
      </main>
    </div>
  );
}

export default Apps;
