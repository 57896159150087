import React, { useEffect, useState } from 'react'
import axios from 'axios';
import toast, { LoaderIcon } from 'react-hot-toast';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";

import { appointmentDataIcons } from '../../constants/icons';
import { useStateProvider } from '../../manager/StateContext';
import TextInput from '../TextInput';

import { sendError, setNextTab } from '../../utils/helpers';
import { colors } from '../../utils/style';

const BankDetails = ({
  activeTab,
  tab,
  setActiveTab,
  isLoading,
  setIsLoading,
  handlePrev,
}) => {
  // const { accessToken } = useSelector((state) => state.auth);
  const [{ user_data, token }] = useStateProvider();

  const initailData = JSON.parse(localStorage.getItem("bankData")) || {
    account_type: "",
    bank_name: "",
    account_name: "",
    account_number: "",
  };
  const [bankData, setBankData] = useState(initailData);

   useEffect(() => {
     localStorage.setItem("bankData", JSON.stringify(bankData));
   }, [bankData]);

  const handleBankChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setBankData((bankData) => ({
        ...bankData,
        [name]: value,
      }));
    }
  };

  const handleUpdateBank = () => {
    Swal.fire({
      title: "Update Bank Details",
      text: "Are you sure you want to update your bank details?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      cancelButtonColor: "red",
      confirmButtonColor: "#00aaff",
      customClass: {
        title: "swal2-title-custom",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          setIsLoading(true);
          const url = `${process.env.REACT_APP_API_URL}/api/v1/cooperate/health-worker/update-health-worker-information/${user_data?.id}`;
          axios
            .put(url, { ...bankData }, config)
            .then((response) => {
              setIsLoading(false);
              const { data } = response;
              toast.success(data.message);
              setNextTab(activeTab, tab, setActiveTab);
            })
            .catch((error) => {
              setIsLoading(false);
              const message = sendError(error);
              console.log(message);
              toast.error(message.message);
            });
        } catch (error) {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.error);
        }
      }
    });
  };

  return (
    <div className="mx-5 flex-1">
      <h5 className="text-[15px] font-semibold">Bank Account Details</h5>
      <p className="text-[10px]">Set up your bank account information.</p>

      <div className="my-2 grid grid-cols-1 sm:grid-cols-2 sm:gap-4">
        <div className="w-full relative">
          <label className="text-[10px] font-bold">Account Type</label>
          <select
            name="account_type"
            value={bankData.account_type}
            onChange={(e) => handleBankChange(e)}
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
          >
            <option value="" disabled>
              Select Type
            </option>
            <option value="savings">Savings</option>
            <option value="current">Current</option>
          </select>
          <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
            <img
              src={appointmentDataIcons.dropdownIcon}
              className="w-3 h-3"
              alt=""
            />
          </div>
        </div>

        <div>
          <TextInput
            label="Bank Name"
            type="text"
            id="bank_name"
            name="bank_name"
            value={bankData.bank_name}
            onChange={handleBankChange}
            className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
          />
        </div>

        <div>
          <TextInput
            label="Account Name"
            type="text"
            id="account_name"
            name="account_name"
            value={bankData.account_name}
            onChange={handleBankChange}
            className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
          />
        </div>

        <div>
          <TextInput
            label="Account Number"
            type="text"
            id="account_number"
            name="account_number"
            value={bankData.account_number}
            onChange={handleBankChange}
            className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
          />
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="flex cursor-pointer items-center space-x-2 mr-2">
            <div onClick={handlePrev} className="flex items-center space-x-1">
              <FaArrowAltCircleLeft size={25} color={colors.primary} />
              <p className="font-bold">Back</p>
            </div>
          </div>

          <button onClick={handleUpdateBank} className="button">
            <div className="flex items-center space-x-3">
              {isLoading && <LoaderIcon size="20" color="white" />}
              <p>Update</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankDetails