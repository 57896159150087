import { createSlice } from "@reduxjs/toolkit";
import { UserEmergency } from "../actions/formAction";

const initialState = {
    loading:false,
    error: null,
    success:false,
    emergencyInfo: null,

};

const UserEmergencySlice = createSlice({
    name: 'useremergency',
    initialState,
    reducers: {},
    setDatas: (state, { payload }) => {
        state.emergencyInfo = payload
    },

    extraReducers: {
        [UserEmergency.pending]: (state) => {
            state.loading = true
            state.error =  null

        },

        [UserEmergency.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.emergencyInfo = payload
        },

        [UserEmergency.rejected]: (state, {payload}) => {
            state.loading = false;
            state.error = payload
        }

    }
    
})

export const { setDatas } = UserEmergencySlice.actions
export default UserEmergencySlice.reducer;
