import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import toast, { LoaderIcon } from 'react-hot-toast';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { TiDelete } from 'react-icons/ti';
import { useSelector } from 'react-redux';

import { minibar } from '../../constants/icons';
import { reducerCases } from '../../manager/constants';
import { useStateProvider } from '../../manager/StateContext';

import { daysOfWeek, formatAppointmentTime, sendError, setNextTab } from '../../utils/helpers';
import { colors } from '../../utils/style';

const Availability = ({
  activeTab,
  tab,
  setActiveTab,
  isLoading,
  setIsLoading,
  handlePrev,
}) => {
  // const { accessToken } = useSelector((state) => state.auth);
  const [{ user_data, health_worker_detail, token }, dispatch] =
    useStateProvider();
    
    const initailData = JSON.parse(localStorage.getItem("appointmentData")) || {
      user_id: "",
      carer_id: "",
      org_id: "121",
      monday_opening: "",
      monday_closing: "",
      tuesday_opening: "",
      tuesday_closing: "",
      wednesday_opening: "",
      wednesday_closing: "",
      thursday_opening: "",
      thursday_closing: "",
      friday_opening: "",
      friday_closing: "",
      saturday_opening: "",
      saturday_closing: "",
      sunday_opening: "",
      sunday_closing: "",
    };
  const [appointmentData, setAppointmentData] = useState(initailData);

  useEffect(() => {
    localStorage.setItem("appointmentData", JSON.stringify(appointmentData));
  }, [appointmentData]);

  const [hoveredDay, setHoveredDay] = useState(null);
  const inputRefs = useRef({});

  const handleMouseEnter = (name) => {
    setHoveredDay(name);
  };

  const handleMouseLeave = () => {
    setHoveredDay(null);
  };

  const checkClosing = (text) => {
    return text.includes("_closing");
  };

  const handleAppointmentChange = (e) => {
    const { name, value } = e.target;
    const isClosing = checkClosing(name);

    if (isClosing) {
      const day = name.split("_")[0];
      const checkTime = day + "_opening";
      const openingValue = parseInt(appointmentData[checkTime], 10);
      const closingValue = parseInt(value, 10);

      if (isNaN(openingValue)) {
        toast.error("Please set opening time first.");
        return;
      }

      if (
        isNaN(closingValue) ||
        closingValue <= openingValue ||
        closingValue > 23
      ) {
        toast.error(
          "The closing time selected must be later than the opening time and must not exceed 11:00 PM."
        );
        return;
      }
    }

    setAppointmentData({
      ...appointmentData,
      [name]: value,
    });
  };

  const handleUpdateAvailability = () => {
    setIsLoading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/v1/cooperate/availablility/create-update`,
          { ...appointmentData },
          config
        )
        .then((response) => {
          setIsLoading(false);
          const { data } = response;
          toast.success(data.message);
          setNextTab(activeTab, tab, setActiveTab);
        })
        .catch((error) => {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.message);
        });
    } catch (error) {
      setIsLoading(false);
      const message = sendError(error);
      console.log(message.message);
    }
  };

  const handleDeleteAvailability = (day) => {
    setAppointmentData((prevData) => ({
      ...prevData,
      [`${day}_opening`]: "",
      [`${day}_closing`]: "",
    }));
  };

  useEffect(() => {
    setAppointmentData((prev) => ({
      ...prev,
      user_id: user_data?.id || "",
      carer_id: health_worker_detail?.carer_id || "",
      org_id: user_data?.organisationId || "",
    }));
  }, [user_data, health_worker_detail]);

  return (
    <div className="m-auto p-2 sm:p-5">
      <div>
        <div className="flex flex-row items-center justify-between">
          <div>
            <h5 className="font-bold text-lg">Set availability</h5>
            <p className="text-xs">
              Set general day and time that you are availabile for consultation.
            </p>
          </div>
        </div>

        {daysOfWeek.map((day, index) => (
          <div
            key={day}
            ref={(el) => (inputRefs.current[`${day}`] = el)}
            onMouseEnter={() => handleMouseEnter(`${day}`)}
            onMouseLeave={handleMouseLeave}
            className="relative my-5"
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 place-content-center">
              <div className="flex flex-row items-center space-x-1">
                <p className="font-bold text-sm">{index + 1}).</p>
                <p className="font-bold text-[10px] sm:text-sm">
                  {day.charAt(0).toUpperCase() + day.slice(1)}:
                </p>
              </div>
              <div className="grid-span-2 w-full">
                <div className="flex flex-row flex-wrap items-center space-x-4">
                  <div className="mr-3">
                    <label className="text-sm font-light flex flex-col items-center">
                      <div className="mr-2 flex flex-row items-center space-x-2">
                        <p className="text-left text-[10px] font-semibold">
                          Opening Time:
                        </p>
                        <p>
                          {formatAppointmentTime(
                            appointmentData[`${day}_opening`]
                          )}
                        </p>
                      </div>

                      <div>
                        <input
                          type="range"
                          name={`${day}_opening`}
                          min="0"
                          max="23"
                          step="1"
                          value={appointmentData[`${day}_opening`]}
                          onChange={handleAppointmentChange}
                          className="h-4 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-bgbutton"
                        />
                      </div>
                    </label>
                  </div>

                  <div>
                    <label className="text-sm font-light flex flex-col items-center">
                      <div className="ml-2 flex flex-row items-center space-x-2">
                        <p className="text-right text-[10px] font-semibold">
                          Closing Time:
                        </p>
                        <p>
                          {formatAppointmentTime(
                            appointmentData[`${day}_closing`]
                          )}
                        </p>
                      </div>

                      <div>
                        <input
                          type="range"
                          name={`${day}_closing`}
                          min="0"
                          max="23"
                          step="1"
                          value={appointmentData[`${day}_closing`]}
                          onChange={handleAppointmentChange}
                          className="h-4 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-bgbutton"
                        />
                      </div>
                    </label>
                  </div>

                  <div>
                    <button
                      onClick={() => handleDeleteAvailability(day)}
                      className="left-4 z-10 flex flex-row items-center"
                    >
                      <TiDelete size={25} color={colors.danger} />
                      <p className="text-[10px] font-bold text-red-600">
                        Reset
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="w-full flex flex-row justify-between items-center">
          <div className="flex cursor-pointer items-center space-x-2 mr-2">
            <div onClick={handlePrev} className="flex items-center space-x-1">
              <FaArrowAltCircleLeft size={25} color={colors.primary} />
              <p className="font-bold">Back</p>
            </div>
          </div>

          <button
            type="submit"
            onClick={handleUpdateAvailability}
            className="text-white w-[5rem] bg-bgbutton hover:bg-bgbutton focus:ring-4 
            focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
             dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800"
          >
            {isLoading ? (
              <div className="flex justify-center">
                <LoaderIcon size={20} color="white" />
              </div>
            ) : (
              <p>Update</p>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Availability