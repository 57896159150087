import React, { useState } from "react";
import AcountInfo from "../../components/corporatedashboard/AcountInfo";
import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
import ServicesOverview from "../../components/corporatedashboard/ServicesOverview";
import ActivitiesContainer from "../../containers/corporateContainer/ActivitiesContainer";
import UserSidebar from "../../components/global/ClientSidebar";
import AppointmentTable from "../../components/corporatedashboard/AppointmentTable";
import UsersContainer from "../../containers/corporateContainer/UsersContainer";
import ScheduleContainer from "../../containers/corporateContainer/ScheduleContainer";
import EmergencyTable from "../../components/corporatedashboard/EmergencyTable"

export const delQuery = (asPath) => {
  return asPath.split("?")[0];
};

const CorporateAdminDashboard = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat">
      <UserSidebar isOpen={isOpen} setOpen={setOpen} statusStyle={'mt-[135vh]'}/>
      <div className="w-[calc(100%-212px)] xl:w-[calc(100%-212px)]  pl-[36px] pt-[22.5px] pr-[36px]">
        <CorporateHeader isOpen={isOpen} setOpen={setOpen} />

        <div className="w-[100%]">
          {/* <AcountInfo /> */}
          <UsersContainer />
          <div className="flex justify-between w-[100%] max-[650px]:flex-col">
            <div>
              <ServicesOverview />
              <ActivitiesContainer />
              <AppointmentTable className={"w-[96%] mt-[30px]"} />
            </div>
            <div>
              <ScheduleContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateAdminDashboard;
