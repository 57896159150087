
export const reducerCases = {
  SET_MESSAGE: "SET_MESSAGE",
  SET_MESSAGE_STATUS: "SET_MESSAGE_STATUS",
  SET_STAGING_TOKEN: "SET_STAGING_TOKEN",
  SET_TOKEN: "SET_TOKEN",
  SET_USER_CREDENTIALS: "SET_USER_CREDENTIALS",
  SET_HEALTHWORKER_INFO: "SET_HEALTHWORKER_INFO",
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_TOKEN: "SET_USER_TOKEN",
  SET_USER_APPOINTMENTS: "SET_USER_APPOINTMENTS",
  SET_USER_AVAILABILITY: "SET_USER_AVAILABILITY",
  SET_USER_SPECIAL_AVAILABILITY: "SET_USER_SPECIAL_AVAILABILITY",
  SET_DRUGS: "SET_DRUGS",
  SET_TESTS: "SET_TESTS",
};
