import { images } from "../../constants/icons";

export const userHeader = [
  { id: 1, title: "Name" },
  { id: 2, title: "Account Type" },
  { id: 3, title: "Date" },
  { id: 4, title: "Status" },

  { id: 5, title: "Actions" },
];

export const adminHeader = [
  { id: 1, title: "Name" },
  { id: 2, title: "Email Address" },
  { id: 3, title: "Date Created" },
  { id: 4, title: "Referral Code" },

  { id: 5, title: "Referred By" },
];

export const userData = [
  {
    id: 1,
    name: "Jane Cooper",
    type: "Health Worker",
    date: "15/05/2023",
    status: "Pending",
    icon: images.horizontalIcon,
  },
  {
    id: 2,
    name: "Wade Warren",
    type: "Clients",
    date: "15/05/2023",
    status: "Active",
    icon: images.horizontalIcon,
  },
  {
    id: 3,
    name: "Eleanor Pena",
    type: "Health Worker",
    date: "15/05/2023",
    status: "Pending",
    icon: images.horizontalIcon,
  },
  {
    id: 4,
    name: "Leslie Alexander",
    type: "Health Worker",
    date: "15/05/2023",
    status: "Active",
    icon: images.horizontalIcon,
  },
  {
    id: 5,
    name: "Jacob Jones",
    type: "Health Worker",
    date: "12/03/2022",
    status: "Paid",
    icon: images.horizontalIcon,
  },
];
