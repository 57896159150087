import React from "react";
import { messaging } from "../../../services/data/activitiesData";
import { images } from "../../../constants/icons";

const MsgWidget = ({className, seeAll}) => {
  return (
    <div className={className}>
      <div className="flex justify-between mb-2">
        <p className="text-base font-bold">Messaging</p>
        <p><img src={images.verticalIcon} alt="" /></p>
      </div>
      <div className="py-[20px]">
        {messaging &&
          messaging.map((item, i) => (
            <div
              key={i}
              className="flex justify-between py-1"
            >
              <div className="w-[20%]">    
                <img src={item.image} alt="" />
              </div>
              <div className="w-[80%] mb-2">
                <p className="text-[12px]  font-semibold">{item.name}</p>
                <p className="text-[10px] text-[#888888]">{item.mssg}</p>
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-center font-semibold text-sm mt-5 "><p>{seeAll}</p></div>
    </div>
  );
};

export default MsgWidget;
