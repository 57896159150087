import React from "react";
import ReportContainer from "../reportContainer/ReportContainer";
import ChartContainer from "../chartContainer/ChartContainer";
import UsersTable from "../../components/Admin/dashboard/UsersTable";
import { adminHeader, userData , userHeader} from "../../services/data/usersdata";
import { withdrawalData, withdrawalHeader } from "../../services/data/withdrawaldata";
import WithdrawalTable from "../../components/Admin/dashboard/WithdrawalTable";
import TableData2 from "../../services/data/TableData2"


const DashboardContainer = ({superAdmindata, error,handleDeleteUser ,isDeleting}) => {

  return (
    <>
      <div>
        <ReportContainer superAdmindata={superAdmindata}/>
      </div>
      <div>
        <ChartContainer />
      </div>
      <div>
      <WithdrawalTable withdrawalData={withdrawalData} withdrawalHeader={withdrawalHeader} />
      </div>
      <div>
        <TableData2 />
      </div>
    </>
  );
};

export default DashboardContainer;
