import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserById } from '../../services/redux/actions/dashboardAction'

const AcountInfo = ({userId}) => {
  const { userInfo } = useSelector((state) => state.auth);
  userId = userInfo.data.id;
  const { userDetails, } = useSelector((state) => state.userDetails);
  const dispatch = useDispatch()

  useEffect(() => {
    // Dispatch the async thunk to fetch user details when the component mounts
    dispatch(fetchUserById(userId));
  }, [dispatch, userId]);

  console.log('info', userDetails);
  return (
    <div className="font-Montserrat">
        <p className="text-[30px] ">Emergency Health Information,</p>
        <h1 className='text-black font-Roboto font-extrabold py-4'>My Emergency Code: {userDetails?.data?.emeregency_code || 'pending..'} </h1>
        <p className="text-[5rem]] text-black font-Poppins">
        To retrieve a victim’s emergency health information, use the above Emergency Access Code by clicking on this link {''}
        <a href="/emergency" style={{ color: '#47A7FD' }}>
          mymedicalbank.com/emergency
        </a> {''}
          and entering the code. 
        If you encounter any difficulty locating your code, contact our customer support team via 
        +234 802 759 0165.
      </p>
    </div>
  )
}

export default AcountInfo