import React from "react";
import MsgWidget from "../../components/Admin/dashboard/MsgWidget";
import ActivitiesWidget from "../../components/Admin/dashboard/ActivitiesWidget";
import Reviews from "../../components/corporatedashboard/Reviews";

const ActivitiesContainer = () => {
  return (
    <div className="flex justify-between w-[96%] mt-[30px] ">
      <div className="w-[33%]">
        <ActivitiesWidget
          title={"Activities"}
          className={"bg-white h-[470px] rounded-lg p-[20px] font-Montserrat"}
        />
      </div>
      <div className="w-[32%]">
        <MsgWidget
          className={"h-[470px] bg-white rounded-lg p-[20px] font-Montserrat"}
        />
      </div>
      <div className="w-[32%]">
        <Reviews />
      </div>
    </div>
  );
};

export default ActivitiesContainer;
