import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LandingPage from "../../components/Users/LandingPage/LandingPage";
import { useStateProvider } from "../../manager/StateContext";
import { useAuthManager } from "../../manager/useAuthManager";

const HomePage = () => {

  const { accessToken } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const {token} = useStateProvider()
  const {} = useAuthManager({
    middleware: "guest",
    role: "",
    redirectIfAuthenticated: "/health-worker-dashboard",
  });

  // useEffect(() => {
  //   if (token) {
  //     navigate("/health-worker-dashboard");
  //   }
  // }, [token, navigate]);
  
  return <LandingPage />;
};

export default HomePage;
