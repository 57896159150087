import { useStateProvider } from "./StateContext";

import { isLoggedIn } from "../utils/helpers";

export default function withAuth(Component) {
  const AuthComponent = (props) => {
    return <Component {...props} />;
  };

  AuthComponent.getInitialProps = (context) => {
    const isUserLoggedIn = isLoggedIn();

    if (!isUserLoggedIn) {
      window.location = "/login";
    }

    return {
      user: { isLoggedIn: isUserLoggedIn },
    };
  };

  return AuthComponent;
}
