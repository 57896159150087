import { reducerCases } from "./constants";

const getToken = (key) =>
  typeof window !== "undefined" ? localStorage.getItem(key) : "";
export const initialState = {
  state_message: "",
  message_status: {
    type: "",
    message: "",
  },
  stage_token: getToken("token"),
  token: getToken("accessToken"),
  user_creds: {},
  health_worker_detail: null,
  user_data: undefined,
  activePage: "/dashboard",
  access_token: undefined,
  user_appointments: [],
  user_availability: [],
  user_special_availability: [],
  tests: [],
  drugs: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case reducerCases.SET_MESSAGE_STATUS:
      return {
        ...state,
        message_status: action.message_status,
      };
    case reducerCases.SET_MESSAGE:
      return {
        ...state,
        state_message: action.state_message,
      };
    case reducerCases.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case reducerCases.SET_STAGING_TOKEN:
      return {
        ...state,
        stage_token: action.stage_token,
      };
    case reducerCases.SET_USER_CREDENTIALS:
      return {
        ...state,
        user_creds: action.user_creds,
      };
    case reducerCases.SET_HEALTHWORKER_INFO:
      return {
        ...state,
        health_worker_detail: action.health_worker_detail,
      };
    case reducerCases.SET_USER_DATA:
      return {
        ...state,
        user_data: action.user_data,
      };
    case reducerCases.SET_USER_TOKEN:
      return {
        ...state,
        access_token: action.access_token,
      };
    case reducerCases.SET_USER_APPOINTMENTS:
      return {
        ...state,
        user_appointments: action.user_appointments,
      };
    case reducerCases.SET_USER_SPECIAL_AVAILABILITY:
      return {
        ...state,
        user_special_availability: action.user_special_availability,
      };
    case reducerCases.SET_USER_AVAILABILITY:
      return {
        ...state,
        user_availability: action.user_availability,
      };
    case reducerCases.SET_TESTS:
      return {
        ...state,
        tests: action.tests,
      };
    case reducerCases.SET_DRUGS:
      return {
        ...state,
        drugs: action.drugs,
      };
    default:
      return state;
  }
};

export default reducer;
