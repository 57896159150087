import React from "react";

import { Transition } from "@headlessui/react";
import { logo } from "../../constants/images";

const LoadingModal = ({ open, setOpen, title }) => {
  return (
    <Transition show={open}>
      <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative w-full max-w-sm p-4 mx-auto rounded-md">
          <div className="flex flex-col items-center">
            <img
              src={logo.mmbLogo}
              alt="loader image"
              width={120}
              height={120}
              className="animate-pulse"
            />
            <p className="text-bgbutton text-sm mt-1 z-50 font-semibold animate-pulse">
              {title ?? ""}
            </p>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default LoadingModal;
