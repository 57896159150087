import { useState } from "react";
import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";

const TextInput = ({
  disabled,
  label,
  error,
  password,
  className,
  email,
  labelClassName,
  onFocus = () => {},
  ...props
}) => {
  const [hidePassword, setHidePassword] = useState(true);

  const handleChangeType = () => {
    setHidePassword(!hidePassword);
  }

  return (
    <div className="relative">
      <label className={`${labelClassName} font-semibold text-[10px]`}>
        {label}
      </label>
      <input
        id={label}
        type={hidePassword ? "password" : "text"}
        disabled={disabled}
        className={` ${
          disabled ? "bg-gray-200" : ""
        } block w-full px-3 py-2 text-base placeholder-sm font-normal text-gray-700  bg-clip-padding border border-gray-300 rounded-md transition ease-in-out m-0 focus:border focus:ring-bgbutton duration-150 ${className}`}
        {...props}
      />
      {password && (
        <span
          onClick={() => handleChangeType()}
          style={{
            fontSize: 22,
            color: "#47A7FD",
            cursor: "pointer",
            position: "absolute",
            bottom: 9,
            right: 9,
            zIndex: 50
          }}
        >
          {hidePassword ? <FaEyeSlash /> : <FaEye />}
        </span>
      )}

      {error && (
        <p style={{ color: "red", marginTop: 7, fontSize: 12 }}>{error}</p>
      )}
    </div>
  );
};

export default TextInput;
