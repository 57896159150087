import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";

import { reducerCases } from "../manager/constants";
import { useStateProvider } from "../manager/StateContext";

const StatusMessage = () => {
  const [{ message_status }, dispatch] = useStateProvider();

  useEffect(() => {
    if (message_status?.message) {
      const timer = setTimeout(() => {
        dispatch({
          type: reducerCases.SET_MESSAGE_STATUS,
          message_status: {
            type: "",
            message: "",
          },
        });
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [dispatch, message_status]);

  const handleClose = () => {
    dispatch({
      type: reducerCases.SET_MESSAGE_STATUS,
      message_status: {
        type: "",
        message: "",
      },
    });
  };

  return (
    <div>
      {message_status?.message && (
        <div
          className={`flex items-center justify-between space-x-2 ${
            message_status?.type === "success" ? "bg-green-600" : "bg-red-600"
          } px-3 py-2 rounded-lg my-2`}
        >
          <p className="flex-1 text-[10px] text-white">
            {message_status?.message}
          </p>
          <button onClick={handleClose}>
            <FaTimes size="12" color="white" />
          </button>
        </div>
      )}
    </div>
  );
};

export default StatusMessage;
