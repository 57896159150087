import React from 'react'

const HomeFooter = () => {

    const currentYear = new Date().getFullYear();
  return (
    <footer
      style={{
        textAlign: "center",
        padding: "1em 0",
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      <p className="text-sm font-Montserrat">
        © {currentYear} All rights reserved.
      </p>
    </footer>
  );
}

export default HomeFooter