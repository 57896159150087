import React from "react";
import {
  appointmentHeader,
  appointmentData,
  emergencyHeader,
} from "../../services/data/corporateData";
import { appointmentDataIcons } from "../../constants/icons";

const EmergencyTable = ({className}) => {
  return (
    <div className={className}>
      <div className="bg-white flex justify-between p-[20px] rounded-t-[8px]">
        <p className="font-semibold text-[16px]">Emergency Access History</p>
      </div>

      <table className="items-center">
        <thead className="">
          <tr className="whitespace-nowrap pl-3">
            {emergencyHeader &&
              emergencyHeader.map((item, idx) => (
                <td
                  key={idx}
                  className="text-[13px] py-[7px]  w-[200.757px] font-medium  whitespace-nowrap"
                >
                  {item.title}
                </td>
              ))}
          </tr>
        </thead>
        <tbody className=" bg-white ">
          {appointmentData &&
            appointmentData.map((item, idx) => (
              <tr className="whitespace-nowrap" key={idx}>
                <td className="w-[170.757px] pl-[17px]">
                  <div className="flex items-center space-x-2 py-2">
                   
                    <div>
                      <p className="text-[13px] font-medium"></p>
                      <p className="text-[10px]"></p>
                    </div>
                  </div>
                </td>
               
                <td className="text-[10px] font-medium ">
                  <div>
                   
                  
                  </div>
                </td>
                <td className="pl-6">
                 
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmergencyTable;
