import React, { useEffect, useState } from "react";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { Twirl as Hamburger } from "hamburger-react";
import toast, { LoaderIcon } from "react-hot-toast";
import {
  FaCopy,
  FaExchangeAlt,
  FaMobileAlt,
  FaShare,
  FaShareAlt,
  FaTimes,
  FaUser,
} from "react-icons/fa";
import { MdLogout, MdTransferWithinAStation } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import { sideIcons } from "../../../constants/icons";
import { logo, uploads } from "../../../constants/images";
import { useStateProvider } from "../../../manager/StateContext";

import { sendError } from "../../../utils/helpers";
import {
  healthWorkerLinks,
  referers,
} from "../../../services/data/NavigationData";
import { userLogout } from "../../../services/redux/auth/authAction/authAction";

const Index = ({ isOpen, setOpen, statusStyle }) => {
  let e = useLocation();

  const [{ user_data, stage_token }] = useStateProvider();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [referralModal, setReferralModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invitationCode, setInvitationCode] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleProfileClick = () => {
    const userId = user_data?.id;
    navigate(
      `/health-workers/health-worker-edit-profile/${userId}?tab=profile`
    );
  };

  const handleLogoutModalToggle = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  const handleReferralToggle = () => {
    setReferralModal(!referralModal);
  };

  const handleLogout = () => {
    dispatch(userLogout());
    navigate("/");
  };

  const steps = [
    {
      description: "Share your invitation with your friends.",
      icon: <FaShareAlt color="#47A7FD" size="12" />,
    },
    {
      description: "Friends sign in with your invitation code/link.",
      icon: <FaMobileAlt color="#47A7FD" size="12" />,
    },
    {
      description: "Friends make their first transaction.",
      icon: <FaExchangeAlt color="#47A7FD" size="12" />,
    },
  ];

  const handleGenerateReferralCode = () => {
    try {
      setIsLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${stage_token}`,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_API_LIVE_URL}/api/user/invitation-code`,
          {},
          config
        )
        .then((response) => {
          setIsLoading(false);
          const { data } = response;
          toast.success(data.status.desc);
          setInvitationCode(data.entity.invite_code);
        })
        .catch((error) => {
          setIsLoading(false);
          const message = sendError(error);
          console.log(message);
        });
    } catch (error) {
      setIsLoading(false);
      const message = sendError(error);
      console.log(message);
      toast.error("There was an error generating your referral code.");
    }
  };

  const handleCopy = async (code) => {
    try {
      await navigator.clipboard.writeText(code);
      toast.success("Referral code number copied.");
    } catch (error) {
      console.error("Copy to clipboard failed:", error);
    }
  };

  useEffect(() => {
    setInvitationCode(user_data?.invite_code);
  }, [user_data]);

  return (
    <div className="shadow-md">
      <div className="xl:block w-[212px] xl:w-[212px] min-h-screen hidden z-50 bg-white navigation-shadow">
        <div>
          <Link
            onClick={() => setOpen(false)}
            href="/dashboard"
            className="ml-5 "
          >
            <div className="hidden lg:block w-[150px] ml-[33px]">
              <img src={logo.mmbLogo} alt="" className="w-[100%]" />
            </div>
            <div className="lg:hidden fixed left-0">
              <Hamburger
                color="#0b211a"
                size={20}
                label="Show menu"
                toggled={isOpen}
                toggle={setOpen}
              />
            </div>
          </Link>
        </div>

        <div className="mt-4">
          <div>
            <nav className="overflow-y-auto  transition-all ease-in scrollbar-thin hover:scrollbar-thumb-primary hover:scrollbar-track-gray-200 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              <div className="ml-[19px] mr-[13px]">
                <div className="">
                  <ul className="flex flex-col gap-[5px]">
                    {healthWorkerLinks.map((link, index) => {
                      return (
                        <li
                          key={link.label}
                          className={`${
                            e.pathname === link.to
                              ? "selected"
                              : e.pathname === "/health-worker-dashboard" &&
                                link.label === "Dashboard"
                              ? "selected"
                              : ""
                          }`}
                        >
                          <NavLink
                            to={link.to}
                            target={link.external ? "_blank" : "_self"}
                            className={`flex w-full opacity-60 hover:opacity-100  p-[10px] border-l-[3px] transition-all border-transparent hover:border-secondary hover:bg-[#47A7FD] hover:rounded-md hover:text-[#FFF] items-center space-x-2`}
                          >
                            <img src={link.icon} alt="" />

                            <span className="text-[#333] hover:text-[#FFF] font-Poppins font-normal text-[10px]">
                              {link.label}
                            </span>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                  <button
                    onClick={handleProfileClick}
                    className={`flex w-full opacity-60 hover:opacity-100  p-[10px] border-l-[3px] transition-all border-transparent hover:border-secondary hover:bg-[#47A7FD] hover:rounded-md hover:text-[#FFF] items-center space-x-2`}
                  >
                    <FaUser size="14" color="#333" />
                    <span className="text-[#333] hover:text-[#FFF] font-Poppins font-normal text-[10px]">
                      My Profile
                    </span>
                  </button>
                  <button
                    onClick={handleLogoutModalToggle}
                    className={`flex w-full opacity-60 hover:opacity-100  p-[10px] border-l-[3px] transition-all border-transparent hover:border-secondary hover:bg-[#47A7FD] hover:rounded-md hover:text-[#FFF] items-center space-x-2`}
                  >
                    <MdLogout size="14" color="red" />
                    <span className="text-[#333] hover:text-[#FFF] font-Poppins font-normal text-[10px]">
                      Logout
                    </span>
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="items-end mt-12 justify-end">
          {e.pathname === "/corporate-admin-1" ? (
            ""
          ) : (
            <div
              className={`pl-[25%] py-[55px] mx-[23px] flex align-bottom text-black bg-[#E5EFFB] rounded-[20px]  ${statusStyle}`}
            >
              <div className="w-[50px] ">
                <button onClick={handleReferralToggle}>
                  {" "}
                  <img src={referers.image} alt="" className="w-[100%]" />
                </button>
              </div>

              <p className="font-normal text-[10px] text-[#888888]">
                {referers.title}
              </p>
            </div>
          )}
        </div>
      </div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { type: "just" } }}
              exit={{
                opacity: 0,
                transition: { type: "just", delay: 0.1 },
              }}
              className="fixed top-0 left-0 right-0 bottom-0 bg-[#545f7d80]"
              onClick={() => setOpen(false)}
            />
            <motion.section
              initial={{ x: "-100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1, transition: { type: "just" } }}
              exit={{
                x: -100,
                opacity: 0,
                transition: { type: "just", delay: 0.1 },
              }}
              className="w-[283px] fixed z-40 left-0 overflow-scroll top-0 h-screen bg-white py-10 navigation-shadow "
            >
              <Link
                onClick={() => setOpen(false)}
                href="/dashboard"
                className="ml-4 flex justify-between"
              >
                <div className="mb-10">
                  <img src={logo.mmbLogo} alt="" />
                </div>
                <div className="lg:hidden">
                  <Hamburger
                    color="#0b211a"
                    size={20}
                    label="Show menu"
                    toggled={isOpen}
                    toggle={setOpen}
                  />
                </div>
              </Link>
              <nav className="overflow-y-auto transition-all ease-in scrollbar-thin hover:scrollbar-thumb-primary hover:scrollbar-track-gray-200 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                <div className="ml-[19px] mr-[13px]">
                  <div className="">
                    <ul className="flex flex-col gap-[5px]">
                      {healthWorkerLinks.map((link, index) => {
                        return (
                          <li
                            key={link.label + index}
                            className={`${
                              e.pathname === link.to
                                ? "selected"
                                : e.pathname === "/health-worker-dashboard" &&
                                  link.label === "Dashboard"
                                ? "selected"
                                : ""
                            }`}
                          >
                            <NavLink
                              to={link.to}
                              target={link.external ? "_blank" : "_self"}
                              className={
                                "flex w-full opacity-60 hover:opacity-100  p-[10px] border-l-[3px] transition-all border-transparent hover:border-secondary hover:bg-[#47A7FD] hover:rounded-md hover:text-[#FFF] items-center space-x-2"
                              }
                            >
                              <img src={link.icon} alt="" />

                              <span className="text-[#333] hover:text-[#FFF] font-Poppins font-normal text-[13px]">
                                {link.label}
                              </span>
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>

                    <button
                      onClick={handleProfileClick}
                      className={`flex w-full opacity-60 hover:opacity-100  p-[10px] border-l-[3px] transition-all border-transparent hover:border-secondary hover:bg-[#47A7FD] hover:rounded-md hover:text-[#FFF] items-center space-x-2`}
                    >
                      <FaUser size="14" color="#333" />
                      <span className="text-[#333] hover:text-[#FFF] font-Poppins font-normal text-[10px]">
                        My Profile
                      </span>
                    </button>

                    <button
                      onClick={handleLogoutModalToggle}
                      className={`flex w-full opacity-60 hover:opacity-100  p-[10px] border-l-[3px] transition-all border-transparent hover:border-secondary hover:bg-[#47A7FD] hover:rounded-md hover:text-[#FFF] items-center space-x-2`}
                    >
                      <MdLogout size="14" color="red" />
                      <span className="text-[#333] hover:text-[#FFF] font-Poppins font-normal text-[10px]">
                        Logout
                      </span>
                    </button>
                  </div>
                </div>
              </nav>

              {e.pathname === "/corporate-admin-1" ? (
                ""
              ) : (
                <div className="pl-[25%] py-[55px]  mx-[23px] flex-end text-black bg-[#E5EFFB] rounded-[20px] mt-12">
                  <div className="w-[50px] ">
                    <button onClick={handleReferralToggle}>
                      {" "}
                      <img src={referers.image} alt="" className="w-[100%]" />
                    </button>
                  </div>

                  <p className="font-normal text-[10px] text-[#888888]">
                    {referers.title}
                  </p>
                </div>
              )}
            </motion.section>
          </>
        )}
      </AnimatePresence>

      {showLogoutModal && (
        <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-full max-w-sm p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="mt-3 sm:flex">
              <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                <h4 className="text-lg font-medium text-gray-800">
                  Are you Sure?
                </h4>
                <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                  Do you realize you are Logging Out?
                </p>
                <div className="items-center gap-3 mt-3 sm:flex">
                  <button
                    className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </button>
                  <button
                    className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                    onClick={() => handleLogoutModalToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {referralModal && (
        <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-full max-w-md h-[600px] p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="">
              <div
                className="absolute z-30 top-0 w-full flex items-center justify-center"
                style={{ top: "2px" }}
              >
                <img
                  src={uploads.box}
                  alt="box"
                  className="w-[55px] h-[55px]"
                />
              </div>

              <div className="custom-shape-divider-top-1721992260">
                <svg
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1200 120"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
                    className="shape-fill"
                  ></path>
                </svg>
              </div>

              <div
                className="absolute top-0 w-full flex items-center justify-center"
                style={{ top: "55px" }}
              >
                <p className="font-bold text-white w-[165px] text-center">
                  Get rewarded when you refer our app to 20 friends or more!
                </p>
              </div>
            </div>

            <div className="mt-[160px] bg-bgbutton/10 w-30 h-30 px-8 py-4 rounded-lg mx-8">
              <h1 className="text-bgbutton font-extra-bold text-center">
                Invitation Code
              </h1>

              {invitationCode ? (
                <div className="my-2 flex items-center justify-center space-x-1">
                  <p className="text-bgbutton text-[25px] font-semibold text-center">
                    {invitationCode}
                  </p>
                  <button onClick={() => handleCopy(invitationCode)}>
                    <FaCopy color="#47A7FD" size="15" />
                  </button>
                </div>
              ) : (
                <div className="my-2 flex items-center justify-center space-x-1">
                  <button
                    onClick={handleGenerateReferralCode}
                    className="bg-bgbutton text-white w-36 px-5 py-2 rounded-lg flex items-center space-x-1 justify-center"
                  >
                    {isLoading && <LoaderIcon color="white" />}
                    <p className="text-[12px]">Generate Code</p>
                  </button>
                </div>
              )}

              <p className="text-bgbutton text-[10px] text-center">
                Help your friends keep their health record.
              </p>
            </div>

            <div className="border-gray-500 border-y mt-4 py-2 flex justify-center">
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mymedicalbankphr"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-red-600 text-white w-36 px-5 py-4 rounded-lg flex items-center space-x-1 justify-center"
                >
                  <FaShare color="white" />
                  <p className="text-sm">Invite</p>
                </a>
              </div>
            </div>

            <div className="mt-4 bg-bgbutton w-30 h-30 px-8 py-4 rounded-lg mx-8">
              <h1 className="text-white font-extra-bold text-center">
                How to Earn
              </h1>

              <div className="flex items-center justify-evenly mt-2">
                {steps.map((step, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className="bg-white rounded-full p-2 items-center w-8 h-8 flex justify-center mb-2">
                      {step.icon}
                    </div>
                    <p className="text-white text-[8px] w-12 text-center">
                      {step.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div className="z-40 absolute top-1 right-2 bg-white rounded-full p-1 w-8 h-8 flex justify-center cursor-pointer">
              <button onClick={handleReferralToggle} className="cursor-pointer">
                <FaTimes color="red" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
