import React from "react";
import Circularchart from "../../../components/charts/CircularChart";



const ChartWidget = ({ type, numbers, percentage }) => {
  return (
    <div className="bg-white flex justify-between w-[224px] h-[130px] rounded-lg p-[20px] items-center max-[650px]:w-[90%] ">
      {" "}
      <div className="w-[73px]">
        <p className="text-[9px]">{type}</p>
        <p className="text-[24px] font-bold">{numbers}</p>
      </div>
      <Circularchart percentage={percentage} pathColor={"#47A7FD"} />
    </div>
  );
};

export default ChartWidget;
