import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { MdFileOpen, MdImage, MdOutlineCameraIndoor } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useAppointmentContext } from "../../components/corporatedashboard/AppointmentProvider";
import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
import HealthWorkerDashboardSidebar from "../../components/global/HealthWorkerSidebar";
import LoadingModal from "../../components/Modal/LoadingModal";
import ReportModal from "../../components/Modal/ReportModal";
import { uploads } from "../../constants/images";
import { reducerCases } from "../../manager/constants";
import { useStateProvider } from "../../manager/StateContext";
import { useAuthManager } from "../../manager/useAuthManager";

import { imageLoader, priceFormat, sendError } from "../../utils/helpers";
import { colors } from "../../utils/style";

const HealthWorkerAppointmentView = () => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useSelector((state) => state.auth);

  const [appointmentData, setAppointmentData] = useState({
    detail: {},
    report: {},
  });

  const { id: defaultId } = useParams();
  const {
    selectedBooking,
    errors,
    inputs,
    setInputs,
    previews,
    setPreviews,
    handleFileChange,
    handleInputChange,
    handleBlur,
    handleFileDelete,
    handleReportClick,
    validateInput,
    reportModal,
    setReportModal,
  } = useAppointmentContext();

  const [{ user_data, state_message, stage_token, token }, dispatch] =
    useStateProvider();

  const {} = useAuthManager({
    middleware: "auth",
    role: "carer",
    redirectIfAuthenticated: "/",
  });

  const handleProjectView = () => {
    handleReportClick(141, defaultId);
    setReportModal(!reportModal);
  };

  const getSingleAppointmentDetail = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_LIVE_URL}/dashboard/telemedicine-booking/${defaultId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${stage_token}`,
          },
        }
      );
      const { data } = response;
      setAppointmentData({
        detail: data.contractDetails,
        report: {
          ...data.reportDetails,
          upload: JSON.parse(data.reportDetails.upload),
        },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching appointment details:", error);
    }
  };

  useEffect(() => {
    getSingleAppointmentDetail();
  }, [defaultId]);

  const handleStartClick = async (id) => {
    setIsLoading(true);
    dispatch({
      type: reducerCases.SET_MESSAGE,
      state_message: "Starting up meeting please wait...",
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const url = `${process.env.REACT_APP_API_URL}/api/v1/appointment-google/link/${id}`;
      const response = await axios.get(url, config);

      const { data } = response;

      if (data.data) {
        dispatch({
          type: reducerCases.SET_MESSAGE,
          state_message: "Meeting initialized and starting...",
        });

        setTimeout(() => {
          dispatch({
            type: reducerCases.SET_MESSAGE,
            state_message: "",
          });
          setIsLoading(false);

          window.open(data.data.link, "_blank");
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: reducerCases.SET_MESSAGE,
        state_message: "",
      });
      const message = sendError(error);
      console.log(message);
      toast.error("There was an error starting the meeting...");
    }
  };

  if (isLoading) {
    return (
      <LoadingModal
        open={isLoading}
        setOpen={setIsLoading}
        title={state_message}
      />
    );
  }

  return (
    <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat h-screen">
      <HealthWorkerDashboardSidebar isOpen={isOpen} setOpen={setOpen} />

      <div className="sm:w-screen space-x-2 h-screen overflow-y-auto custom-scrollbar">
        <CorporateHeader isOpen={isOpen} setOpen={setOpen} />

        <div className="h-screen overflow-y-auto custom-scrollbar mt-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4">
            <div>
              <div className="flex items-center space-x-2 bg-white rounded-lg px-4 py-7 w-72">
                <div className="flex items-center justify-center my-1 z-20">
                  <img
                    src={
                      imageLoader(appointmentData.detail?.carer?.imageUrl) ??
                      uploads.avatar
                    }
                    alt=""
                    width="70"
                    height="70"
                    className="border border-bgbutton rounded-full h-12 w-12"
                  />
                </div>
                <div>
                  <h5 className="font-semibold text-[12px] border-b-2 border-gray-300 py-1">
                    {appointmentData.detail?.carer?.first_name}{" "}
                    {appointmentData.detail?.carer?.last_name}
                  </h5>
                  <p className="text-[10px] font-Roboto mt-1">
                    {appointmentData.detail?.org_id?.name}
                  </p>
                </div>
              </div>

              <div className="bg-white mt-4 rounded-lg p-6">
                <p className="font-bold text-sm">Appointment Schedule</p>
                <hr className="border-b border-bgbutton" />

                <div className="mt-3">
                  <div className="flex items-center space-x-2">
                    <p className="font-bold text-[10px]">Date:</p>
                    <p className="text-[10px]">
                      {appointmentData.detail?.date}
                    </p>
                  </div>
                  <div className="flex items-center mt-1 space-x-4">
                    <div className="flex items-center space-x-2 border-r-2 border-bgbutton pr-3">
                      <p className="font-bold text-[10px]">Start Time:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.start_time}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <p className="font-bold text-[10px]">End Time:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.end_time}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 mt-1">
                    <p className="font-bold text-[10px]">Appointment Id:</p>
                    <p className="text-[10px]">
                      {appointmentData.detail?.contract_id}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2 mt-1">
                    <p className="font-bold text-[10px]">Consultation Price:</p>
                    <p className="text-[10px]">
                      {priceFormat(appointmentData.detail?.consultation_price)}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2 mt-1">
                    <p className="font-bold text-[10px]">Consultation Type:</p>
                    <p className="text-[10px]">
                      {appointmentData.detail?.consultation_type}
                    </p>
                  </div>

                  <div className="flex flex-col items-center space-y-2 mt-2">
                    <p className="font-bold text-[10px]">Health Description</p>
                    <p className="text-[8px]">
                      {appointmentData.detail?.health_description}
                    </p>
                  </div>

                  <div className="flex items-center space-x-4 mt-4">
                    <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() =>
                        handleStartClick(appointmentData.detail?._id)
                      }
                    >
                      <div className="rounded-full bg-green-500 p-2">
                        <MdOutlineCameraIndoor size="15" color={colors.white} />
                      </div>
                      <p className="font-semibold text-[10px]">
                        Start Video Meeting
                      </p>
                    </div>
                    {/* calendar feature for the probability of needing it in the future */}

                    {/* <div className="calendar-feature">
                      <button
                        onClick={() => setModalIsOpen(true)}
                        className="flex items-center justify-center space-x-2 text-blue-600 hover:text-blue-800"
                      >
                        <MdCalendarToday size={20} />
                        <span>Calendar</span>
                      </button>
                      <p className="text-sm font-bold mt-2">
                        Selected Date: {date.toDateString()}
                      </p>

                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => setModalIsOpen(false)}
                        contentLabel="Calendar Modal"
                        className="modal"
                        overlayClassName="overlay"
                      >
                        <h2>Select a Date</h2>
                        <Calendar onChange={handleDateChange} value={date} />
                        <button
                          onClick={() => setModalIsOpen(false)}
                          className="close-modal"
                        >
                          Close
                        </button>
                      </Modal>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg p-4">
              <div className="relative mt-3">
                <div className="flex items-center justify-center my-1 z-20">
                  <img
                    src={
                      imageLoader(appointmentData.detail?.patient?.imageUrl) ??
                      uploads.avatar
                    }
                    alt=""
                    width="70"
                    height="70"
                    className="border border-bgbutton rounded-full"
                  />
                </div>

                <p className="font-bold text-sm">Patient's Details</p>
                <hr className="border-b border-bgbutton" />

                <div className="my-1 ">
                  <div className="mt-2 grid grid-cols-2">
                    <div className="flex items-center space-x-2">
                      <p className="font-bold text-[10px]">Patient Id:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.patient?.patient_id}
                      </p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <p className="font-bold text-[10px]">Last Name:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.patient?.last_name}
                      </p>
                    </div>

                    <div className="flex items-center space-x-2 mt-2">
                      <p className="font-bold text-[10px]">First Name:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.patient?.first_name}
                      </p>
                    </div>

                    <div className="flex items-center space-x-2 mt-2">
                      <p className="font-bold text-[10px]">Middle Name:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.patient?.middle_name}
                      </p>
                    </div>

                    <div className="flex items-center space-x-2 mt-2">
                      <p className="font-bold text-[10px]">Gender:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.gender}
                      </p>
                    </div>

                    <div className="flex items-center space-x-2 mt-2">
                      <p className="font-bold text-[10px]">Marital Status:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.patient?.marital_status}
                      </p>
                    </div>

                    <div className="flex items-center space-x-2 mt-2">
                      <p className="font-bold text-[10px]">Nationality:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.patient?.nationality}
                      </p>
                    </div>

                    <div className="flex items-center space-x-2 mt-2">
                      <p className="font-bold text-[10px]">Religion:</p>
                      <p className="text-[10px]">
                        {appointmentData.detail?.patient?.religion}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <p className="font-bold text-sm">Appointment Report</p>
                <hr className="border-b border-bgbutton" />

                <div className="mt-2">
                  {appointmentData.report?.summary ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-2">
                      <div className="mt-2">
                        <p className="font-bold text-[10px]">Summary</p>
                        <p className="text-[10px]">
                          {appointmentData.report?.summary}
                        </p>
                      </div>

                      <div className="mt-2">
                        <p className="font-bold text-[10px]">Recommendation</p>
                        <p className="text-[10px]">
                          {appointmentData.report?.recommendation}
                        </p>
                      </div>

                      <div className="mt-2">
                        <p className="font-bold text-[10px]">
                          Recomended Drugs
                        </p>
                        <p className="text-[10px] mt-2">
                          <div className="grid grid-cols-2 gap-y-2">
                            {appointmentData.report?.medication?.map(
                              (medication, index) => (
                                <span
                                  key={index}
                                  className="bg-bgbutton/10 rounded-full px-3 py-2 font-bold mr-1 w-24 text-center"
                                >
                                  {medication}
                                </span>
                              )
                            )}
                          </div>
                        </p>
                      </div>

                      <div className="mt-2">
                        <p className="font-bold text-[10px]">
                          Recommended Test
                        </p>
                        <p className="text-[10px] mt-2">
                          <div className="grid grid-cols-2 gap-y-2">
                            {appointmentData.report?.test?.map(
                              (test, index) => (
                                <span
                                  key={index}
                                  className="bg-bgbutton/10 rounded-full px-3 py-2 font-bold mr-1 w-24 text-center"
                                >
                                  {test}
                                </span>
                              )
                            )}
                          </div>
                        </p>
                      </div>
                      {appointmentData.report?.upload && (
                        <div className="mt-2">
                          <p className="font-bold text-[10px]">File</p>
                          <div className="mt-2 flex items-center space-x-3">
                            {appointmentData.report.upload.map(
                              (data, index) => (
                                <div key={index}>
                                  {/* {data.toLowerCase().endsWith(".jpg") ||
                                data.toLowerCase().endsWith(".jpeg") ||
                                data.toLowerCase().endsWith(".png") ? (
                                  <MdImage size="20" color="#00aaff" />
                                ) : (
                                  <MdFileOpen size="20" color="#00aaff" />
                                )} */}
                                  {data.toLowerCase().endsWith(".pdf") && (
                                    <MdFileOpen size="20" color="#00aaff" />
                                  )}

                                  {(data.toLowerCase().endsWith(".jpg") ||
                                    data.toLowerCase().endsWith(".jpeg") ||
                                    data.toLowerCase().endsWith(".png")) && (
                                    <MdImage size="20" color="#00aaff" />
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center">
                      <img
                        src={uploads.noData}
                        alt=""
                        width={150}
                        height={150}
                      />
                      <p className="text-red-500 text-[12px] font-semibold">
                        No report submitted yet!
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* btn */}
            <div></div>
            <div className="w-full flex justify-center items-center">
              <button
                className="px-5 py-3 border rounded-md bg-blue-500 text-white font-semibold text-[12px] mt-2 text-center"
                onClick={handleProjectView}
              >
                Report
              </button>
            </div>
            {reportModal && (
              <ReportModal
                id={selectedBooking}
                title="Appointment Report"
                description="Submit appointment report for patient"
                buttonText="Submit"
                onConfirm={validateInput}
                onClose={() => setReportModal(!reportModal)}
                isLoading={isLoading}
                inputs={inputs}
                setInputs={setInputs}
                previews={previews}
                setPreviews={setPreviews}
                handleInputChange={handleInputChange}
                handleFileChange={handleFileChange}
                handleBlur={handleBlur}
                handleFileDelete={handleFileDelete}
                errors={errors}
                selectedBooking={selectedBooking}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthWorkerAppointmentView;
