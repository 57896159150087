import React from "react";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import bolt from '../../global/assets/bolt.svg';

const Roundchart = ({ percentage, pathColor }) => {
  return (
    <>
    <div className="w-[90%] px-6 py-[5rem] border shadow-md rounded-3xl ">
 <CircularProgressbarWithChildren value={40}>
  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
  <img style={{ width: 40, marginTop: 5 }} src={bolt} alt="bolt" />
</CircularProgressbarWithChildren>;
    </div>
    
          </>
  );
};

export default Roundchart ;
