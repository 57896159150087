import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import HealthWorkerProfile from "../../../pages/hw-profile-setup/HealthWorkerProfile";
import UserProfileHeader from "./UserProfileHeader";
import { doctor } from "../../../constants/images";
import { useStateProvider } from "../../../manager/StateContext";
import { useAuthManager } from "../../../manager/useAuthManager";

const UserProfileSetup = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const [{ user_data, health_worker_detail }] = useStateProvider();

  const handleClickButton = (id) => {
    navigate(`/user/health-worker${id ? `/${id}` : ""}`, {
      replace: true,
    });
  };

  const handleClickOrg = () => {
    navigate("/user/corporate", { replace: true });
    navigate(0);
  };

  return (
    <>
      <UserProfileHeader Breadcrumb="Provider's Setup Page" />

      <section
        className="relative bg-contain md:bg-cover bg-center bg-no-repeat text-center text-slate-600"
        style={{
          backgroundImage: `url(${doctor.Lifesavers})`,
          height: "calc(100vh - 118px)",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-30"></div>{" "}
        <div className="relative z-10 max-w-2xl mx-auto space-y-8 flex flex-col justify-center h-full px-4">
          {/* <h1 className="text-4xl md:text-5xl font-extrabold tracking-wide leading-tight drop-shadow-lg">
            Welcome!
          </h1>
          <p className="text-base md:text-lg leading-relaxed tracking-wider italic">
            Thank you for registering. <br />
            Let's kickstart your profile setup with ease.
          </p>
          <button
            onClick={() => handleClickButton(user_data?.id)}
            type="submit"
            className="bg-bgbutton hover:bg-blue-400 text-white font-semibold rounded-full text-lg py-3 px-5 focus:outline-none focus:ring-4 focus:ring-blue-200 transition-all duration-300 shadow-lg"
          >
            Begin Profile Setup
          </button> */}
          <h1 className="text-4xl md:text-5xl font-extrabold tracking-wide leading-tight drop-shadow-lg text-white">
            Welcome!
          </h1>
          <p className="text-base md:text-lg leading-relaxed tracking-wider italic text-white">
            Thank you for registering. <br />
            Let's kickstart your profile setup with ease.
          </p>
          <button
            onClick={() => handleClickButton(user_data?.id)}
            type="submit"
            className="bg-[#0056b3] hover:bg-[#004494] text-white font-semibold rounded-full text-lg py-3 px-5 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-all duration-300 shadow-lg"
          >
            Begin Profile Setup
          </button>
        </div>
        {showModal && (
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-sm p-4 mx-auto  rounded-md shadow-lg">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  <button
                    onClick={() => setShowModal(false)}
                    type="button"
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="authentication-modal"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="px-6 py-6 lg:px-8 h-[300px]">
                    <h3 className="mb-6 text-[15px] text-center font-medium font-Roboto text-black-600 dark:text-white">
                      Choose a Health Worker Category.
                    </h3>
                    <p className=" mb-8 text-[13px] text-center">
                      Note: Independent Health Worker is for someone without any
                      affiliation to an organisation.
                    </p>
                    <form className="space-y-6 space-x-6" action="">
                      <button
                        onClick={() =>
                          handleClickButton(health_worker_detail?._id)
                        }
                        type="submit"
                        className="ml-14 text-white bg-bgbutton hover:bg-blue-800 focus:ring-4
        focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
         dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800"
                      >
                        Independent Health Worker
                      </button>
                      <button
                        onClick={() => handleClickButton()}
                        type="submit"
                        className="bg-transparent  text-bgbutton bg-blue-700 hover:bg-bgbutton focus:ring-4
        focus:outline-none focus:ring-bgbutton-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:text-white
         dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800 border border-bgbutton "
                      >
                        Health Worker with an Organisation
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default UserProfileSetup;
