
const TextareaInput = ({
  error,
  disabled = false,
  label,
  className,
  onFocus = () => {},
  ...props
}) => (
  <div>
    <label>{label}</label>
    <textarea
      disabled={disabled}
      {...props}
      type="text"
      className={`${className} resize-none font-mono placeholder-sm tracking-wide w-full px-4 pt-3 border-2 border-gray-300 rounded-lg focus:border-bgbutton focus:outline-none`}
    ></textarea>
    {error && <p style={{ color: "red", fontSize: 12 }}>{error}</p>}
  </div>
);

export default TextareaInput;
