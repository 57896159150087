import React from "react";

import DonutChart from "../../components/global/DonutChart";
import PaymentHealthWorker from "../../components/Users/HealthWorkersUsers/healthWorker/PaymentHealthWorker";
import { images } from "../../constants/icons";

const PaymentHealthContainer = () => {
  const title = "Payment";
  return (
    <div className="bg-white rounded-lg p-2 sm:p-[20px] sm:w-[258px] h-[350px]">
      <div className="flex justify-between items-center ">
        <p className="text-base font-bold"> {title}</p>
      </div>

      <div className="flex justify-center">
        <div className="h-[141.22px] w-[121.22px] ">
          <DonutChart />
        </div>
      </div>
      <PaymentHealthWorker />
    </div>
  );
};

export default PaymentHealthContainer;
