import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

import { AppointmentProvider } from "./components/corporatedashboard/AppointmentProvider";
import PreventReload from "./components/PreventReload";
import AuthProvider from "./authProvider";
import { StateProvider } from "./manager/StateContext";
import reducer, { initialState } from "./manager/StateReducers";

import store from "./services/redux/store";

import RoutesManager from "./routesManager/RoutesManager";

import "./css/custom.css";
import "react-calendar/dist/Calendar.css";

function App() {
  return (
    <Provider store={store}>
      <PreventReload />
      <Toaster />
      <AuthProvider>
        <StateProvider initialState={initialState} reducer={reducer}>
          <AppointmentProvider>
            <RoutesManager />
          </AppointmentProvider>
        </StateProvider>
      </AuthProvider>
    </Provider>
  );
}

export default App;
