import React from "react";
import OverviewWidget from "../../components/Admin/dashboard/OverviewWidget";
import Linechart from "../../components/charts/Linechart";
import { useLocation } from "react-router-dom";


const ReportContainer = ({superAdmindata}) => {
  const e = useLocation();
  // console.log(e.pathname);
  // console.log(superAdmindata.data.percentVerified,);

  return (
    <div className="flex w-[100%] max-[650px]:flex-col max-[650px]:w-[90%] ">
      <div>
        <div>
          <OverviewWidget
            title={`${superAdmindata?.data?.percentVerified || 0} of users are yet to be complete registration`}
            numbers={superAdmindata?.data?.totalUsers || 0}
            group={"Users"}
            middle={"Verified"}
            bottom={"Unverified"}
            topNum={superAdmindata?.data?.new || 0}
            middleNum={superAdmindata?.data?.verified  || 0}
            bottomNum={superAdmindata?.data?.unverified || 0}
          />
        </div>
        
        {e.pathname !== "/services/phr" && (
          <div className="mt-[29px]">
            <OverviewWidget
              title={"25% of corporate renew their subscription"}
              numbers={500}
              group={"Organizations"}
              middle={"Subscribed"}
              bottom={"Expired"}
              topNum={2}
              middleNum={448}
              bottomNum={50}
            />
          </div>
        )}
      </div>

      <div className="  w-[calc(100%-339px)]">
        <Linechart />
      </div>
    </div>
  );
};

export default ReportContainer;
