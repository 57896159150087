import React from "react";

import { images } from "../../../../constants/icons";
import { useStateProvider } from "../../../../manager/StateContext";

import { imageLoader } from "../../../../utils/helpers";

const HealthWorkerID = () => {
  const [{ health_worker_detail, user_data }] = useStateProvider();

  return (
    <div className="bg-white rounded-lg p-[10px] sm:p-[20px] mb-[15px] w-full sm:w-[258px] h-[350px]">
      <div>
        <div className="mb-[20px] flex justify-center">
          <div></div>
          <div className="text-center">
            <p className="w-fit h-fit mx-auto">
              <img
                src={imageLoader(user_data?.imageUrl)}
                alt=""
                className="border-2 border-bgbutton rounded-full h-[80px] w-[80px]"
              />
            </p>

            <div className="mt-2">
              <p className="text-[12px] text-textgray">
                {user_data?.first_name} {user_data?.last_name}
              </p>
              <p className="text-[10px] text-textgray">
                {user_data?.email}
              </p>
            </div>
          </div>

         
        </div>
        <div>
          <div className="w-full">
            <p className="text-[14px] font-medium">Personal Details</p>

            <div className="text-[12px] mt-2">
              <div className="text-textgray">
                <div className="flex justify-between items-center">
                  <p className="font-bold">Location: </p>
                  <p>{user_data?.state}</p>
                </div>

                <div className="flex justify-between items-center mt-2">
                  <p className="font-bold">Account Type: </p>
                  <p>{user_data?.account_type}</p>
                </div>

                <div className="flex justify-between items-center mt-2">
                  <p className="font-bold">Id: </p>
                  <p>{health_worker_detail?.carer_id}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthWorkerID;
