import { createSlice } from "@reduxjs/toolkit";
import { fetchUserById } from '../actions/dashboardAction';

const initialState = {
    loading: false,
    error: null,
    success: false,
    userDetails: {},
};

const DashboardSlice = createSlice({
    name: 'userDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.userDetails = action.payload;
            })
            .addCase(fetchUserById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default DashboardSlice.reducer;
