import React, { useEffect, useState } from 'react'
import axios from 'axios';
import toast, { LoaderIcon } from 'react-hot-toast';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";

import { useStateProvider } from '../../manager/StateContext';
import TextInput from '../TextInput';

import { sendError, setNextTab } from '../../utils/helpers';
import { colors } from '../../utils/style';

const Referee = ({
  activeTab,
  tab,
  setActiveTab,
  isLoading,
  setIsLoading,
  handlePrev,
}) => {
  // const { accessToken } = useSelector((state) => state.auth);
  const [{ user_data, health_worker_detail, token }] = useStateProvider();

  const initialData = JSON.parse(localStorage.getItem("refData")) || {
    ref_name: "",
    ref_email: "",
    ref_phone: "",
    referee_address: "",
  };
  const [refData, setRefData] = useState(initialData);

   useEffect(() => {
     localStorage.setItem("refData", JSON.stringify(refData));
   }, [refData]);

  const handleRefereeChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setRefData((refData) => ({
        ...refData,
        [name]: value,
      }));
    }
  };

  const handleUpdateReferee = () => {
    Swal.fire({
      title: "Update Referee",
      text: "Are you sure you want to update your referee details?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      cancelButtonColor: "red",
      confirmButtonColor: "#00aaff",
      customClass: {
        title: "swal2-title-custom",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          setIsLoading(true);
          const url = `${process.env.REACT_APP_API_URL}/api/v1/cooperate/health-worker/update-health-worker-information/${user_data?.id}`;
          axios
            .put(url, { ...refData }, config)
            .then((response) => {
              setIsLoading(false);
              const { data } = response;
              toast.success(data.message);
              setNextTab(activeTab, tab, setActiveTab);
            })
            .catch((error) => {
              setIsLoading(false);
              const message = sendError(error);
              console.log(message);
              toast.error(message.message);
            });
        } catch (error) {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.error);
        }
      }
    });
  };

  return (
    <div className="mx-5 flex-1">
      <h5 className="text-[15px] font-semibold">Referee</h5>
      <p className="text-[10px]">Let us know who your referee is!</p>

      <div className="my-2 grid grid-cols-1 sm:grid-cols-2 sm:gap-4">
        <div>
          <TextInput
            label="Enter name"
            type="text"
            id="ref_name"
            name="ref_name"
            value={refData.ref_name}
            onChange={handleRefereeChange}
            className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
          />
        </div>

        <div>
          <TextInput
            label="Enter email"
            type="email"
            id="ref_email"
            name="ref_email"
            value={refData.ref_email}
            onChange={handleRefereeChange}
            className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
          />
        </div>

        <div>
          <TextInput
            label="Enter phone number"
            type="text"
            id="ref_phone"
            name="ref_phone"
            value={refData.ref_phone}
            onChange={handleRefereeChange}
            className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
          />
        </div>

        <div>
          <TextInput
            label="Referee Job Title & Work Address"
            type="text"
            id="referee_address"
            name="referee_address"
            value={refData.referee_address}
            onChange={handleRefereeChange}
            className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
          />
        </div>
      </div>

      <div className="flex items-center justify-between mt-4 w-full">
        <div className="flex cursor-pointer items-center space-x-2 mr-2">
          <div onClick={handlePrev} className="flex items-center space-x-1">
            <FaArrowAltCircleLeft size={25} color={colors.primary} />
            <p className="font-bold">Back</p>
          </div>
        </div>

        <button onClick={handleUpdateReferee} className="button">
          <div className="flex items-center space-x-3">
            {isLoading && <LoaderIcon size="20" color="white" />}
            <p>Update</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Referee