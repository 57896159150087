import { createSlice } from "@reduxjs/toolkit";
import {getAllOrg} from '../actions/formAction'

const initialState = {
    loading: false,
    error: null,
    success: false,
    orgDetails: [],
};

const OrganisationSlice = createSlice({
    name: 'orgDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrg.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllOrg.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.orgDetails = action.payload;
            })
            .addCase(getAllOrg.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default OrganisationSlice.reducer;
