import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { formIcons } from "../../../constants/icons";
import { reducerCases } from "../../../manager/constants";
import { useStateProvider } from "../../../manager/StateContext";
import FormField from "../../FormField";
import Error from "../../global/Error";
import Spinner from "../../global/Spinner";
import StatusMessage from "../../StatusMessage";

import { sendError } from "../../../utils/helpers";
import { userLogin } from "../../../services/redux/auth/authAction/authAction";

const Login = ({ setShowLoginModal, setShowModal, handleResetPassword }) => {
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const [isLoading, setIsLoading] = useState(false);

  // Password eye icon
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // Prevent form submission
    setPasswordVisible(!passwordVisible);
  };

  const handleRegister = () => {
    setShowLoginModal(false);
    setShowModal(true);
  };

  const { loading, error, userInfo, success, accessToken } = useSelector(
    (state) => state.auth
  );

  const [
    { health_worker_detail, user_data, stage_token, token, user_creds },
    dispatch,
  ] = useStateProvider();

  const dispatcher = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  const fetchUserInfo = useCallback(
    async (id, accessToken) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };

        axios
          .get(`${BASE_URL}/api/v1/user/get-user/${id}`, config)
          .then((response) => {
            setIsLoading(false);
            toast.success("Login successful. You are being redirected.");

            const { data } = response;
            console.log(data)
            dispatch({
              type: reducerCases.SET_USER_DATA,
              user_data: data.data,
            });

            if (data?.data?.first_name && data?.data?.last_name) {
              navigate("/health-worker-dashboard");
            } else {
              navigate("/set-up");
            }
          })
          .catch((error) => {
            setIsLoading(false);
            const message = sendError(error);
            console.log(message);
            dispatch({
              type: reducerCases.SET_MESSAGE_STATUS,
              message_status: {
                type: "error",
                message: message.message,
              },
            });
          });
      } catch (error) {
        const message = sendError(error);
        console.log(message);
        dispatch({
          type: reducerCases.SET_MESSAGE_STATUS,
          message_status: {
            type: "error",
            message: message.message,
          },
        });
      }
    },
    [BASE_URL, dispatch, health_worker_detail, navigate, token]
  );

  const fetchWorkerDetails = useCallback(
    async (id, token, accessToken) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_LIVE_URL}/all-doctors/${id}`,
          config
        );

        const result = response.data;
        if (result) {
          dispatch({
            type: reducerCases.SET_HEALTHWORKER_INFO,
            health_worker_detail: result,
          });

          fetchUserInfo(result?.user_id?._id, accessToken);
        }
      } catch (error) {
        setIsLoading(false);
        const message = sendError(error);
        dispatch({
          type: reducerCases.SET_MESSAGE_STATUS,
          message_status: {
            type: "error",
            message: message.message,
          },
        });
      }
    },
    [stage_token, dispatch, fetchUserInfo]
  );

  const stageLogin = useCallback(
    async (email, password, accessToken) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios
          .post(
            `${process.env.REACT_APP_API_LIVE_URL}/api/account/login`,
            { email, password },
            config
          )
          .then((response) => {
            const { data } = response;
            const token = data.entity.token;
            localStorage.setItem("token", token);

            dispatch({
              type: reducerCases.SET_STAGING_TOKEN,
              stage_token: token,
            });

            // fetchUserInfo(data?.entity?.detail?._id);
            fetchWorkerDetails(data?.entity?.detail?._id, token, accessToken);
          })
          .catch((error) => {
            const message = sendError(error);
            console.log(message);
            dispatch({
              type: reducerCases.SET_MESSAGE_STATUS,
              message_status: {
                type: "error",
                message: message.message,
              },
            });
          });
      } catch (error) {
        setIsLoading(false);
        const message = sendError(error);
        console.log(message);
      }
    },
    [dispatch, fetchWorkerDetails]
  );

  // useEffect(() => {
  //   if (userInfo && success) {
  //     stageLogin(user_creds.email, user_creds.password);

  //     if (userInfo?.account_type === "carer") {
  //       fetchUserInfo();
  //     } else {
  //       navigate("/dashboard");
  //     }
  //   }
  // }, [
  //   userInfo,
  //   success,
  //   navigate,
  //   fetchUserInfo,
  //   stageLogin,
  //   user_creds?.email,
  //   user_creds?.password,
  // ]);

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    dispatch({
      type: reducerCases.SET_USER_CREDENTIALS,
      user_creds: {
        email: formData.email,
        password: formData.password,
      },
    });

    // dispatcher(userLogin(formData));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    axios.post(
       `${process.env.REACT_APP_API_LIVE2_URL}/api/v1/cooperate/user/login`,
       { ...formData },
       config
     ).then((response) => {
        const { data } = response;
        dispatch({
          type: reducerCases.SET_TOKEN,
          token: data?.data?.accessToken,
        });
        localStorage.setItem("accessToken", data?.data?.accessToken);
        stageLogin(formData.email, formData.password, data?.data?.accessToken);
      }).catch((error) => {
        setIsLoading(false);
        console.log(error);
        const message = sendError(error);
        dispatch({
          type: reducerCases.SET_MESSAGE_STATUS,
          message_status: {
            type: "error",
            message: message.message,
          },
        });
      });
  };

  return (
    <div className="justify-center mt-4 overflow-x-hidden overflow-y-auto custom-scrollbar fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-[3rem] mx-auto max-w-3xl">
        <main className="flex flex-col items-center sm:px-4 mx-2 sm:mx-0">
          <div className="bg-bgbutton px-3 py-1 text-white rounded-full mb-2 text-center">
            <button
              onClick={() => setShowLoginModal(false)}
              className="text-center text-sm"
            >
              x
            </button>
          </div>
          <div className="space-y-6 text-gray-600 sm:max-w-md">
            <div className="bg-white rounded-[20px] shadow-2xl p-4 sm:px-[3.5rem] sm:rounded-2xl w-full sm:w-[457px] h-[563px]">
              <div className="text-center">
                <div className="mt-5 space-y-2">
                  <h3 className="text-gray-800 text-[23px] font-Roboto font-bold sm:text-[30px] leading-[39.5px]">
                    Log in to your account
                  </h3>
                  <div className="flex items-center space-x-2">
                    <p className="text-black font-Roboto leading-[16.8px] text-[16px] z-50">
                      Don’t have an account?
                    </p>
                    <button
                      type="button"
                      onClick={handleRegister}
                      className="font-medium text-[#47A7FD] hover:text-indigo-500"
                    >
                      Create a free account
                    </button>
                  </div>
                </div>
              </div>
              <form onSubmit={submitForm} className="space-y-5">
                {error && <Error message={error}>{error}</Error>}

                <StatusMessage />
                
                <div className="pt-2 sm:pt-12">
                  <label className="font-medium"></label>
                  <FormField
                    className="w-full mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                    name="email"
                    type="email"
                    placeholder="email@gmail.com"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label className="font-medium">Password</label>
                  <div className="relative">
                    <FormField
                      className="w-full mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                      name="password"
                      type={passwordVisible ? "text" : "password"}
                      placeholder="***********"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="absolute top-4 right-2">
                      <button onClick={togglePasswordVisibility}>
                        <img
                          src={
                            passwordVisible
                              ? formIcons.eyedisabled
                              : formIcons.eyedisabled
                          }
                          alt=""
                        />
                      </button>
                    </div>
                  </div>

                  <div className="flex items-end justify-end z-50">
                    <button
                      type="button"
                      onClick={handleResetPassword}
                      className="text-[12px] text-bgbutton mt-2 font-semibold"
                    >
                      Forgot Password?
                    </button>
                  </div>
                </div>
                {isLoading ? (
                  <button
                    type="submit"
                    className="w-full px-4 py-2 text-white font-medium bg-[#47A7FD] hover-bg-[#47A7FD]-500
              active-bg-indigo-600 rounded duration-150
                  mr-2 mb-2"
                    disabled={isLoading}
                  >
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full px-4 py-2 text-white font-medium bg-[#47A7FD] hover-bg-[#47A7FD]-500
                      active-bg-indigo-600 rounded duration-150"
                    disabled={isLoading}
                  >
                    {" "}
                    Login
                  </button>
                )}
              </form>
              <div className="mt-5"> </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Login;
