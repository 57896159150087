import React from "react";
import ChartWidget from "../../components/Admin/dashboard/ChartWidget";

const  ChartContainer = () => {
  return (
    <div className="flex justify-between mt-[20px] max-[650px]:w-[90%] max-[650px]:flex-col ">
      <div className="max-[650px]:w-[90%]">
        <ChartWidget type={'Bookings'} numbers={1000} percentage={53} 
/>
      </div>
      <div className="ml-5  max-[650px]:ml-0  max-[650px]:w-[90%] ">
        <ChartWidget type={'Health Workers'} numbers={300} percentage={72} />
      </div>
      <div className="ml-5  max-[650px]:ml-0">
        <ChartWidget type={'Subscriptions'} numbers={520} percentage={80} />
      </div>
    </div>
  );
};

export default ChartContainer;
