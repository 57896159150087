import { serviceCorpIcons, images } from "../../constants/icons";
import {
  corporatePageImages,
  msgImages,
  withdrawalsImages,
} from "../../constants/images";

export const corporateDisplayCards = [
  {
    title: "Corporate Sponsor",
    description: "Amet minim mollit non",
    img: corporatePageImages.sponsorImg,
    bg: "#F2DBB1",
  },
  {
    title: "Healthcare Provider",
    description: "Amet minim mollit non",
    img: corporatePageImages.healthCareImg,
    bg: "#679AFF",
  },
  {
    title: "Corporate Member",
    description: "Amet minim mollit non",
    img: corporatePageImages.memberImg,
    bg: "#97D482",
  },
];

export const emergencyHeader = [{ title: "Name" }, { title: "Date" }];

export const appointmentHeader = [
  { title: "Name" },
  { title: "Contract ID" },
  { title: "Amount" },
  { title: "Start Time" },
  { title: "Duration" },
  { title: "Date" },
  { title: "Status" },
  { title: "Action" },
];

export const appointmentData = [
  {
    name: "Jenny Wilson",
    image: msgImages.alexlieImg,
    service: "Homecare",
    id: "CTR-1234",
    amount: "#10,000",
    startDate: "11/08/2023",
    endDate: "14/03/2023",
    percentage: "0%",
    status: "Pending",
    icon: images.verticalIcon,
    bg: "#F2F2F2",
  },
  {
    name: "Jane Coopers",
    image: msgImages.cameronImg,
    service: "Homecare",
    id: "CTR-1234",
    amount: "#10,000",
    startDate: "11/08/2023",
    endDate: "14/03/2023",
    percentage: "80%",
    status: "Ongoing",
    icon: images.verticalIcon,
    bg: "#FABC2F",
  },
  {
    name: "Jenny Wilson",
    image: msgImages.cameronImg,
    service: "Homecare",
    id: "CTR-1234",
    amount: "#10,000",
    startDate: "11/08/2023",
    endDate: "14/03/2023",
    percentage: "50%",
    status: "Ongoing",
    icon: images.verticalIcon,
    bg: "#FABC2F",
  },
  {
    name: "Jenny Wilson",
    image: msgImages.cameronImg,
    service: "Homecare",
    id: "CTR-1234",
    amount: "#10,000",
    startDate: "11/08/2023",
    endDate: "14/03/2023",
    percentage: "50%",
    status: "Ongoing",
    icon: images.verticalIcon,
    bg: "#FABC2F",
  },
  {
    name: "Jenny Wilson",
    image: msgImages.cameronImg,
    service: "Homecare",
    id: "CTR-1234",
    amount: "#10,000",
    startDate: "11/08/2023",
    endDate: "14/03/2023",
    percentage: "0%",
    status: "Pending",
    icon: images.verticalIcon,
    bg: "#F2F2F2",
  },
  {
    name: "Kristin Watson",
    image: msgImages.cameronImg,
    service: "Homecare",
    id: "CTR-1234",
    amount: "#10,000",
    startDate: "11/08/2023",
    endDate: "14/03/2023",
    percentage: "30%",
    status: "Ongoing",
    icon: images.verticalIcon,
    bg: "#FABC2F",
  },
  {
    name: "Guy Hawkins",
    image: msgImages.cameronImg,
    service: "Homecare",
    id: "CTR-1234",
    amount: "#10,000",
    startDate: "11/08/2023",
    endDate: "14/03/2023",
    percentage: "0%",
    status: "Pending",
    icon: images.verticalIcon,
    bg: "#F2F2F2",
  },
  {
    name: "Jenny Wilson",
    image: msgImages.cameronImg,
    service: "Homecare",
    id: "CTR-1234",
    amount: "#10,000",
    startDate: "11/08/2023",
    endDate: "14/03/2023",
    percentage: "100%",
    status: "Completed",
    icon: images.verticalIcon,
    bg: "#4AF06F",
  },
  {
    name: "Jenny Wilson",
    image: msgImages.cameronImg,
    service: "Homecare",
    id: "CTR-1234",
    amount: "#10,000",
    startDate: "11/08/2023",
    endDate: "14/03/2023",
    percentage: "100%",
    status: "Completed",
    icon: images.verticalIcon,
    bg: "#4AF06F",
  },
];

export const serviceData = [
  {
    logo: serviceCorpIcons.houseBlueCorp,
    numbers: 200,
    service: "Homecare",
  },
  {
    logo: serviceCorpIcons.mobileCorpIcon,
    numbers: 120,
    service: "Telemedicine",
  },
  {
    logo: serviceCorpIcons.housePurCorpIcon,
    numbers: 250,
    service: "Clinic Visit",
  },
  {
    logo: serviceCorpIcons.userCorpIcon,
    numbers: 50,
    service: "Health Market",
  },
  {
    logo: serviceCorpIcons.jobsIcon,
    numbers: 100,
    service: "Health Jobs",
  },
];

export const reviewData = [
  {
    name: "Kathryn Murphy",
    image: withdrawalsImages.guyImg,
    mssg: "Services was okay, would re.....",
  },
  {
    name: "Olarenwaju Saheed",
    image: withdrawalsImages.penaImg,
    mssg: "Services was okay, would re.....",
  },
  {
    name: "Olarenwaju Saheed",
    image: withdrawalsImages.penaImg,
    mssg: "Services was okay, would re.....",
  },
];

export const paymentData = [
  {
    title: "Earning Balance",
    balanceDetails: [
      { name: "Current Balance:", balance: "#35,000" },
      { name: "Withdrawn:", balance: "#10,000" },
      { name: "Pending:", balance: "#5,000" },
    ],
  },
];
