import React, { useRef, useState } from 'react'
import axios from 'axios';
import toast, { LoaderIcon } from 'react-hot-toast';
import { FaArrowAltCircleLeft, FaCheckCircle } from 'react-icons/fa';

import { healthWorkerIcons } from '../../constants/icons';
import { useStateProvider } from '../../manager/StateContext';

import { sendError, setNextTab } from '../../utils/helpers';
import { colors } from '../../utils/style';

const Qualification = ({
  activeTab,
  tab,
  setActiveTab,
  isLoading,
  setIsLoading,
  handlePrev,
}) => {
  const [{ stage_token, health_worker_detail }] = useStateProvider();

  const [qualificationData, setQualificationData] = useState({
    edu_cert: null,
    license: null,
    reg_cert: null,
    nysc: null,
    nat_id: null,
    work_id: null,
    ref: null,
  });

  const fileInputRefs = useRef({
    edu_cert: React.createRef(),
    license: React.createRef(),
    reg_cert: React.createRef(),
    nysc: React.createRef(),
    nat_id: React.createRef(),
    work_id: React.createRef(),
    ref: React.createRef(),
  });

  const handleFileChange = (e, key) => {
    const selectedFile = e.currentTarget.files[0];

    if (selectedFile) {
      setQualificationData((prevFormData) => ({
        ...prevFormData,
        [key]: selectedFile,
      }));
    }
  };

  const handleClick = (key) => {
    const fileInputRef = fileInputRefs.current[key];

    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.style.position = "absolute";
      fileInputRef.current.style.left = "-9999px";
      fileInputRef.current.click();
      fileInputRef.current.addEventListener("change", () => {
        fileInputRef.current.style.position = "none";
        fileInputRef.current.style.left = "none";
      });
    }
  };

  const handleUploadDocument = async () => {
    const health = {
      edu_cert: qualificationData.edu_cert,
      license: qualificationData.license,
      reg_cert: qualificationData.reg_cert,
      nysc: qualificationData.nysc,
      work_id: qualificationData.work_id,
      nat_id: qualificationData.nat_id,
      ref: qualificationData.ref,
    };

    const formDataToSend = new FormData();

    Object.entries(health).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          formDataToSend.append(`${key}[${index}]`, element);
        });
      } else {
        formDataToSend.append(key, value === "null" ? null : value);
      }
    });

    Object.entries(qualificationData).forEach(([key, file]) => {
      if (key.startsWith("file") && file) {
        formDataToSend.append(key, file, file.name);
      }
    });

    setIsLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${stage_token}`,
        },
      };

      const url = `${process.env.REACT_APP_API_LIVE_URL}/api/carer/verify`;
      axios
        .post(url, formDataToSend, config)
        .then((response) => {
          setIsLoading(false);
          const { data } = response;
          toast.success(data.status.desc);
          setNextTab(activeTab, tab, setActiveTab);
        })
        .catch((error) => {
          setIsLoading(false);

          const message = sendError(error);
          console.log(message);
          toast.error(
            "There was an error updating your information! Please try again."
          );
        });
    } catch (error) {
      setIsLoading(false);
      toast.error("There was an error updating your file! Please try again.");
    }
  };

  return (
    <div className="mx-5">
      <div className="mb-3">
        <h5 className="font-bold text-sm">Verification Document</h5>
        <p className="text-xs">Update your doucuments</p>

        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center w-full  sm:gap-x-4 py-2">
          <div className="relative w-full">
            {health_worker_detail?.edu_cert && (
              <div className="absolute top-4 right-0">
                <FaCheckCircle size="20" color={colors.primary} />
              </div>
            )}
            <div className="w-full mt-2">
              <label className="font-semibold max-w-xl text-[10px] text-black">
                <span className="text-[#D60000]">*</span>
                <i>Upload Education Certificate</i>
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                <label htmlFor="fileInput-edu_cert" className="cursor-pointer">
                  <img
                    src={healthWorkerIcons.dashicons}
                    className="px-[10rem] py-2 items-center"
                    alt=""
                  />
                  <p className="text-center text-[#47A7FD] text-sm">
                    <span className="text-black">
                      {qualificationData.edu_cert &&
                      qualificationData.edu_cert.name
                        ? `Selected File: ${qualificationData.edu_cert.name}`
                        : " Click here to Add your files here "}
                    </span>
                  </p>
                </label>
                <input
                  id="fileInput-edu_cert"
                  name="edu_cert"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "edu_cert")}
                  ref={fileInputRefs.edu_cert}
                />
              </div>
              <h1 className="max-w-[15rem] text-[8px]">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            </div>
          </div>

          <div className="relative w-full">
            {health_worker_detail?.license && (
              <div className="absolute top-4 right-0">
                <FaCheckCircle size="20" color={colors.primary} />
              </div>
            )}
            <div className="w-full mt-2">
              <label className="font-semibold max-w-xl text-[10px] text-black">
                <span className="text-[#D60000]">*</span>
                <i> Upload license</i>
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                <label htmlFor="fileInput-license" className="cursor-pointer">
                  <img
                    src={healthWorkerIcons.dashicons}
                    className="px-[10rem] py-2 items-center"
                    onClick={() => handleClick("license")}
                    alt=""
                  />
                  <p
                    className="text-center text-[#47A7FD] text-sm cursor-auto"
                    onClick={() => handleClick("license")}
                  >
                    <span className="text-black">
                      {qualificationData.license &&
                      qualificationData.license.name
                        ? `Selected File: ${qualificationData.license.name}`
                        : " Click here to Add your files here "}
                    </span>
                  </p>
                </label>
                <input
                  id="fileInput-license"
                  name="license"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "license")}
                  ref={fileInputRefs.license}
                />
              </div>
              <h1 className="max-w-[15rem] text-[8px]">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            </div>
          </div>

          <div className="relative w-full">
            {health_worker_detail?.reg_cert && (
              <div className="absolute top-4 right-0">
                <FaCheckCircle size="20" color={colors.primary} />
              </div>
            )}
            <div className="w-full mt-2">
              <label className="font-semibold max-w-xl text-[10px] text-black">
                <span className="text-[#D60000]">*</span>
                <i> Upload Certificate of Registration</i>
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                <label htmlFor="fileInput-reg_cert" className="cursor-pointer">
                  <img
                    src={healthWorkerIcons.dashicons}
                    className="pl-[8rem] py-2 items-center"
                    onClick={() => handleClick("reg_cert")}
                    alt=""
                  />
                  <p
                    className="text-center text-[#47A7FD] text-sm"
                    onClick={() => handleClick("reg_cert")}
                  >
                    <span className="text-black">
                      {qualificationData.reg_cert &&
                      qualificationData.reg_cert.name
                        ? `Selected File: ${qualificationData.reg_cert.name}`
                        : " Click here to Add your files here "}
                    </span>
                  </p>
                </label>
                <input
                  id="fileInput-reg_cert"
                  name="reg_cert"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "reg_cert")}
                  ref={fileInputRefs.reg_cert}
                />
              </div>
              <h1 className="max-w-[15rem] text-[8px]">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            </div>
          </div>

          <div className="relative w-full">
            {health_worker_detail?.nysc && (
              <div className="absolute top-4 right-0">
                <FaCheckCircle size="20" color={colors.primary} />
              </div>
            )}
            <div className="w-full mt-2">
              <label className="font-semibold max-w-xl text-[10px] text-black">
                <span className="text-[#D60000]">*</span>
                <i> Upload NYSC Certificate</i>
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                <label htmlFor="fileInput-nysc" className="cursor-pointer">
                  <img
                    src={healthWorkerIcons.dashicons}
                    className="pl-[8rem] py-2 items-center"
                    onClick={() => handleClick("nysc")}
                    alt=""
                  />
                  <p
                    className="text-center text-[#47A7FD] text-sm"
                    onClick={() => handleClick("nysc")}
                  >
                    <span className="text-black">
                      {qualificationData.nysc && qualificationData.nysc.name
                        ? `Selected File: ${qualificationData.nysc.name}`
                        : " Click here to Add your files here "}
                    </span>
                  </p>
                </label>
                <input
                  id="fileInput-nysc"
                  name="nysc"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "nysc")}
                  ref={fileInputRefs.nysc}
                />
              </div>
              <h1 className="max-w-[15rem] text-[8px]">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            </div>
          </div>

          <div className="relative w-full">
            {health_worker_detail?.work_permit && (
              <div className="absolute top-4 right-0">
                <FaCheckCircle size="20" color={colors.primary} />
              </div>
            )}
            <div className="w-full mt-2">
              <label className="font-semibold max-w-xl text-[10px] text-black">
                <span className="text-[#D60000]">*</span>
                <i> Upload National ID</i>
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                <label htmlFor="fileInput-nat_id" className="cursor-pointer">
                  <img
                    src={healthWorkerIcons.dashicons}
                    className="pl-[8rem] py-2 items-center"
                    onClick={() => handleClick("nat_id")}
                    alt=""
                  />
                  <p
                    className="text-center text-[#47A7FD] text-sm"
                    onClick={() => handleClick("nat_id")}
                  >
                    <span className="text-black">
                      {qualificationData.nat_id && qualificationData.nat_id.name
                        ? `Selected File: ${qualificationData.nat_id.name}`
                        : " Click here to Add your files here "}
                    </span>
                  </p>
                </label>
                <input
                  id="fileInput-nat_id"
                  name="nat_id"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "nat_id")}
                  ref={fileInputRefs.nat_id}
                />
              </div>
              <h1 className="max-w-[15rem] text-[8px]">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            </div>
          </div>

          <div className="relative w-full">
            {health_worker_detail?.work_id && (
              <div className="absolute top-4 right-0">
                <FaCheckCircle size="20" color={colors.primary} />
              </div>
            )}
            <div className="w-full mt-2">
              <label className="font-semibold max-w-xl text-[10px] text-black">
                <span className="text-[#D60000]">*</span>
                <i> Upload Work ID</i>
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                <label htmlFor="fileInput-work_id" className="cursor-pointer">
                  <img
                    src={healthWorkerIcons.dashicons}
                    className="pl-[8rem] py-2 items-center"
                    onClick={() => handleClick("work_id")}
                    alt=""
                  />
                  <p
                    className="text-center text-[#47A7FD] text-sm"
                    onClick={() => handleClick("work_id")}
                  >
                    <span className="text-black">
                      {qualificationData.work_id &&
                      qualificationData.work_id.name
                        ? `Selected File: ${qualificationData.work_id.name}`
                        : " Click here to Add your files here "}
                    </span>
                  </p>
                </label>
                <input
                  id="fileInput-work_id"
                  name="work_id"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "work_id")}
                  ref={fileInputRefs.work_id}
                />
              </div>
              <h1 className="max-w-[15rem] text-[8px]">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            </div>
          </div>

          <div className="relative w-full">
            {health_worker_detail?.referee && (
              <div className="absolute top-4 right-0">
                <FaCheckCircle size="20" color={colors.primary} />
              </div>
            )}
            <div className="w-full mt-2">
              <label className="font-semibold max-w-xl text-[10px] text-black">
                <span className="text-[#D60000]">*</span>
                <i> Upload Referee Document</i>
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
                <label htmlFor="fileInput-ref" className="cursor-pointer">
                  <img
                    src={healthWorkerIcons.dashicons}
                    className="pl-[8rem] py-2 items-center"
                    onClick={() => handleClick("ref")}
                    alt=""
                  />
                  <p
                    className="text-center text-[#47A7FD] text-sm"
                    onClick={() => handleClick("ref")}
                  >
                    <span className="text-black">
                      {qualificationData.ref && qualificationData.ref.name
                        ? `Selected File: ${qualificationData.ref.name}`
                        : " Click here to Add your files here "}
                    </span>
                  </p>
                </label>
                <input
                  id="fileInput-ref"
                  name="ref"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "ref")}
                  ref={fileInputRefs.ref}
                />
              </div>
              <h1 className="max-w-[15rem] text-[8px]">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex cursor-pointer items-center space-x-2 mr-2">
          <div onClick={handlePrev} className="flex items-center space-x-1">
            <FaArrowAltCircleLeft size={25} color={colors.primary} />
            <p className="font-bold">Back</p>
          </div>
        </div>

        <button
          type="submit"
          onClick={handleUploadDocument}
          className="text-white bg-bgbutton hover:bg-bgbutton focus:ring-4 
            focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
             dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800"
        >
          {isLoading ? (
            <div className="flex justify-center">
              <LoaderIcon size={20} color="white" />
            </div>
          ) : (
            <p>Upload</p>
          )}
        </button>
      </div>
    </div>
  );
};

export default Qualification