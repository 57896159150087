import React, {useState} from 'react'
import 'react-international-phone/style.css';
import Error from '../global/Error';
import { useDispatch, useSelector } from 'react-redux';
import { UserEmergency } from '../../services/redux/actions/formAction';
import { useNavigate, useParams } from 'react-router-dom';
import FormField from '../FormField';


const EmergencyInfo = () => {
  const { sessionId } = useParams();

  const navigate = useNavigate();

  const handleNavigation = (e) => {
    e.preventDefault();
    navigate('/emergency');
  };


  const [formData, setFormData] = useState({
    phone: "",
    sessionId: sessionId,
     
  });

  const { loading, error, emergencyInfo, } = useSelector((state) => state.useremergency); 
  const healthProfile = emergencyInfo?.data?.healthProfile?.result;
  const userProfile = emergencyInfo?.data?.user?.result;
  const emergencyProfile = emergencyInfo?.data?.emergencyContacts?.result[0];
  const insurance = emergencyInfo?.data.insurance?.result[0];
  const medicalHistory = emergencyInfo?.data?.medicalHistory?.result;
  const currentMedicalReminder = emergencyInfo?.data?.currentMedicalReminder?.result;
  const pastMedication = emergencyInfo?.data?.pastMedicalReminder?.result

  console.log('medicalHistory', medicalHistory);
    console.log('currentMedicalReminder', currentMedicalReminder);
      console.log('pastMedication', pastMedication);

  
  const tableItems = [
    {
      label: "View Past Medication",
      title: "Medication",
      items: Array.isArray(pastMedication)
        ? pastMedication.map((medication, idx) => ({
            name:medication.medication,
            content: medication.dosage + '' + medication.drugType,
            info:   medication.additionalInfo,
          }))
        : [],
    },
    {
      label: "View Currrent Medication",
      title: "Medication",
      items: Array.isArray(currentMedicalReminder)
        ? currentMedicalReminder.map((medication, idx) => ({
            name:medication.medication,
            content: medication.drugType + medication.dosage,
             info:  medication.additionalInfo,
          }))
        : [],
    },
    {
      label: "View Medication History",
      title: "Medication",
      items: Array.isArray(medicalHistory)
        ? medicalHistory.map((medication, idx) => ({
            name:medication.name,
            content: medication.content,
            info: medication.updatedAt,
          }))
        : [],
    },
]

const [selectedItem, setSelectedItem] = useState(0)



  
  


  // handle change event
  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));
    }
  };
   
  // handle submit button
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(UserEmergency(formData));
  };


  return (
    <div>
    <header className="text-gray-600 body-font">
    <div className=" mx-auto flex flex-wrap p-8 flex-col md:flex-row items-center">
      <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
        <span className="ml-3 text-xl">
        Emergency Health Information
        </span>
      </a>
      <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center ">
      <button   onClick={() => { window.location.href = '/emergency'; }} className="text-[#47A7FD] hover:text-white border border-[#47A7FD] hover:bg-[#47A7FD]
      focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-2.5 text-center 
      me-2 mb-2 dark:border-[#47A7FD]-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500
       dark:focus:ring-[#47A7FD]">Back </button>
      </nav>
    </div>
  </header>
  <p className='px-9 text-[20px] font-Roboto'>Enter victim’s Phone number to retrieve emergency health information.</p>
  <div className="flex flex-col md:flex-row lg:px-8">
      <div className="md:w-1/2  p-4">
        <form onSubmit={handleSubmit}>
        {error && <Error message={error}>{error}</Error>}
        <div>
        <label className='text-lg  text-black font-normal font-Poppins leading-4 '>Phone Number</label>
              <FormField
               type="phone"
               name="phone"
               placeholder="Enter Victim's Phone Number"
               value={formData.phone}
               onChange={handleChange}
               className="appearance-none block w-[75%] lg:w-[56%] bg-gray-200
               text-gray-700 border border-[#47A7FD] rounded items-center 
               py-4 my-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
              />
            </div> 
            {loading ?
         <button type="submit" className="duration-250 block items-center w-auto lg:w-[57%] bg-[#47A7FD] text-white
         text-center  me-12 mb-2 my-5 py-3 px-5 focus:outline-none
         border hover:-bg-[#47A7FD]  rounded-lg text-base
            " disabled={loading}>  
               
                 <svg aria-hidden="true" className="inline w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                 <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                 </svg> 
           </button> :

            <button type="submit" className="block items-center w-auto lg:w-[57%] bg-[#47A7FD] text-white
            text-center  me-12 mb-2 my-5 py-3 px-5 focus:outline-none
            border hover:-bg-[#47A7FD]  rounded-lg text-base " disabled={loading}>
            View Information
            </button>
             
          }
        </form>
      </div>
      <div className="md:w-1/2  p-4">
        <div className='border border-[#47A7FD] w-auto lg:h-auto h-[70rem]  rounded-lg'>
          <div className='flex  h-screen flex-col'>
            <div className='p-4 lg:flex-grow  '>
                <p className='text-[#47A7FD] font-Poppins font-bold leading-5 py-2'>Personal Details</p>
                <div className="lg:flex md:flex">
                <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Name</h1> <p className='text-[13px] '>{userProfile?.first_name || 'Nill'}</p></div>
                <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Gender</h1><p className='text-[13px] '>{healthProfile?.gender || 'Nill'} </p></div>
                <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Date of Birth</h1><p className='text-[13px] '>{healthProfile?.birthday || 'Nill'} </p></div>
                <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Blood Group</h1><p className='text-[13px] '>{healthProfile?.bloodGroup || 'Nill'} </p></div>
                <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Genotype</h1><p className='text-[13px] '>{healthProfile?.genotype || 'Nill'} </p></div>
                </div>
            </div>
            <div className='p-4 flex-grow'>
            <p className="text-[#47A7FD] font-Poppins font-bold leading-5 py-2">Emergency Contact(s) Information</p>
            <div className="lg:flex md:flex pb-2">
            <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Name</h1><p className='text-[13px] '>{emergencyProfile?.contactName || 'Nill'}  </p></div>
            <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Phone Number</h1><p className='text-[13px] '>{emergencyProfile?.contactPhone || 'Nill'}  </p></div>
            <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Email Address</h1><p className='text-[13px] '>{emergencyProfile?.email || 'Nill'}  </p></div>
            <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Address</h1><p className='text-[13px] '>{emergencyProfile?.contactAddress || 'Nill'}  </p></div>
           </div>
           <div className="lg:flex md:flex">
            <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>state</h1><p className='text-[13px] '>{emergencyProfile?.state || 'Nill'}  </p></div>
            <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Country</h1><p className='text-[13px] '>{emergencyProfile?.country || 'Nill'}  </p></div>
            <div className="flex-1 "><h1 className ='font-Poppins font-black text-sm'>Relationship</h1><p className='text-[13px] '>{emergencyProfile?.relationship || 'Nill'}  </p></div>
           </div>
            
            </div>
            <div className='p-4 flex-grow'>
            <p className="text-[#47A7FD] font-Poppins font-bold leading-5 py-2">Health and Medical Information</p>
            <div className="lg:flex md:flex pb-2">
                <div className="flex-1"><h1 className ='font-Poppins font-black text-sm'>H.M.O Provider</h1><p className='text-[13px] '>{insurance?.provider.provider || 'Nill'}  </p></div>
                <div className="flex-1"><h1 className ='font-Poppins font-black text-sm'>Policy Number</h1><p className='text-[13px] '>{insurance?.policy_number || 'Nill'}  </p></div>
            </div>
            </div>

            <div className="max-w-screen-xl  px-4 md:px-8">
          
            <div className="text-sm mt-4 overflow-x-auto">
                <ul rol="tablist" className="w-full border-b flex items-center gap-x-3 overflow-x-auto">
                    {
                        tableItems.map((item, idx) => (
                            <li key={idx} className={`text-[#47A7FD] font-Poppins font-bold leading-5 py-2 border-b-2 ${selectedItem == idx ? "border-[#47A7FD] text-[#47A7FD]" : "border-white text-gray-500"}`}>
                                <button
                                    role="tab"
                                    aria-selected={selectedItem == idx ? true : false}
                                    aria-controls={`tabpanel-${idx + 1}`}
                                    className="text-[#47A7FD] font-Poppins font-bold leading-5 py-2 text-[0.9rem] px-4 rounded-lg duration-150 hover:text-[#47A7FD] hover:bg-gray-50 active:bg-gray-100 font-medium"
                                    onClick={() => setSelectedItem(idx)}
                                >
                                    {item.label}
                                </button>
                            </li>
                        ))
                    }
                </ul>
                <table className="w-full table-auto text-left">
                    <thead className="text-gray-600 font-medium border-b">
                        <tr>
                            <th className=" py-4 pr-6 font-Poppins font-black text-sm text-black">{tableItems[selectedItem].title}</th>
                            <th className="py-4 pr-6 font-Poppins font-black text-sm text-black">Content</th>
                            <th className="py-4 pr-6 font-Poppins font-black text-sm text-black">Extra Information</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 divide-y">
                        {
                            tableItems[selectedItem].items.slice(0, 5).map((item, idx) => (
                                <tr key={idx}>
                                    <td className="pr-6 py-4 whitespace-nowrap text-black">{item.name}</td>
                                    <td className="pr-6 py-4 whitespace-nowrap text-black">{item.content}</td>
                                    <td className="pr-6 py-4 whitespace-nowrap text-black">
                                        <span className={`py-2 px-3 rounded-full font-semibold text-xs ${[item?.info]?.color || ""}`}>{item.info}</span>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
            
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}


export default EmergencyInfo