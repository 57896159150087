import React, { useEffect, useRef, useState } from "react";
import { Twirl as Hamburger } from "hamburger-react";
import { FormProvider, useForm } from "react-hook-form";
import { FaUser } from "react-icons/fa";
import { MdLogout, MdSwitchAccount } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { images } from "../../constants/icons";
import { uploads } from "../../constants/images";
import { useStateProvider } from "../../manager/StateContext";

import { imageLoader } from "../../utils/helpers";
import { profileDetail } from "../../services/data/NavigationData";
import { userLogout } from "../../services/redux/auth/authAction/authAction";

const CorporateHeader = ({ isOpen, setOpen }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const dispatch = useDispatch();
  const [{ user_data }] = useStateProvider();

  const handleLogoutModalToggle = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      searchValue: " ",
    },
  });

  const {
    formState: { errors },
  } = methods;

  /* Handle submit */
  const onSubmit = async (payload, event) => {
    event?.stopPropagation();
  };

  const [sidebarState, setSidebarState] = useState(false);
  const sidebarRef = useRef(null);

  const navigate = useNavigate();

  const handleProfileClick = () => {
    const userId = user_data?.id;
    navigate(
      `/health-workers/health-worker-edit-profile/${userId}?tab=profile`
    );
  };

  const toggleDropdown = () => {
    setSidebarState(!sidebarState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarState(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    dispatch(userLogout());
    navigate("/");
  };

  return (
    <div className="flex justify-between z-50 sm:w-full px-2 mt-1">
      <div className="xl:hidden">
        <Hamburger
          color="#0b211a"
          size={20}
          label="Show menu"
          toggled={isOpen}
          toggle={setOpen}
        />
      </div>

      <div className="flex sm:flex-1 justify-end items-end">
        <div
          className="flex items-center justify-between space-x-2 cursor-pointer"
          ref={sidebarRef}
          onClick={toggleDropdown}
        >
          <div className="flex w-[40px]">
            <button>
              <img src="" alt="" className="" />
            </button>
          </div>
          <div className="relative flex justify-between   p-[10px]  text-black bg-[#ffffff] rounded-lg ">
            <div className="pr-1">
              <img
                src={imageLoader(user_data?.imageUrl) ?? uploads.avatar}
                alt={user_data?.first_name}
                className="w-6 h-6 rounded-full"
              />
            </div>
            <div className="text-black lg:w-[113px]">
              <p className="font-bold text-[8px]">
                {user_data?.first_name || ""} {user_data?.last_name || ""}
              </p>
              <p className="font-medium text-[8px]">
                {user_data?.account_type || user_data?.email}
              </p>
            </div>
            <div className="w-[10px]">
              <button onClick={toggleDropdown}>
                <img src={profileDetail.logo} alt="" className="w-[100%]" />
              </button>
            </div>

            {sidebarState && (
              <div className="absolute top-12 right-0 z-50 bg-[#f9f4f4] rounded-lg w-[188px] p-[10px]  text-black shadow-md">
                <div>
                  <div className="border-b border-gray-300 flex flex-row items-center py-1 mb-1">
                    <FaUser size="14" color="#333" />
                    <button
                      onClick={handleProfileClick}
                      className="text-xs font-bold ml-2"
                    >
                      My Profile
                    </button>
                  </div>
                  <div className="flex flex-row items-center py-1">
                    <MdLogout size="14" color="red" />
                    <button
                      onClick={() => handleLogoutModalToggle()}
                      className="text-xs font-bold ml-2"
                    >
                      Log out
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showLogoutModal && (
        <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-full max-w-sm p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="mt-3 sm:flex">
              <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                <h4 className="text-lg font-medium text-gray-800">
                  Are you Sure?
                </h4>
                <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                  Do you realize you are Logging Out?
                </p>
                <div className="items-center gap-3 mt-3 sm:flex">
                  <button
                    className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </button>
                  <button
                    className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                    onClick={() => handleLogoutModalToggle()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CorporateHeader;
