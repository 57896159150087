import React from 'react'
import EmergencyInfo from '../../components/corporatedashboard/EmergencyInfo'

function UserEmergency() {
  return (
    <div>
        <EmergencyInfo/>
    </div>
  )
}

export default UserEmergency