import React from "react";

import { images } from "../../constants/icons";
import { reviewData } from "../../services/data/corporateData";
import Circularchart from "../../components/charts/CircularChart";

const Reviews = () => {
  return (
    <div>
      <div className="h-[470px] bg-white rounded-lg p-[20px]  font-Montserrat">
        <div className="flex justify-between mb-2">
          <p className="text-base font-bold">Reviews</p>
          <p>
            <img src={images.verticalIcon} alt="" />
          </p>
        </div>

        <div className="flex justify-center items-center my-7">
          <Circularchart type={"Bookings"} numbers={1000} percentage={45}  pathColor={'#FABC2F'} />
        </div>
        <div>
          {reviewData &&
            reviewData.map((item, i) => (
              <div key={i} className="flex justify-between py-1">
                <div className="w-[20%]">
                  <img src={item.image} alt="" />
                </div>
                <div className="w-[80%] mb-2 ">
                  <p className="text-[12px]  font-semibold">{item.name}</p>
                  <p className="text-[8px] text-[#888888]">{item.mssg}</p>
                </div>
              </div>
            ))}
        </div>
      
      </div>
    </div>
  );
};

export default Reviews;
