import React from 'react'
import Calendar from 'react-calendar'
import { Link, useNavigate } from 'react-router-dom';

import Schedule from '../../components/corporatedashboard/Schedule'
import { images } from '../../constants/icons'

import { normalizeDate } from '../../utils/helpers';

const ScheduleContainer = ({ calendarValue, appointments }) => {
  const isDateInRanges = (date) => {
    const normalizedDate = normalizeDate(date);
    return calendarValue.some(
      ([start, end]) =>
        normalizedDate >= normalizeDate(start) &&
        normalizedDate <= normalizeDate(end)
    );
  };

  const navigate = useNavigate()

  const handleRedirect = () => {
    navigate("/health-worker-appointment");
  }

  return (
    <div className="bg-white rounded-lg p-[20px] w-[318px] h-[350px]">
      <div className="flex justify-between">
        <p className="text-[15px] font-bold">Your Schedule</p>
       
      </div>

      <Calendar
        allowPartialRange={true}
        tileClassName={({ date }) =>
          isDateInRanges(date) ? "highlight" : null
        }
        selectRange={true}
      />

      <div className="flex justify-between text-[10px] mt-[10.5px]">
        <p className="text-textgray">
          {appointments?.length} appointment(s) today
        </p>
        <button className="z-40" onClick={handleRedirect}>View more</button>
      </div>
    </div>
  );
};

export default ScheduleContainer