import React  from 'react';
import SuperadminLogin from '../../components/Admin/adminlogin/SuperadminLogin'



const AdminLogin = () => {
  return(
    <main className="w-full flex">
     <SuperadminLogin/>
    </main>
  )
}

export default AdminLogin