import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Country } from "country-state-city";
import NaijaStates from "naija-state-local-government";
import toast, { LoaderIcon } from 'react-hot-toast';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { TiEdit } from 'react-icons/ti';
import { PhoneInput } from 'react-international-phone';
import { useSelector } from 'react-redux';

import { appointmentDataIcons } from '../../constants/icons';
import { uploads } from '../../constants/images';
import { reducerCases } from '../../manager/constants';
import { useStateProvider } from '../../manager/StateContext';
import FormField from '../FormField';

import { categories, categoriesRank, sendError, setNextTab, ValidateForm } from '../../utils/helpers';
import { colors } from '../../utils/style';

const Profile = ({
  activeTab,
  tab,
  setActiveTab,
  isLoading,
  setIsLoading,
  organizations,
  specialities,
  handlePrev,
}) => {
  const [{ user_data, health_worker_detail, stage_token, token }, dispatch] =
    useStateProvider();
  const initialFormData = JSON.parse(localStorage.getItem("formData")) || {
    first_name: "",
    last_name: "",
    mobile: "",
    city: "",
    address: "",
    country: "Nigeria",
    state: "",
    organisationId: "121",
    gender: "",
    category: "",
    prof_body: "",
    prof_body_number: "",
    prof_body_first: "",
    intro_title: "",
    intro_body: "",
    specialty: "",
    doc_Specialty: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const [states, setStates] = useState(NaijaStates.states());
  const [cities, setCities] = useState();
  const [countries, setCountries] = useState(Country.getAllCountries());

  const [imageUrl, setImageUrl] = useState(uploads.avatar);
  const [ranks, setRanks] = useState([]);
  const [hasUpdatedImage, setHasUpdatedImage] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setImageUrl(base64String);
        uploadImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditClick = () => {
    document.getElementById("fileInput").click();
  };

  const uploadImage = async (base64String) => {
    const formData = new FormData();
    formData.append("image", base64String);

    try {
      const url = `${process.env.REACT_APP_API_LIVE_URL}/api/account/upload-profile-pic`;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${stage_token}`,
          },
        })
        .then((response) => {
          const { data } = response;
          setHasUpdatedImage(true);
          toast.success(data.status.desc);
        })
        .catch((error) => {
          const message = sendError(error);
          console.log(message);
          toast.error("There was an error updating profile image");
        });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;

      if (name === "category") {
        const categoryRanks = categoriesRank.find(
          (category) => category.name === value
        );
        setRanks(categoryRanks ? categoryRanks.ranks : []);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!hasUpdatedImage) {
      setIsLoading(false);
      toast.error("Please update your profile image to submit");
    } else {
        try {
          const keys = [
            "first_name",
            "last_name",
            "mobile",
            "city",
            "address",
            "country",
            "state",
            "organisationId",
            "gender",
            "category",
            "prof_body",
            "prof_body_number",
            "prof_body_first",
            "intro_title",
            "intro_body",
            "doc_Specialty",
          ];
      
          const allow = ValidateForm(formData, keys);

          if (allow.status) {
            const config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            const url = `${process.env.REACT_APP_API_URL}/api/v1/cooperate/health-worker/update-health-worker-information/${user_data?.id}`;
            axios
              .put(url, { ...formData }, config)
              .then((response) => {
                setIsLoading(false);
                const { data } = response;
                toast.success(data.message);

                  const husers = data.data.result.user;
                  console.log(husers);
                  dispatch({
                    type: reducerCases.SET_USER_DATA,
                    user_data: husers,
                  });
                  
                  const hdetails = data.data.result.details;
                  console.log(hdetails);
                  dispatch({
                    type: reducerCases.SET_HEALTHWORKER_INFO,
                    health_worker_detail: hdetails,
                  });
                setNextTab(activeTab, tab, setActiveTab);
              })
              .catch((error) => {
                setIsLoading(false);
                const message = sendError(error);
                console.log(message);
                toast.error(
                  "There was an error updating your information! Please try again."
                );
              });
          } else {
            setIsLoading(false);
            toast.error(allow.message);
          }
        } catch (error) {
          setIsLoading(false);
          toast.error(
            "There was an error updating your information! Please try again."
          );
        }
      }
  };

  useEffect(() => {
    if (formData.state) {
      setCities(NaijaStates.lgas(formData.state)?.lgas);
    }
  }, [formData, formData.state]);

  useEffect(() => {
    if (
      user_data?.imageUrl
    ) {
      setHasUpdatedImage(true);
    }
  }, [user_data]);

  return (
    <div className="mx-5">
      <div className="mb-3">
        <h5 className="font-bold text-sm">Profile Information</h5>
        <p className="text-xs">Edit your profile information</p>
      </div>

      <div className="flex flex-row justify-center my-3">
        <div className="relative border-2 border-bgbutton rounded-full">
          <img
            src={imageUrl}
            alt={user_data?.first_name}
            className="rounded-full h-[80px] sm:w-[80px]"
            onClick={handleEditClick}
          />

          <button
            onClick={handleEditClick}
            className="absolute bottom-0 right-0 z-5 bg-[#47A7FD] rounded-full p-1"
          >
            <TiEdit size={18} color="#000" />
          </button>

          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 place-items-center w-full  sm:gap-x-4">
        <div className="w-full">
          <label className="font-medium text-[10px]">First name</label>
          <FormField
            type="text"
            name="first_name"
            placeholder="Joe"
            value={formData.first_name}
            onChange={(e) => handleChange(e)}
            required
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
          />
        </div>
        <div className="w-full">
          <label className="font-medium text-[10px]">Last name</label>
          <FormField
            placeholder="Doe"
            name="last_name"
            value={formData.last_name}
            onChange={(e) => handleChange(e)}
            type="text"
            required
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
          />
        </div>
        <div className="w-full">
          <label className="font-medium text-[10px] max-w-xl ">
            Phone number
          </label>
          <PhoneInput
            defaultCountry="ng"
            type="phone"
            name="mobile"
            value={formData.mobile}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                ["mobile"]: e,
              }))
            }
            containerStyle={{
              border: "0.5rem ",
              width: "100%",
            }}
            inputStyle={{
              background: "#F3F3F3",
              width: "100%",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              marginBottom: "1rem",
              border: "0.5rem",
            }}
          />
        </div>
        <div className="w-full relative">
          <label className="font-medium text-[10px] max-w-xl">Gender</label>
          <select
            name="gender"
            value={formData.gender}
            onChange={(e) => handleChange(e)}
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
          >
            <option value="" disabled>
              Select Gender
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
            <img
              src={appointmentDataIcons.dropdownIcon}
              className="w-3 h-3"
              alt=""
            />
          </div>
        </div>

        <div className="w-full relative">
          <label className="font-medium text-[10px] max-w-xl">State</label>
          <select
            name="state"
            value={formData.state}
            onChange={(e) => handleChange(e)}
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
          >
            <option value="" disabled>
              Select State
            </option>
            {states.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
          <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
            <img
              src={appointmentDataIcons.dropdownIcon}
              className="w-3 h-3"
              alt=""
            />
          </div>
        </div>

        <div className="w-full relative">
          <label className="font-medium text-[10px] max-w-xl">City</label>
          <select
            name="city"
            value={formData.city}
            onChange={(e) => handleChange(e)}
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
          >
            <option value="" disabled>
              Select City
            </option>
            {cities?.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
          <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
            <img
              src={appointmentDataIcons.dropdownIcon}
              className="w-3 h-3"
              alt=""
            />
          </div>
        </div>

        <div className="w-full relative">
          <label className="font-medium text-[10px] max-w-xl">Country</label>
          <select
            name="country"
            value={formData.country}
            onChange={(e) => handleChange(e)}
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
          >
            <option value="" disabled>
              Select Country
            </option>
            {countries.map((country, index) => (
              <option key={index} value={country?.name}>
                {country?.name}
              </option>
            ))}
          </select>
          <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
            <img
              src={appointmentDataIcons.dropdownIcon}
              className="w-3 h-3"
              alt=""
            />
          </div>
        </div>

        <div className="w-full">
          <label className="font-medium text-[10px] max-w-xl">Address</label>
          <FormField
            type="address"
            name="address"
            value={formData.address}
            onChange={(e) => handleChange(e)}
            placeholder="No. 5 Toyin Street"
            required
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
          />
        </div>

        <div className="w-full">
          <div className="relative">
            <label className="font-medium text-[10px] max-w-xl">
              Organisation
            </label>
            <select
              name="organisationId"
              className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
              value={formData.organisationId}
              onChange={(e) => handleChange(e)}
            >
              <option value="121">MyMedicalBank Healthcare</option>
              {organizations?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.organisationName}
                </option>
              ))}
            </select>
            <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
              <img
                src={appointmentDataIcons.dropdownIcon}
                className="w-3 h-3"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="w-full relative">
          <label className="font-medium text-[10px] max-w-xl">
            Health Worker Category
          </label>
          <select
            name="category"
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
            value={formData.category}
            onChange={(e) => handleChange(e)}
          >
            <option value="">Select category</option>
            {categories?.map((item, index) => (
              <option key={index} value={item?.value}>
                {item?.name}
              </option>
            ))}
          </select>
          <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
            <img
              src={appointmentDataIcons.dropdownIcon}
              className="w-3 h-3"
              alt=""
            />
          </div>
        </div>

        <div className="w-full relative">
          <label className="font-medium text-[10px] max-w-xl">
            Approximate Rank
          </label>
          <select
            name="specialty"
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
            value={formData.specialty}
            onChange={(e) => handleChange(e)}
          >
            <option value="">Select</option>
            {ranks?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
          <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
            <img
              src={appointmentDataIcons.dropdownIcon}
              className="w-3 h-3"
              alt=""
            />
          </div>
        </div>

        <div className="w-full relative">
          <label className="font-medium text-[10px] max-w-xl">
            Doctor's Specialty
          </label>
          <select
            value={formData.doc_Specialty}
            id="doc_Specialty"
            name="doc_Specialty"
            className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
            onChange={(e) => handleChange(e)}
          >
            <option value="">Select</option>
            {specialities?.map((item) => (
              <option key={item.value} value={item.specialties}>
                {item.specialties}
              </option>
            ))}
          </select>
          <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
            <img
              src={appointmentDataIcons.dropdownIcon}
              className="w-3 h-3"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="mt-2">
        <h5 className="font-bold text-[12px]">Profession Details</h5>

        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2">
          <div className="w-full">
            <label className="font-medium text-[10px] max-w-xl">
              Professional Title
            </label>
            <FormField
              value={formData.prof_body}
              onChange={(e) => handleChange(e)}
              type="text"
              name="prof_body"
              placeholder=""
              required
              className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
            />
          </div>

          <div className="w-full">
            <label className="font-medium text-[10px] max-w-xl">
              Professional Registration Number
            </label>
            <FormField
              value={formData.prof_body_number}
              onChange={(e) => handleChange(e)}
              type="text"
              name="prof_body_number"
              placeholder=""
              required
              className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
            />
          </div>

          <div className="w-full flex flex-col items-center gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
            <div>
              <label className="font-medium text-[10px]">
                Professional Date First Registered
              </label>
              <FormField
                value={formData.prof_body_first}
                onChange={(e) => handleChange(e)}
                type="date"
                name="prof_body_first"
                placeholder="Joe"
                required
                className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <h5 className="font-bold text-[12px]">Bio</h5>

        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2">
          <div className="w-full">
            <label className="font-medium text-[10px] max-w-xl">
              Introduction Title
            </label>
            <FormField
              value={formData.intro_title}
              onChange={(e) => handleChange(e)}
              type="text"
              name="intro_title"
              placeholder=""
              required
              className="w-full mb-4 mt bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
            />
          </div>

          <div className="w-full">
            <label className="font-medium text-[10px] max-w-xl">
              Introduction
            </label>
            <textarea
              value={formData.intro_body}
              onChange={(e) => handleChange(e)}
              type="text"
              name="intro_body"
              placeholder=""
              required
              rows="1"
              className="w-full mb-4 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
            ></textarea>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-row justify-between items-center">
        {activeTab !== "profile" && (
          <div className="flex sm:hidden cursor-pointer items-center space-x-2 mr-2">
            <div onClick={handlePrev} className="flex items-center space-x-1">
              <FaArrowAltCircleLeft size={25} color={colors.primary} />
              <p className="font-bold">Back</p>
            </div>
          </div>
        )}

        <div className="flex items-end justify-end w-full">
          <button
            type="submit"
            onClick={handleSubmit}
            className="text-white w-[20rem] bg-bgbutton hover:bg-bgbutton focus:ring-4 
            focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
             dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800"
          >
            {isLoading ? (
              <div className="flex justify-center">
                <LoaderIcon size={20} color="white" />
              </div>
            ) : (
              <p>Update</p>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile