import React from "react";
import { images } from "../../../constants/icons";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import { TiArrowUnsorted } from "react-icons/ti";
import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";

const UsersTable = ({
  userData = [{}],
  adminHeader = [{}],
  userHeader = [{}],
  superAdmindata,
}) => {
  let e = useLocation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Check if superAdmindata is defined and contains the necessary properties
  const rows =
    superAdmindata?.data?.users?.filter((value) => value.email !== "") || [];

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - rows.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CustomTablePagination = styled(TablePagination)`
    // ... your styling here ...
  `;

  return (
    <>
      <div className="bg-white w-[100%] mt-5 py-[30px] px-[20px] rounded-lg max-[650px]:snap-x max-[650px]:scrollbar">
        <div className="flex justify-between">
          <p className="text-lg font-semibold">User Login</p>
          <p>
            <img src={images.verticalIcon} alt="" />
          </p>
        </div>

        <table className="items-center" aria-label="custom pagination table">
          <thead className="">
            <tr className="whitespace-nowrap">
              {e.pathname === "/super-admin-dashboard" ||
              e.pathname === "/services/phr"
                ? adminHeader.map((item, i) => (
                    <td
                      key={i}
                      className="text-[13px] w-[25%] font-medium whitespace-nowrap"
                    >
                      {item.title}
                    </td>
                  ))
                : userHeader.map((item, i) => (
                    <td
                      key={i}
                      className="text-[13px] w-[25%] font-medium whitespace-nowrap"
                    >
                      {item.title} <TiArrowUnsorted />
                    </td>
                  ))}
            </tr>
          </thead>

          <tbody>
            {e.pathname === "/super-admin-dashboard" ||
            e.pathname === "/services/phr"
              ? rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <tr
                      key={item.id} // Provide a unique key for each row
                      className="whitespace-nowrap font-medium text-[10px]"
                    >
                      <td className="w-[25%] py-2">{item.first_name}</td>
                      <td>{item.email}</td>
                      <td>{moment(item.createdAt).format("DD, MMMM yy")}</td>
                      <td>{item.referral}</td>
                      <td></td>
                    </tr>
                  ))
              : userData.map((item, index) => (
                  <tr key={index} className="whitespace-nowrap font-medium text-[10px]">
                    <td className="w-[25%] py-2">{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.date}</td>
                    <td>
                      {item.status === "Active" || item.status === "Paid" ? (
                        <span className="text-[10px] text-[#4AF06F]">
                          {item.status}
                        </span>
                      ) : (
                        <span className="text-[10px] text-[#FABC2F]">
                          {item.status}
                        </span>
                      )}
                    </td>
                    <td className="pl-6">
                      <img src={item.icon} alt="" />
                    </td>
                  </tr>
                ))}
            {emptyRows > 0 && (
              <tr style={{ height: 41 * emptyRows }}>
                <td colSpan={5} aria-hidden />
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <CustomTablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={5}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    "aria-label": "rows per page",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default UsersTable;
