import React, { useEffect, useState } from "react";
import axios from "axios";
import { LoaderIcon } from "react-hot-toast";
import { useSelector } from "react-redux";
import useSWR from "swr";

import AppointmentTable from "../../components/corporatedashboard/AppointmentTable";
import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
import HealthWorkerDashboardSidebar from "../../components/global/HealthWorkerSidebar";
import LoadingModal from "../../components/Modal/LoadingModal";
import { useStateProvider } from "../../manager/StateContext";
import { useAuthManager } from "../../manager/useAuthManager";

import { bookingDate, sendError } from "../../utils/helpers";

// import { useAppointmentContext } from "../../components/corporatedashboard/AppointmentProvider";
const HealthWorkerAppointment = () => {
  const {} = useAuthManager({
    middleware: "auth",
    role: "carer",
    redirectIfAuthenticated: "/health-worker-dashboard",
  });

  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [appointments, setAppointments] = useState([]);
  const [{ stage_token }] = useStateProvider();
  // console.log(stage_token);

  // const { appointments, fetchAppointments } = useAppointmentContext();

  // const fetchAppointments = () => {
  //   setIsLoading(true);
  //   try {
  //     axios
  //       .get(`${process.env.REACT_APP_API_LIVE_URL}/dashboard/carer-booking`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${stage_token}`,
  //         },
  //       })
  //       .then((response) => {
  //         setIsLoading(false);

  //         const { data } = response;
  //         setAppointments(data);
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);

  //         const message = sendError(error);
  //         console.log(message);
  //       });
  //   } catch (error) {
  //     setIsLoading(false);

  //     const message = sendError(error);
  //     console.log(message);
  //   }
  // };

  const fetchAppointments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_LIVE_URL}/dashboard/carer-booking`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${stage_token}`,
          },
        }
      );
      return response.data; // Return the data directly
    } catch (error) {
      const message = sendError(error);
      console.log(message);
      throw error; // Ensure the error is propagated
    }
  };

  const { data: dataBooking, error: errorBooking } = useSWR(
    `${process.env.REACT_APP_API_LIVE_URL}/dashboard/carer-booking`,
    fetchAppointments
  );

  useEffect(() => {
    if (dataBooking) {
      setAppointments(dataBooking); // Set the appointments only when data is fetched
      setIsLoading(false);
    } else if (errorBooking) {
      setIsLoading(false);
    }
  }, [dataBooking, errorBooking]); // Depend on the SWR data

  // const { data: dataBooking, error: errorBooking } = useSWR(
  //   `${process.env.REACT_APP_API_LIVE_URL}/dashboard/carer-booking`,
  //   fetchAppointments
  // );

  const checkPopups = () => {
    let newWindow;
    try {
      newWindow = window.open("", "", "width=100,height=100");
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        throw new Error("Pop-ups blocked");
      }
      newWindow.close();
    } catch (e) {
      window.alert(
        "Please enable pop-ups and redirects in your browser settings to continue."
      );
    }
  };

  useEffect(() => {
    checkPopups();
  }, []); // Only run once when the component mounts

  // useEffect(() => {
  //   checkPopups();
  //   fetchAppointments();
  // }, []);

  if (isLoading) {
    return (
      <LoadingModal
        open={isLoading}
        setOpen={setIsLoading}
        title="We're fetching you booking"
      />
    );
  }

  return (
    <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat">
      <HealthWorkerDashboardSidebar isOpen={isOpen} setOpen={setOpen} />

      <div className="sm:w-screen space-x-2 h-screen overflow-y-auto custom-scrollbar">
        <CorporateHeader isOpen={isOpen} setOpen={setOpen} />

        <div className="h-screen overflow-y-auto custom-scrollbar">
          <div className="flex justify-between w-[100%]">
            <div className="mr-[15px] overflow-x-auto custom-scrollbar">
              <AppointmentTable data={appointments} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthWorkerAppointment;
