import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { images } from "../../../constants/icons";
import { Twirl as Hamburger } from "hamburger-react";
import { profileDetail } from "../../../services/data/NavigationData";



const AdminHeader = ({ isOpen, setOpen }) => {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      searchValue: " ",
    },
  });

  const {
    formState: { errors },
  } = methods;

  /* Handle submit */
  const onSubmit = async (payload, event) => {
    event?.stopPropagation();
  };



  return (
    <header className=" xl:flex  xl:justify-between w-[100%] xl:pb-[41.5px]">
      <div className="hidden lg:block ">
      <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
           
          >
            <div className="flex border-solid rounded-lg border-[#FFFFFF] border-2 bg-white  p-2 items-center ">
              <input
                name="search"
                type="search"
                onError={errors}
                placeholder="Search for health worker, client, team "
                className="pl-[10px] bg-transparent pr-[10px] py-[5px] !w-[288px] font-Poppins text-[#DCDCDC] text-[10px] rounded-lg placeholder-primary::placeholder hover:bg-white  cursor-pointer appearance-none focus:outline-none "
              />

              <div className="w-[18px]">
                <img src={images.searchIcon} alt="" className="w-[100%]" />
              </div>
            </div>
          </form>
        </FormProvider>
      
      </div>
      <div className="hidden xl:flex  justify-between ">
        <div className="flex  mr-[12px] w-[40px]">
          <button onClick={() => alert("nofified")}>
            <img src={images.nofityIcon} alt="" />
          </button>
        </div>
        <div className="flex justify-between  w-[188px] p-[10px]  text-black bg-[#ffffff] rounded-lg ">
          <div className="w-[35px] pr-2">
            <img src={profileDetail.image} alt="" className="w-[100%]" />
          </div>
          <div className="text-black lg:w-[113px]">
            <p className="font-bold text-[8px]">name</p>
            <p className="font-medium text-[8px]">super admin</p>
          </div>
          <div className="w-[10px]">
            <button onClick={() => alert("loading...")}>
              {" "}
              <img src={profileDetail.logo} alt="" className="w-[100%]" />
            </button>
          </div>
        </div>

        {isOpen && (
        <div className=" bg-[#ffffff] rounded-lg w-[188px] p-[10px]  text-black">
          <div className="flex justify-between    ">
            <div className="w-[35px] pr-2">
              <img src={profileDetail.image} alt="" className="w-[100%]" />
            </div>
            <div className="text-black lg:w-[113px]">
              <p className="font-bold text-[8px]">name</p>
              <p className="font-medium text-[8px]">Super Admin</p>
            </div>
            <div className="w-[10px]">
              <button onClick={() => alert("loading...")}>
                {" "}
                <img src={profileDetail.logo} alt="" className="w-[100%]" />
              </button>
            </div>
          </div>
          <div>
            <p>My Profile</p>
            <p>Switch Account</p>
            <p>Log out</p>
          </div>
        </div>
      )}
      </div>

    

      <div className="xl:hidden fixed left-0">
        <Hamburger
          color="#0b211a"
          size={20}
          label="Show menu"
          toggled={isOpen}
          toggle={setOpen}
        />
      </div>
    </header>
  );
};

export default AdminHeader;
