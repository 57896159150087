import React, { useEffect } from "react";

const PreventReload = () => {
  useEffect(() => {
    const preventReload = (event) => {
      if (
        event.key === "F5" ||
        (event.ctrlKey && event.key === "r") ||
        (event.metaKey && event.key === "r")
      ) {
        event.preventDefault();
        event.returnValue = ""; // for older browsers
        return false;
      }
    };

    const preventContextMenuReload = (event) => {
      if (event.target.nodeName === "BODY" && event.button === 2) {
        event.preventDefault();
        return false;
      }
    };

    window.addEventListener("keydown", preventReload);
    window.addEventListener("contextmenu", preventContextMenuReload);

    return () => {
      window.removeEventListener("keydown", preventReload);
      window.removeEventListener("contextmenu", preventContextMenuReload);
    };
  }, []);

  return null;
};

export default PreventReload;
