import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuthManager } from "../../../manager/useAuthManager";

const ProfileSuccess = () => {
  const {} = useAuthManager({
    middleware: "auth",
    role: "carer",
    redirectIfAuthenticated: "/",
  });

  const navigate = useNavigate();

  const handleClickButton = (id) => {
    navigate("/health-worker-dashboard", { replace: true });
  };

  return (
    <div className="">
      <div className="h-full flex flex-col items-center justify-center w-full">
        <div className="p-5 bg-[#FFFBDA] mb-3 rounded-lg mt-10">
          <h5 className="font-bold text-green-600 text-center text-4xl py-2">
            Setup completed Successfully!
          </h5>
          <p className="text-[1rem] text-center">
            Verification usually take 24 to 48 hours
          </p>
        </div>

        <div className="px-14 mt-2">
          <button onClick={handleClickButton} className="button">
            Continue to dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSuccess;
