import { sideIcons } from "../../constants/icons";
import { userSidebarImage } from "../../constants/images";

export const navigations = [
  {
    sectionTitle: "Main Menu",
    links: [
      {
        label: "Dashboard",
        icon: sideIcons.dashboardIcon,
        to: "/super-admin-dashboard",
      },
      {
        label: "Organisation",
        icon: sideIcons.briefCaseIcon,
        to: "/all-organisation",
      },
      {
        label: "Health Workers",
        icon: sideIcons.briefCaseIcon,
        to: "/all-HealthWorkers",
      },
      {
        label: "Clients",
        icon: sideIcons.usersIcon,
        to: "/",
      },
      {
        label: "Services",
        icon: sideIcons.hardDriveIcon,
        to: "/",
      },
      {
        label: "Broadcast Message",
        icon: sideIcons.sendIcon,
        to: "/broadcast",
      },
      {
        label: "Users",
        icon: sideIcons.usersIcon,
        to: "/",
      },
      {
        label: "Transactions",
        icon: sideIcons.creditCardIcon,
        to: "/",
      },

      {
        label: "Settings",
        icon: sideIcons.settingIcon,
        to: "/",
      },
      {
        label: "All users",
        icon: sideIcons.usersIcon,
        to: "/all-users",
      },
    ],
  },
];

export const profileDetail = {
  image: sideIcons.profile,
  name: "Aderopo Oluwasegun",
  title: "Super Admin",
  logo: sideIcons.dropdown,
};

//Corporate sidebar links

export const corporateLinks = [
  {
    label: "Dashboard",
    icon: sideIcons.dashboardIcon,
    to: "/corporate-admin-dashboard",
  },
  {
    label: "Emergency Information",
    icon: sideIcons.settingIcon,
    to: "/corporate-admin-dashboard/emergency",
  },
  {
    label: "Appointments",
    icon: sideIcons.briefCaseIcon,
    to: "/corporate-admin-dashboard",
  },
  {
    label: "Services",
    icon: sideIcons.hardDriveIcon,
    to: "/corporate-admin-dashboard",
  },
  {
    label: "Messages",
    icon: sideIcons.sendIcon,
    to: "/corporate-admin-dashboard",
  },
  {
    label: "Transactions",
    icon: sideIcons.creditCardIcon,
    to: "/corporate-admin-dashboard",
  },
  {
    label: "Help Center",
    icon: sideIcons.helpcenter,
    to: "/corporate-admin-dashboard",
  },
  {
    label: "Settings",
    icon: sideIcons.settingIcon,
    to: "/corporate-admin-dashboard",
  },
];

//Health worker Side links

export const healthWorkerLinks = [
  {
    label: "Dashboard",
    icon: sideIcons.dashboardIcon,
    to: "/health-worker-dashboard",
  },
  {
    label: "Appointments",
    icon: sideIcons.briefCaseIcon,
    to: "/health-worker-appointment",
  },
  // {
  //   label: "Services",
  //   icon: sideIcons.hardDriveIcon,
  //   to: "javascript:void(0)",
  // },
  // {
  //   label: "Messages",
  //   icon: sideIcons.sendIcon,
  //   to: "javascript:void(0)",
  // },
  // {
  //   label: "Transactions",
  //   icon: sideIcons.creditCardIcon,
  //   to: "javascript:void(0)",
  // },
  // {
  //   label: "Help Center",
  //   icon: sideIcons.helpcenter,
  //   to: "javascript:void(0)",
  // },
  // {
  //   label: "Settings",
  //   icon: sideIcons.settingIcon,
  //   to: "javascript:void(0)",
  // },
];

export const referers = {
  title: "Refer a friend",
  image: userSidebarImage.referrerImg,
};
