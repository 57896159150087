import axios from "axios";
import toast from "react-hot-toast";

import { createAsyncThunk } from "@reduxjs/toolkit";

//change the base Url on Production
const BASE_URL = `${process.env.REACT_APP_API_URL}`;

export const registerUser = createAsyncThunk(
  "/auth/register",
  async ({ email, password, account_type }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${BASE_URL}/api/v1/cooperate/user/register`,
        { email, password, account_type },
        config
      );

      toast.success(response.data.message + ". Please login with your credentials to continue to application.");
    } catch (error) {
      //return backend error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//handle login
export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_LIVE2_URL}/api/v1/cooperate/user/login`,
        { email, password },
        config
      );

      toast.success("Login successful. You are being redirected.");

      // store user's token in local storage
      localStorage.setItem("accessToken", data.data.accessToken);

      //   console.log('User Info:', data);
      const userData = data.data;
      return userData;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// VerifyEmail action
export const ResendVerificationCode = createAsyncThunk(
  "auth/verifyEmail",
  async ({ email, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const url = `${BASE_URL}/api/v1/user/verify-email/${token}`;

      const response = await axios.post(
        url,
        { email }, // Pass only email in the request body
        config
      );

      console.log(response.data);

      return response.data;
    } catch (error) {
      // Return backend error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Resend Verification email code
export const VerifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async ({ email, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const url = `${BASE_URL}/api/v1/user/verify-email/${token}`;

      const response = await axios.post(
        url,
        { email }, // Pass only email in the request body
        config
      );

      console.log(response.data);

      return response.data;
    } catch (error) {
      // Return backend error message
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Action to handle user logout
export const userLogout = createAsyncThunk(
  "/auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      // Optionally, you can make an API call to log the user out on the server-side
      // Clear user data from local storage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("token");
      window.location = '/';

      // Return a success message or any necessary data
      return "Logout successful";
    } catch (error) {
      // Return custom error message if any
      return rejectWithValue(error.message);
    }
  }
);
