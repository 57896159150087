import mmblogo2 from "../global/assets/512x512.png";
import alexlieImg from "../global/assets/alexleslie.svg";
import ambulance from "../global/assets/ambulance-ehi.jpg";
import avatar from "../global/assets/avatar.jpg";
import backgroundImg from "../global/assets/background.png";
import box from "../global/assets/box.png";
import cameronImg from "../global/assets/cameron.svg";
import clientBookingImage from "../global/assets/ClientBookingImg.svg";
import healthCareImg from "../global/assets/corporatehealthcare.svg";
import memberImg from "../global/assets/corporatemember.svg";
import sponsorImg from "../global/assets/corporateSponsor.svg";
import Emergency from "../global/assets/emergency.jpg";
import estherImage from "../global/assets/estherImage.svg";
import flyer from "../global/assets/flyer.png";
import greenLine from "../global/assets/greenGraphL.svg";
import guyImg from "../global/assets/guy.svg";
import hawkingsGImg from "../global/assets/hawkinsg.svg";
import phrhelpcenterImg from "../global/assets/healthworkerImg.svg";
import phrhomecareImg from "../global/assets/homecareImg.svg";
import phrImage from "../global/assets/hwimage.png";
import informationIcon from "../global/assets/information.svg";
import janeImg from "../global/assets/jane.svg";
import jonesImg from "../global/assets/jones.svg";
import leslisImg from "../global/assets/leslie.svg";
import licence from "../global/assets/licence.png";
import mmbLogo from "../global/assets/logo.svg";
import penaImg from "../global/assets/pena.svg";
import phrImg from "../global/assets/phrImg.svg";
import qualification from "../global/assets/qualification.jpg";
import redLine from "../global/assets/redGraphL.svg";
import referrerImg from "../global/assets/referrerImg.svg";
import registration from "../global/assets/registration.png";
import saheedImg from "../global/assets/saheed.svg";
import Lifesavers from "../global/assets/setupBackground.png";
import wadeImg from "../global/assets/wade.svg";

export const logo = { mmbLogo, mmblogo2 };

export const emergency = { Emergency, ambulance };

export const withdrawalsImages = {
  guyImg,
  leslisImg,
  janeImg,
  penaImg,
  wadeImg,
};

export const msgImages = {
  alexlieImg,
  cameronImg,
  saheedImg,
  jonesImg,
  hawkingsGImg,
};

export const incomeImg = { redLine, greenLine, informationIcon };

export const corporatePageImages = { healthCareImg, memberImg, sponsorImg };

export const userSidebarImage = { referrerImg };

export const healthWorkerImages = { estherImage };

export const clientsImages = { clientBookingImage };

export const LoginImage = { flyer };

export const phrImages = { phrhelpcenterImg, phrImg, phrhomecareImg, phrImage };

export const doctor = { Lifesavers };

export const uploads = {
  qualification,
  licence,
  registration,
  avatar,
  box,
  backgroundImg,
};
