import React, { useCallback, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { reducerCases } from "./constants";
import { useStateProvider } from "./StateContext";

import { sendError } from "../utils/helpers";

export const useAuthManager = ({
  middleware,
  role,
  redirectIfAuthenticated,
} = {}) => {
  const [{ user_data, stage_token, token }, dispatch] = useStateProvider();
  const { accessToken } = useSelector((state) => state.auth);
  const navigate = useNavigate();

   const fetchDrugs = useCallback(async () => {
     try {
       const config = {
         headers: {
           "Content-Type": "application/json",
           Authorization: `Bearer ${stage_token}`,
         },
       };

       axios
         .get(`${process.env.REACT_APP_API_LIVE_URL}/medication`, config)
         .then((response) => {
           const { data } = response;
           dispatch({
             type: reducerCases.SET_DRUGS,
             drugs: data?.status?.desc?.drugs,
           });
         })
         .catch((error) => {
           const message = sendError(error);
           console.log(message);
         });
     } catch (error) {
       const message = sendError(error);
       console.log(message);
     }
   }, [stage_token, dispatch]);

   const fetchTests = useCallback(async () => {
     try {
       const config = {
         headers: {
           "Content-Type": "application/json",
           Authorization: `Bearer ${stage_token}`,
         },
       };

       axios
         .get(`${process.env.REACT_APP_API_LIVE_URL}/medical-test`, config)
         .then((response) => {
           const { data } = response;
           dispatch({
             type: reducerCases.SET_TESTS,
             tests: data?.status?.desc?.medical_test,
           });
         })
         .catch((error) => {
           const message = sendError(error);
           console.log(message);
         });
     } catch (error) {
       const message = sendError(error);
       console.log(message);
     }
   }, [stage_token, dispatch]);

  const fetchWorkerDetails = useCallback(
    async (id) => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .get(
            `${process.env.REACT_APP_API_LIVE_URL}/all-doctors/${id}`,
            config
          )
          .then((response) => {
            const { data } = response;
            if (data) {
              dispatch({
                type: reducerCases.SET_HEALTHWORKER_INFO,
                health_worker_detail: data,
              });
            }
            return data;
          })
          .catch((error) => {
            const message = sendError(error);
            console.log(message);
          });
      } catch (error) {
        const message = sendError(error);
        console.log(message);
      }
    },
    [token, dispatch]
  );

  const fetchAvailabilities = useCallback(
    async (id) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/cooperate/availablility/get/${id}`,
            config
          )
          .then((response) => {
            const { data } = response;
            dispatch({
              type: reducerCases.SET_USER_AVAILABILITY,
              user_availability: data.data,
            });
          })
          .catch((error) => {
            const message = sendError(error);
            console.log(message);
          });
      } catch (error) {
        const message = sendError(error);
        console.log(message);
      }
    },
    [token, dispatch]
  );

  const fetchSpecialAvailabilities = useCallback(
    async (id) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/cooperate/availablility/special/${id}`,
            config
          )
          .then((response) => {
            const { data } = response;
            dispatch({
              type: reducerCases.SET_USER_SPECIAL_AVAILABILITY,
              user_special_availability: data.data,
            });
          })
          .catch((error) => {
            const message = sendError(error);
            console.log(message);
          });
      } catch (error) {
        const message = sendError(error);
        console.log(message);
      }
    },
    [token, dispatch]
  );

  const fetchUserInfo = () => {
    if (token) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/v1/user/authenticated`,
            config
          )
          .then((response) => {
            const { data } = response;
            dispatch({
              type: reducerCases.SET_USER_DATA,
              user_data: data.data,
            });
            return data.data;
          })
          .catch((error) => {
            const message = sendError(error);
            console.log(message);
          });
      } catch (error) {
        const message = sendError(error);
        console.log(message);
      }
    }
  };

  const fetchAppointments = useCallback( async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_LIVE_URL}/dashboard/carer-booking`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${stage_token}`,
            },
          }
        )
        .then((response) => {
          const { data } = response;
          dispatch({
            type: reducerCases.SET_USER_APPOINTMENTS,
            user_appointments: data,
          });
        })
        .catch((error) => {
          const message = sendError(error);
          console.log(message);
        });
    } catch (error) {
      const message = sendError(error);
      console.log(message);
    }
  }, [dispatch, stage_token]);

  const { data: userData} = useSWR(
    `${process.env.REACT_APP_API_URL}/api/v1/user/authenticated`,
    fetchUserInfo
  );

  const { data: drugData } = useSWR(
    `${process.env.REACT_APP_API_LIVE_URL}/medication`,
    fetchDrugs
  );

  const { data: testData } = useSWR(
    `${process.env.REACT_APP_API_LIVE_URL}/medical-test`,
    fetchTests
  );

  useEffect(() => {
    fetchWorkerDetails(user_data?.id);
    fetchAvailabilities(user_data?.id);
    fetchSpecialAvailabilities(user_data?.id);
    fetchAppointments();
  }, [
    userData,
    fetchAvailabilities,
    fetchSpecialAvailabilities,
    fetchWorkerDetails,
    user_data?.id,
    fetchAppointments,
  ]);

  // useEffect(() => {
  //   if (!token) {
  //     const storedToken = localStorage.getItem("accessToken");
  //     if (storedToken) {
  //       dispatch({
  //         type: reducerCases.SET_TOKEN,
  //         token: storedToken,
  //       });
  //     } else if (middleware === "auth") {
  //       navigate("/");
  //       return;
  //     }
  //   }

  //   if (middleware === "guest" && redirectIfAuthenticated && token) {
  //     navigate(redirectIfAuthenticated);
  //   } else if (middleware === "auth" && !token) {
  //     navigate("/");
  //   } else if (middleware === "auth" && redirectIfAuthenticated && !token) {
  //     navigate(redirectIfAuthenticated);
  //   } else if (role !== "" && user_data?.account_type !== role && token) {
  //     navigate(redirectIfAuthenticated);
  //   }
  // }, [
  //   middleware,
  //   role,
  //   redirectIfAuthenticated,
  //   token,
  //   navigate,
  //   stage_token,
  //   fetchWorkerDetails,
  //   user_data?.account_type,
  //   user_data?.id,
  //   dispatch,
  // ]);

  return {
    userData,
    fetchAvailabilities,
    fetchSpecialAvailabilities,
    fetchAppointments,
  };

};
