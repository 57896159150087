import { useLocation } from "react-router-dom";

import { uploads } from "../constants/images";

export const sendError = (error) => {
  if (error?.response?.data) {
    return error.response.data;
  }
  return { status: false, error: error.message };
};

export const formatAppointmentTime = (value) => {
  if (value) {
    const hours = Math.floor(value);
    const minutes = (value % 1) * 60;
    const period = hours >= 12 ? "pm" : "am";

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes === 0 ? "00" : minutes;

    return `${formattedHours}:${formattedMinutes}${period}`;
  } else {
    return "Not set";
  }
};

export const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const convertAvailabilityData = (data) => {
  return daysOfWeek.map((day) => ({
    day: day,
    opening: data[`${day}_opening`]
      ? parseInt(data[`${day}_opening`], 10)
      : null,
    closing: data[`${day}_closing`]
      ? parseInt(data[`${day}_closing`], 10)
      : null,
  }));
};

export const convertAvailabilityResult = (data) => {
  const result = {};
  daysOfWeek.forEach((day, index) => {
    if (data[index] && data[index].day === day) {
      result[`${day}_opening`] =
        data[index].opening !== null ? data[index].opening.toString() : "";
      result[`${day}_closing`] =
        data[index].closing !== null ? data[index].closing.toString() : "";
    } else {
      result[`${day}_opening`] = "";
      result[`${day}_closing`] = "";
    }
  });

  return result;
};

export const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

export const formatDate = (date) => {
  if (typeof date === "string" || !(date instanceof Date)) {
    date = new Date(date);
  }

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date");
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const transformBackendData = (data) => {
  return data
    .map((item) => {
      const fromDate = new Date(item.from_date);
      const toDate = new Date(item.to_date);

      if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
        console.error(
          `Invalid date format: ${item.from_date} or ${item.to_date}`
        );
        return null;
      }

      return [fromDate, toDate];
    })
    .filter((range) => range !== null);
};

export const normalizeDate = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const isLoggedIn = (tokenRes = null) => {
  if (!tokenRes) {
    return !!localStorage.getItem("token");
  }
  return !!localStorage.getItem("token");
};

export const bookingDate = [
  {
    patient: {
      name: null,
      first_name: "awe",
      middle_name: "Adus",
      last_name: "ojapiano",
      email: "Awe.petersq@gmail.com",
      mobile: "09049522077",
      mobile_status: 1,
      mobile2: "",
      occupation: "",
      gender: "Male",
      marital_status: "Single",
      nationality: "Nigeria",
      religion: "",
      password: "$2b$10$LAPBNDK4KDJSTqaMw5eGPOgG0.UffvNhe/7OV8bSh2tl37vNGFt/G",
      created: "2021-03-18 14:29:44",
      imageUrl: "8291688429195.png",
      address: "",
      state: "1",
      city: "2",
      next_of_kin_name: "",
      next_of_kin_relationship: "",
      next_of_kin_mobile: "",
      next_of_kin_address: "",
      last_login: "2024-07-09 19:12:14",
      prev_login: "2024-07-09 19:11:01",
      token: "jZsxRtBJlUwiNU1uB2cSIRhC49qJPOjnEWGoOnFhredEnJa",
      code: "",
      referral: null,
      patient_id: "PT-EMPS4T",
      status: 0,
      subscription_status: "0",
      account_type: "PHR",
      device_token: null,
      new_mobile: null,
      updated: "2024-07-09 10:46:15",
      username: null,
      dateOfBirth: null,
      country: null,
      profileImage: null,
      google_password: null,
      apple_password: null,
      apple_id: null,
      verificationToken: null,
      isVerified: 0,
      phoneVerificationToken: null,
      isPhoneVerified: 1,
      resetToken: "2311",
      pin: null,
      comment: null,
      emeregency_code: null,
      invite_code: null,
      isFirstTimeLogin: 0,
      isPinSet: 0,
      role: null,
      privileges: null,
      service_type: null,
      organisationId: null,
      deletedAt: null,
      createdAt: "2024-07-07 12:33:21",
      updatedAt: "2024-07-07 18:00:59",
      hmo: null,
      _id: 829,
    },
    carer: {
      name: null,
      first_name: "Oluwaseun",
      middle_name: "Olamilekan",
      last_name: "Shittu",
      email: "haivtech1@gmail.com",
      mobile: "90661000815",
      mobile_status: 0,
      mobile2: "09066100817",
      occupation: "",
      gender: "male",
      marital_status: "",
      nationality: "",
      religion: "",
      password: "$2b$10$HO5hrTM79heMqQqmHn3n5.vmjVkFUPbh1wANGKQY3o6AlaCmx2llu",
      created: "2024-07-09 08:45:07",
      imageUrl: null,
      address: "10, Aboderini Street, Orita Challenge",
      state: "Oyo State",
      city: "Ibadan",
      next_of_kin_name: null,
      next_of_kin_relationship: null,
      next_of_kin_mobile: null,
      next_of_kin_address: null,
      last_login: "2024-07-09 17:07:36",
      prev_login: "2024-07-09 17:06:04",
      token: null,
      code: null,
      referral: null,
      patient_id: "PT-DP3O9V",
      status: 1,
      subscription_status: null,
      account_type: "carer",
      device_token: null,
      new_mobile: null,
      updated: null,
      username: "midshot17",
      dateOfBirth: null,
      country: "Nigeria",
      profileImage: null,
      google_password: null,
      apple_password: null,
      apple_id: null,
      verificationToken: "6723",
      isVerified: 0,
      phoneVerificationToken: null,
      isPhoneVerified: 0,
      resetToken: null,
      pin: null,
      comment: null,
      emeregency_code: null,
      invite_code: null,
      isFirstTimeLogin: 0,
      isPinSet: 0,
      role: null,
      privileges: null,
      service_type: null,
      organisationId: null,
      deletedAt: null,
      createdAt: "2024-07-09 08:44:46",
      updatedAt: "2024-07-07 18:00:59",
      hmo: null,
      _id: 1145,
    },
    contract_id: "CTR-111",
    org_id: {
      organization_id: 195,
      user_id: 378,
      org_id: "ORG-610661",
      name: "TOWER OF IVORY",
      logo_url: "health-provider-1713192463.png",
      homecare_logo: "",
      telemed_logo: "195health-provider-1720437154.png",
      clinicvisit_logo: "195health-provider-1713343354.png",
      hmarket_logo: null,
      wallet_fund: null,
      bio: "organi",
      caption: "organi",
      type: "Government Agency",
      mobile: "09765523875478",
      email: "petvot.group@gmail.com",
      location_state: "3",
      location_city: "uyo",
      address: "No 25 Ogooluwa Street Okeira, Ogba",
      status: "2",
      org_balance: "32400",
      cac_no: null,
      cac_cert: "cac-cert-1713192463.png",
      work_id: "work-id-1713192463.png",
      website: "",
      hc_settings:
        '{"bio":{"basic":0,"states":"Akwa Ibom State","intro_ab":"homecare nill","intro":"homecare nill"},"plans":{"medical_visit":{"from":"1000","to":"5000"},"clinic_visit":{"from":"","to":""},"rehab":{"from":"","to":""},"li_carer":{"from":"","to":""},"lo_carer":{"from":"","to":""},"h_m_t":{"from":"","to":""}},"initialAssessment":{"doctor_assessment":{"type_of_assessment":"Doctor ","price":"10000"},"sdoctor_assessment":{"type_of_assessment":"Specialist Doctor ","price":""},"other_assessment":{"type_of_assessment":"Nurses and Other Health Professionals ","price":"20000"}}}',
      tm_settings:
        '{"amount":3000,"open":"09:39","close":"21:39","duration":"5","bio":"nillll","days":["1","2","3","4","5","6","0"],"slots":144}',
      hm_settings: null,
      cv_settings:
        '{"amount":5000,"open":"09:41","close":"21:42","duration":"5","bio":"Nill","location":"lagos","days":["1","2","4","5"],"slots":144}',
      created: "2024-04-15 15:47:43",
      bank_name: "",
      account_type: "Current",
      account_name: "",
      account_number: "",
      other_info: null,
      featured: null,
      approved: 0,
      country: null,
      service_type: null,
      createdAt: "0000-00-00 00:00:00",
      updatedAt: "0000-00-00 00:00:00",
      org_type: null,
      user: null,
    },
    service_type: {
      service_id: 4,
      name: "telemedicine",
      amount: 50000,
      duration: 365,
      about:
        "With Telemedicine, healthcare. \n providers like hospitals, HMOs, care organisations, pharmacies are able to offer patients virtual medical consultation with doctors and other licensed health workers via app, web chat, audio call or video call.\nNote: A Commission of 10% only is taken from every payment received on the platform.",
      icon: "phone",
      image: "telemedicine.jpg",
      commission: 10,
    },
    start_time: "15",
    end_time: "1:15am",
    date: "2024-07-09",
    updated_at: null,
    telemedicine_confirm: null,
    clinicvisit_confirm: null,
    user_carer: null,
    carer_amount: null,
    patient_name: null,
    patient_mobile: null,
    consultation_visit: null,
    consultation_visit_confirm: 0,
    initial_assessment_created: null,
    consultation_price: "9000",
    consultation_ref: "9EUHH6PBU",
    health_worker_type: null,
    medical_specialty: null,
    health_description: "",
    service_for: "hammed segun",
    beneficiary: '{"name":null,"address":"","mobile":""}',
    referral: "",
    care_type: null,
    consultation_address: null,
    care_category: null,
    package_id: null,
    continous_care: 0,
    no_of_visits: 0,
    no_of_visits_confirmed: 0,
    price_per_visit: null,
    status: 0,
    created: "2024-07-09 17:04:36",
    upload: null,
    mode_of_payment: null,
    duration: "15",
    consultation_type: "video",
    mode_: "Stripe",
    visit_end_date: null,
    _id: 111,
  },
  {
    patient: {
      name: null,
      first_name: "awe",
      middle_name: "Adus",
      last_name: "ojapiano",
      email: "Awe.petersq@gmail.com",
      mobile: "09049522077",
      mobile_status: 1,
      mobile2: "",
      occupation: "",
      gender: "Male",
      marital_status: "Single",
      nationality: "Nigeria",
      religion: "",
      password: "$2b$10$LAPBNDK4KDJSTqaMw5eGPOgG0.UffvNhe/7OV8bSh2tl37vNGFt/G",
      created: "2021-03-18 14:29:44",
      imageUrl: "8291688429195.png",
      address: "",
      state: "1",
      city: "2",
      next_of_kin_name: "",
      next_of_kin_relationship: "",
      next_of_kin_mobile: "",
      next_of_kin_address: "",
      last_login: "2024-07-09 19:12:14",
      prev_login: "2024-07-09 19:11:01",
      token: "jZsxRtBJlUwiNU1uB2cSIRhC49qJPOjnEWGoOnFhredEnJa",
      code: "",
      referral: null,
      patient_id: "PT-EMPS4T",
      status: 0,
      subscription_status: "0",
      account_type: "PHR",
      device_token: null,
      new_mobile: null,
      updated: "2024-07-09 10:46:15",
      username: null,
      dateOfBirth: null,
      country: null,
      profileImage: null,
      google_password: null,
      apple_password: null,
      apple_id: null,
      verificationToken: null,
      isVerified: 0,
      phoneVerificationToken: null,
      isPhoneVerified: 1,
      resetToken: "2311",
      pin: null,
      comment: null,
      emeregency_code: null,
      invite_code: null,
      isFirstTimeLogin: 0,
      isPinSet: 0,
      role: null,
      privileges: null,
      service_type: null,
      organisationId: null,
      deletedAt: null,
      createdAt: "2024-07-07 12:33:21",
      updatedAt: "2024-07-07 18:00:59",
      hmo: null,
      _id: 829,
    },
    carer: {
      name: null,
      first_name: "Oluwaseun",
      middle_name: "Olamilekan",
      last_name: "Shittu",
      email: "haivtech1@gmail.com",
      mobile: "90661000815",
      mobile_status: 0,
      mobile2: "09066100817",
      occupation: "",
      gender: "male",
      marital_status: "",
      nationality: "",
      religion: "",
      password: "$2b$10$HO5hrTM79heMqQqmHn3n5.vmjVkFUPbh1wANGKQY3o6AlaCmx2llu",
      created: "2024-07-09 08:45:07",
      imageUrl: null,
      address: "10, Aboderini Street, Orita Challenge",
      state: "Oyo State",
      city: "Ibadan",
      next_of_kin_name: null,
      next_of_kin_relationship: null,
      next_of_kin_mobile: null,
      next_of_kin_address: null,
      last_login: "2024-07-09 17:07:36",
      prev_login: "2024-07-09 17:06:04",
      token: null,
      code: null,
      referral: null,
      patient_id: "PT-DP3O9V",
      status: 1,
      subscription_status: null,
      account_type: "carer",
      device_token: null,
      new_mobile: null,
      updated: null,
      username: "midshot17",
      dateOfBirth: null,
      country: "Nigeria",
      profileImage: null,
      google_password: null,
      apple_password: null,
      apple_id: null,
      verificationToken: "6723",
      isVerified: 0,
      phoneVerificationToken: null,
      isPhoneVerified: 0,
      resetToken: null,
      pin: null,
      comment: null,
      emeregency_code: null,
      invite_code: null,
      isFirstTimeLogin: 0,
      isPinSet: 0,
      role: null,
      privileges: null,
      service_type: null,
      organisationId: null,
      deletedAt: null,
      createdAt: "2024-07-09 08:44:46",
      updatedAt: "2024-07-07 18:00:59",
      hmo: null,
      _id: 1145,
    },
    contract_id: "CTR-110",
    org_id: {
      organization_id: 195,
      user_id: 378,
      org_id: "ORG-610661",
      name: "TOWER OF IVORY",
      logo_url: "health-provider-1713192463.png",
      homecare_logo: "",
      telemed_logo: "195health-provider-1720437154.png",
      clinicvisit_logo: "195health-provider-1713343354.png",
      hmarket_logo: null,
      wallet_fund: null,
      bio: "organi",
      caption: "organi",
      type: "Government Agency",
      mobile: "09765523875478",
      email: "petvot.group@gmail.com",
      location_state: "3",
      location_city: "uyo",
      address: "No 25 Ogooluwa Street Okeira, Ogba",
      status: "2",
      org_balance: "32400",
      cac_no: null,
      cac_cert: "cac-cert-1713192463.png",
      work_id: "work-id-1713192463.png",
      website: "",
      hc_settings:
        '{"bio":{"basic":0,"states":"Akwa Ibom State","intro_ab":"homecare nill","intro":"homecare nill"},"plans":{"medical_visit":{"from":"1000","to":"5000"},"clinic_visit":{"from":"","to":""},"rehab":{"from":"","to":""},"li_carer":{"from":"","to":""},"lo_carer":{"from":"","to":""},"h_m_t":{"from":"","to":""}},"initialAssessment":{"doctor_assessment":{"type_of_assessment":"Doctor ","price":"10000"},"sdoctor_assessment":{"type_of_assessment":"Specialist Doctor ","price":""},"other_assessment":{"type_of_assessment":"Nurses and Other Health Professionals ","price":"20000"}}}',
      tm_settings:
        '{"amount":3000,"open":"09:39","close":"21:39","duration":"5","bio":"nillll","days":["1","2","3","4","5","6","0"],"slots":144}',
      hm_settings: null,
      cv_settings:
        '{"amount":5000,"open":"09:41","close":"21:42","duration":"5","bio":"Nill","location":"lagos","days":["1","2","4","5"],"slots":144}',
      created: "2024-04-15 15:47:43",
      bank_name: "",
      account_type: "Current",
      account_name: "",
      account_number: "",
      other_info: null,
      featured: null,
      approved: 0,
      country: null,
      service_type: null,
      createdAt: "0000-00-00 00:00:00",
      updatedAt: "0000-00-00 00:00:00",
      org_type: null,
      user: null,
    },
    service_type: {
      service_id: 4,
      name: "telemedicine",
      amount: 50000,
      duration: 365,
      about:
        "With Telemedicine, healthcare. \n providers like hospitals, HMOs, care organisations, pharmacies are able to offer patients virtual medical consultation with doctors and other licensed health workers via app, web chat, audio call or video call.\nNote: A Commission of 10% only is taken from every payment received on the platform.",
      icon: "phone",
      image: "telemedicine.jpg",
      commission: 10,
    },
    start_time: "15",
    end_time: "1:15am",
    date: "2024-07-09",
    updated_at: null,
    telemedicine_confirm: null,
    clinicvisit_confirm: null,
    user_carer: null,
    carer_amount: null,
    patient_name: null,
    patient_mobile: null,
    consultation_visit: null,
    consultation_visit_confirm: 0,
    initial_assessment_created: null,
    consultation_price: "9000",
    consultation_ref: "WFV5GFOUV",
    health_worker_type: null,
    medical_specialty: null,
    health_description: "",
    service_for: "hammed segun",
    beneficiary: '{"name":null,"address":"","mobile":""}',
    referral: "",
    care_type: null,
    consultation_address: null,
    care_category: null,
    package_id: null,
    continous_care: 0,
    no_of_visits: 0,
    no_of_visits_confirmed: 0,
    price_per_visit: null,
    status: 0,
    created: "2024-07-09 17:02:56",
    upload: null,
    mode_of_payment: null,
    duration: "15",
    consultation_type: "video",
    mode_: "Stripe",
    visit_end_date: null,
    _id: 110,
  },
  {
    patient: {
      name: null,
      first_name: "awe",
      middle_name: "Adus",
      last_name: "ojapiano",
      email: "Awe.petersq@gmail.com",
      mobile: "09049522077",
      mobile_status: 1,
      mobile2: "",
      occupation: "",
      gender: "Male",
      marital_status: "Single",
      nationality: "Nigeria",
      religion: "",
      password: "$2b$10$LAPBNDK4KDJSTqaMw5eGPOgG0.UffvNhe/7OV8bSh2tl37vNGFt/G",
      created: "2021-03-18 14:29:44",
      imageUrl: "8291688429195.png",
      address: "",
      state: "1",
      city: "2",
      next_of_kin_name: "",
      next_of_kin_relationship: "",
      next_of_kin_mobile: "",
      next_of_kin_address: "",
      last_login: "2024-07-09 19:12:14",
      prev_login: "2024-07-09 19:11:01",
      token: "jZsxRtBJlUwiNU1uB2cSIRhC49qJPOjnEWGoOnFhredEnJa",
      code: "",
      referral: null,
      patient_id: "PT-EMPS4T",
      status: 0,
      subscription_status: "0",
      account_type: "PHR",
      device_token: null,
      new_mobile: null,
      updated: "2024-07-09 10:46:15",
      username: null,
      dateOfBirth: null,
      country: null,
      profileImage: null,
      google_password: null,
      apple_password: null,
      apple_id: null,
      verificationToken: null,
      isVerified: 0,
      phoneVerificationToken: null,
      isPhoneVerified: 1,
      resetToken: "2311",
      pin: null,
      comment: null,
      emeregency_code: null,
      invite_code: null,
      isFirstTimeLogin: 0,
      isPinSet: 0,
      role: null,
      privileges: null,
      service_type: null,
      organisationId: null,
      deletedAt: null,
      createdAt: "2024-07-07 12:33:21",
      updatedAt: "2024-07-07 18:00:59",
      hmo: null,
      _id: 829,
    },
    carer: {
      name: null,
      first_name: "Oluwaseun",
      middle_name: "Olamilekan",
      last_name: "Shittu",
      email: "haivtech1@gmail.com",
      mobile: "90661000815",
      mobile_status: 0,
      mobile2: "09066100817",
      occupation: "",
      gender: "male",
      marital_status: "",
      nationality: "",
      religion: "",
      password: "$2b$10$HO5hrTM79heMqQqmHn3n5.vmjVkFUPbh1wANGKQY3o6AlaCmx2llu",
      created: "2024-07-09 08:45:07",
      imageUrl: null,
      address: "10, Aboderini Street, Orita Challenge",
      state: "Oyo State",
      city: "Ibadan",
      next_of_kin_name: null,
      next_of_kin_relationship: null,
      next_of_kin_mobile: null,
      next_of_kin_address: null,
      last_login: "2024-07-09 17:07:36",
      prev_login: "2024-07-09 17:06:04",
      token: null,
      code: null,
      referral: null,
      patient_id: "PT-DP3O9V",
      status: 1,
      subscription_status: null,
      account_type: "carer",
      device_token: null,
      new_mobile: null,
      updated: null,
      username: "midshot17",
      dateOfBirth: null,
      country: "Nigeria",
      profileImage: null,
      google_password: null,
      apple_password: null,
      apple_id: null,
      verificationToken: "6723",
      isVerified: 0,
      phoneVerificationToken: null,
      isPhoneVerified: 0,
      resetToken: null,
      pin: null,
      comment: null,
      emeregency_code: null,
      invite_code: null,
      isFirstTimeLogin: 0,
      isPinSet: 0,
      role: null,
      privileges: null,
      service_type: null,
      organisationId: null,
      deletedAt: null,
      createdAt: "2024-07-09 08:44:46",
      updatedAt: "2024-07-07 18:00:59",
      hmo: null,
      _id: 1145,
    },
    contract_id: "CTR-109",
    org_id: {
      organization_id: 195,
      user_id: 378,
      org_id: "ORG-610661",
      name: "TOWER OF IVORY",
      logo_url: "health-provider-1713192463.png",
      homecare_logo: "",
      telemed_logo: "195health-provider-1720437154.png",
      clinicvisit_logo: "195health-provider-1713343354.png",
      hmarket_logo: null,
      wallet_fund: null,
      bio: "organi",
      caption: "organi",
      type: "Government Agency",
      mobile: "09765523875478",
      email: "petvot.group@gmail.com",
      location_state: "3",
      location_city: "uyo",
      address: "No 25 Ogooluwa Street Okeira, Ogba",
      status: "2",
      org_balance: "32400",
      cac_no: null,
      cac_cert: "cac-cert-1713192463.png",
      work_id: "work-id-1713192463.png",
      website: "",
      hc_settings:
        '{"bio":{"basic":0,"states":"Akwa Ibom State","intro_ab":"homecare nill","intro":"homecare nill"},"plans":{"medical_visit":{"from":"1000","to":"5000"},"clinic_visit":{"from":"","to":""},"rehab":{"from":"","to":""},"li_carer":{"from":"","to":""},"lo_carer":{"from":"","to":""},"h_m_t":{"from":"","to":""}},"initialAssessment":{"doctor_assessment":{"type_of_assessment":"Doctor ","price":"10000"},"sdoctor_assessment":{"type_of_assessment":"Specialist Doctor ","price":""},"other_assessment":{"type_of_assessment":"Nurses and Other Health Professionals ","price":"20000"}}}',
      tm_settings:
        '{"amount":3000,"open":"09:39","close":"21:39","duration":"5","bio":"nillll","days":["1","2","3","4","5","6","0"],"slots":144}',
      hm_settings: null,
      cv_settings:
        '{"amount":5000,"open":"09:41","close":"21:42","duration":"5","bio":"Nill","location":"lagos","days":["1","2","4","5"],"slots":144}',
      created: "2024-04-15 15:47:43",
      bank_name: "",
      account_type: "Current",
      account_name: "",
      account_number: "",
      other_info: null,
      featured: null,
      approved: 0,
      country: null,
      service_type: null,
      createdAt: "0000-00-00 00:00:00",
      updatedAt: "0000-00-00 00:00:00",
      org_type: null,
      user: null,
    },
    service_type: {
      service_id: 4,
      name: "telemedicine",
      amount: 50000,
      duration: 365,
      about:
        "With Telemedicine, healthcare. \n providers like hospitals, HMOs, care organisations, pharmacies are able to offer patients virtual medical consultation with doctors and other licensed health workers via app, web chat, audio call or video call.\nNote: A Commission of 10% only is taken from every payment received on the platform.",
      icon: "phone",
      image: "telemedicine.jpg",
      commission: 10,
    },
    start_time: "15",
    end_time: "1:15am",
    date: "2024-07-09",
    updated_at: null,
    telemedicine_confirm: null,
    clinicvisit_confirm: null,
    user_carer: null,
    carer_amount: null,
    patient_name: null,
    patient_mobile: null,
    consultation_visit: null,
    consultation_visit_confirm: 0,
    initial_assessment_created: null,
    consultation_price: "9000",
    consultation_ref: "ADY9E68PB",
    health_worker_type: null,
    medical_specialty: null,
    health_description: "",
    service_for: "hammed segun",
    beneficiary: '{"name":null,"address":"","mobile":""}',
    referral: "",
    care_type: null,
    consultation_address: null,
    care_category: null,
    package_id: null,
    continous_care: 0,
    no_of_visits: 0,
    no_of_visits_confirmed: 0,
    price_per_visit: null,
    status: 0,
    created: "2024-07-09 17:01:37",
    upload: null,
    mode_of_payment: null,
    duration: "15",
    consultation_type: "video",
    mode_: "Stripe",
    visit_end_date: null,
    _id: 109,
  },
  {
    patient: {
      name: null,
      first_name: "Oluwaseun",
      middle_name: "Olamilekan",
      last_name: "Shittu",
      email: "haivtech1@gmail.com",
      mobile: "90661000815",
      mobile_status: 0,
      mobile2: "09066100817",
      occupation: "",
      gender: "male",
      marital_status: "",
      nationality: "",
      religion: "",
      password: "$2b$10$HO5hrTM79heMqQqmHn3n5.vmjVkFUPbh1wANGKQY3o6AlaCmx2llu",
      created: "2024-07-09 08:45:07",
      imageUrl: null,
      address: "10, Aboderini Street, Orita Challenge",
      state: "Oyo State",
      city: "Ibadan",
      next_of_kin_name: null,
      next_of_kin_relationship: null,
      next_of_kin_mobile: null,
      next_of_kin_address: null,
      last_login: "2024-07-09 17:07:36",
      prev_login: "2024-07-09 17:06:04",
      token: null,
      code: null,
      referral: null,
      patient_id: "PT-DP3O9V",
      status: 1,
      subscription_status: null,
      account_type: "carer",
      device_token: null,
      new_mobile: null,
      updated: null,
      username: "midshot17",
      dateOfBirth: null,
      country: "Nigeria",
      profileImage: null,
      google_password: null,
      apple_password: null,
      apple_id: null,
      verificationToken: "6723",
      isVerified: 0,
      phoneVerificationToken: null,
      isPhoneVerified: 0,
      resetToken: null,
      pin: null,
      comment: null,
      emeregency_code: null,
      invite_code: null,
      isFirstTimeLogin: 0,
      isPinSet: 0,
      role: null,
      privileges: null,
      service_type: null,
      organisationId: null,
      deletedAt: null,
      createdAt: "2024-07-09 08:44:46",
      updatedAt: "2024-07-07 18:00:59",
      hmo: null,
      _id: 1145,
    },
    carer: {
      name: null,
      first_name: "Oluwaseun",
      middle_name: "Olamilekan",
      last_name: "Shittu",
      email: "haivtech1@gmail.com",
      mobile: "90661000815",
      mobile_status: 0,
      mobile2: "09066100817",
      occupation: "",
      gender: "male",
      marital_status: "",
      nationality: "",
      religion: "",
      password: "$2b$10$HO5hrTM79heMqQqmHn3n5.vmjVkFUPbh1wANGKQY3o6AlaCmx2llu",
      created: "2024-07-09 08:45:07",
      imageUrl: null,
      address: "10, Aboderini Street, Orita Challenge",
      state: "Oyo State",
      city: "Ibadan",
      next_of_kin_name: null,
      next_of_kin_relationship: null,
      next_of_kin_mobile: null,
      next_of_kin_address: null,
      last_login: "2024-07-09 17:07:36",
      prev_login: "2024-07-09 17:06:04",
      token: null,
      code: null,
      referral: null,
      patient_id: "PT-DP3O9V",
      status: 1,
      subscription_status: null,
      account_type: "carer",
      device_token: null,
      new_mobile: null,
      updated: null,
      username: "midshot17",
      dateOfBirth: null,
      country: "Nigeria",
      profileImage: null,
      google_password: null,
      apple_password: null,
      apple_id: null,
      verificationToken: "6723",
      isVerified: 0,
      phoneVerificationToken: null,
      isPhoneVerified: 0,
      resetToken: null,
      pin: null,
      comment: null,
      emeregency_code: null,
      invite_code: null,
      isFirstTimeLogin: 0,
      isPinSet: 0,
      role: null,
      privileges: null,
      service_type: null,
      organisationId: null,
      deletedAt: null,
      createdAt: "2024-07-09 08:44:46",
      updatedAt: "2024-07-07 18:00:59",
      hmo: null,
      _id: 1145,
    },
    contract_id: "CTR-108",
    org_id: {
      organization_id: 195,
      user_id: 378,
      org_id: "ORG-610661",
      name: "TOWER OF IVORY",
      logo_url: "health-provider-1713192463.png",
      homecare_logo: "",
      telemed_logo: "195health-provider-1720437154.png",
      clinicvisit_logo: "195health-provider-1713343354.png",
      hmarket_logo: null,
      wallet_fund: null,
      bio: "organi",
      caption: "organi",
      type: "Government Agency",
      mobile: "09765523875478",
      email: "petvot.group@gmail.com",
      location_state: "3",
      location_city: "uyo",
      address: "No 25 Ogooluwa Street Okeira, Ogba",
      status: "2",
      org_balance: "32400",
      cac_no: null,
      cac_cert: "cac-cert-1713192463.png",
      work_id: "work-id-1713192463.png",
      website: "",
      hc_settings:
        '{"bio":{"basic":0,"states":"Akwa Ibom State","intro_ab":"homecare nill","intro":"homecare nill"},"plans":{"medical_visit":{"from":"1000","to":"5000"},"clinic_visit":{"from":"","to":""},"rehab":{"from":"","to":""},"li_carer":{"from":"","to":""},"lo_carer":{"from":"","to":""},"h_m_t":{"from":"","to":""}},"initialAssessment":{"doctor_assessment":{"type_of_assessment":"Doctor ","price":"10000"},"sdoctor_assessment":{"type_of_assessment":"Specialist Doctor ","price":""},"other_assessment":{"type_of_assessment":"Nurses and Other Health Professionals ","price":"20000"}}}',
      tm_settings:
        '{"amount":3000,"open":"09:39","close":"21:39","duration":"5","bio":"nillll","days":["1","2","3","4","5","6","0"],"slots":144}',
      hm_settings: null,
      cv_settings:
        '{"amount":5000,"open":"09:41","close":"21:42","duration":"5","bio":"Nill","location":"lagos","days":["1","2","4","5"],"slots":144}',
      created: "2024-04-15 15:47:43",
      bank_name: "",
      account_type: "Current",
      account_name: "",
      account_number: "",
      other_info: null,
      featured: null,
      approved: 0,
      country: null,
      service_type: null,
      createdAt: "0000-00-00 00:00:00",
      updatedAt: "0000-00-00 00:00:00",
      org_type: null,
      user: null,
    },
    service_type: {
      service_id: 4,
      name: "telemedicine",
      amount: 50000,
      duration: 365,
      about:
        "With Telemedicine, healthcare. \n providers like hospitals, HMOs, care organisations, pharmacies are able to offer patients virtual medical consultation with doctors and other licensed health workers via app, web chat, audio call or video call.\nNote: A Commission of 10% only is taken from every payment received on the platform.",
      icon: "phone",
      image: "telemedicine.jpg",
      commission: 10,
    },
    start_time: "15",
    end_time: "1:15am",
    date: "2024-07-09",
    updated_at: null,
    telemedicine_confirm: null,
    clinicvisit_confirm: null,
    user_carer: null,
    carer_amount: null,
    patient_name: null,
    patient_mobile: null,
    consultation_visit: null,
    consultation_visit_confirm: 0,
    initial_assessment_created: null,
    consultation_price: "9000",
    consultation_ref: "WO06428LW",
    health_worker_type: null,
    medical_specialty: null,
    health_description: "",
    service_for: "hammed segun",
    beneficiary: '{"name":null,"address":"","mobile":""}',
    referral: "",
    care_type: null,
    consultation_address: null,
    care_category: null,
    package_id: null,
    continous_care: 0,
    no_of_visits: 0,
    no_of_visits_confirmed: 0,
    price_per_visit: null,
    status: 1,
    created: "2024-07-09 17:00:17",
    upload: null,
    mode_of_payment: null,
    duration: "15",
    consultation_type: "video",
    mode_: "Stripe",
    visit_end_date: null,
    _id: 108,
  },
];

export const priceFormat = (num) => {
  const priceNumber = parseFloat(num);
  if (!isNaN(priceNumber)) {
    const formattedPrice = (Math.round(priceNumber * 100) / 100).toFixed(2);
    return "₦" + formattedPrice;
  } else {
    return "₦0";
  }
};

export const imageLoader = (value) => {
  var image =
    value !== null
      ? process.env.REACT_APP_API_LIVE_URL + "/uploads/" + value
      : uploads.avatar;
  return image;
};

export const serviceTypeData = [
  { label: "Association", value: "Association" },
  {
    label: "Ambulance Service Provider",
    value: "Ambulance Service Provider",
  },
  { label: "Care /Nursing Home", value: "Care /Nursing Home" },
  { label: "Training Provider", value: "Training Provider" },
  { label: "Government Agency", value: "Government Agency" },
  {
    label: "HMO/Health Insurance Provider",
    value: "HMO/Health Insurance Provider",
  },
  { label: "Hospital/Clinic", value: "Hospital/Clinic" },
  {
    label: "Medical/Nursing/Care Agency",
    value: "Medical/Nursing/Care Agency",
  },
  { label: "Private Company", value: "Private Company" },
  { label: "Recruitment Agency", value: "Recruitment Agency" },
  {
    label: "Ambulance Service Provider",
    value: "Ambulance Service Provider",
  },
  {
    label: "Health Technology & Logistics Company",
    value: "Health Technology & Logistics Company",
  },
  {
    label: "Health Management & Consultants",
    value: "Health Management & Consultants",
  },
  {
    label: "Healthcare Products Merchant",
    value: "Healthcare Products Merchant",
  },
  { label: "Law Firm", value: "Law Firm" },
  {
    label: "Medical Laboratory/Diagnostic Centre",
    value: "Medical Laboratory/Diagnostic Centre",
  },
  { label: "NGO/Charity", value: "NGO/Charity" },
  { label: "Pharmacy", value: "Pharmacy" },
  { label: "Rehabilitation Centre", value: "Rehabilitation Centre" },
  { label: "Recruitment Agency", value: "Recruitment Agency" },
  { label: "Educational Institution", value: "Educational Institution" },
  { label: "Faith-Based Organisation", value: "Faith-Based Organisation" },
  {
    label: "Other Corporate Organisation",
    value: "Other Corporate Organisation",
  },
];

export const categories = [
  { name: "Doctor", value: "Doctor" },
  { name: "Nurse", value: "Nurse" },
  { name: "Community Health Worker", value: "Community Health Worker" },
  { name: "Dentist", value: "Dentist" },
  { name: "Dietitian", value: "Dietitian" },
  { name: "Dispensing Optician", value: "Dispensing Optician" },
  { name: "Health Records Officer", value: "Health Records Officer" },
  { name: "Nutritionist", value: "Nutritionist" },
  { name: "Ophthalmologist", value: "Ophthalmologist" },
  { name: "Optometrist", value: "Optometrist" },
  { name: "Pharmacist", value: "Pharmacist" },
  { name: "Radiographer", value: "Radiographer" },
  { name: "Midwife", value: "Midwife" },
  { name: "Physiotherapist", value: "Physiotherapist" },
  { name: "Occupational Therapist", value: "Occupational Therapist" },
  {
    name: "Speech and Language Therapist",
    value: "Speech and Language Therapist",
  },
  { name: "Clinical Audiologist", value: "Clinical Audiologist" },
  { name: "Orthotist and Prosthetist", value: "Orthotist and Prosthetist" },
  { name: "Home Care Attendant", value: "Home Care Attendant" },
  {
    name: "Medical Laboratory Scientist",
    value: "Medical Laboratory Scientist",
  },
];

export const categoriesRank = [
  {
    name: "Doctor",
    ranks: [
      {
        name: "Consultant",
        value: "consultant",
      },
      {
        name: "Senior Registrar",
        value: "senior_registrar",
      },
      {
        name: "Registrar",
        value: "registrar",
      },
      {
        name: "Medical Officer",
        value: "medical_officer",
      },
      {
        name: "House Officer",
        value: "house_officer",
      },
    ],
  },
  {
    name: "Nurse",
    ranks: [
      {
        name: "Chief Matron",
        value: "chief_matron",
      },
      {
        name: "Matron",
        value: "matron",
      },
      {
        name: "Senior Nursing Sister",
        value: "senior_nursing_sister",
      },
      {
        name: "Nursing Sister",
        value: "nursing_sister",
      },
      {
        name: "Midwife",
        value: "midwife",
      },
      {
        name: "Staff Nurse",
        value: "staff_nurse",
      },
    ],
  },
];

export const setNextTab = (activeTab, tab, setActiveTab) => {
  const currentIndex = tab.findIndex((tabItem) => tabItem.key === activeTab);
  if (currentIndex === -1) {
    return;
  }
  const nextIndex = (currentIndex + 1) % tab.length;
  setActiveTab(tab[nextIndex].key);
};

export const setPrevTab = (activeTab, tab, setActiveTab) => {
  const currentIndex = tab.findIndex((tabItem) => tabItem.key === activeTab);
  if (currentIndex === -1) {
    return;
  }
  const previousIndex = (currentIndex - 1 + tab.length) % tab.length;
  setActiveTab(tab[previousIndex].key);
};

export const drugs = [
  {
    name: "Paracetamol",
    value: "Paracetamol",
  },
  {
    name: "Aspirin",
    value: "Aspirin",
  },
  {
    name: "Lumafetrin",
    value: "Lumafetrin",
  },
  {
    name: "Ibuprofen",
    value: "Ibuprofen",
  },
  {
    name: "Amoxicillin",
    value: "Amoxicillin",
  },
  {
    name: "Metformin",
    value: "Metformin",
  },
  {
    name: "Omeprazole",
    value: "Omeprazole",
  },
  {
    name: "Atorvastatin",
    value: "Atorvastatin",
  },
  {
    name: "Lisinopril",
    value: "Lisinopril",
  },
  {
    name: "Hydrochlorothiazide",
    value: "Hydrochlorothiazide",
  },
];

export const  ScrollTo = window.scrollTo(0, 0);

export const ValidateForm = (data, keys) => {
  for (let key of keys) {
    if (key === "doc_Specialty" && data["category"].toLowerCase() !== "doctor") {
      continue; 
    }

    if (
      !data[key] ||
      (typeof data[key] === "string" && data[key].trim() === "") ||
      (Array.isArray(data[key]) && data[key].length === 0) ||
      (typeof data[key] === "object" &&
        !Array.isArray(data[key]) &&
        Object.keys(data[key]).length === 0)
    ) {
      return {
        status: false,
        message: `${key} is required and cannot be empty.`,
      };
    }
  }

  return {
    status: true,
    message: "All fields are valid.",
  };
};


export const getAppointmentDuration = (start, end) => {
  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
      throw new Error("Invalid time format: " + time); 
    }
    return hours * 60 + minutes;
  };

  const startInMinutes = convertTimeToMinutes(start);
  const endInMinutes = convertTimeToMinutes(end);

  let duration = endInMinutes - startInMinutes;

  if (duration < 0) {
    duration += 24 * 60;
  }

  return duration;
};