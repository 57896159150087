import React, { useState, useEffect } from "react";

import Table, { StatusPill, StatusPillHw, ActionImage } from "../../Table";

import axiosInstance from "../../services/axiosconfig/AxiosAuth";

function Apps() {
  const [loading, setLoading] = useState(true);
  const [hWorker, setData] = useState([]); // Initialize as an empty object

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin/cooperate/get-all-health-worker`;
    axiosInstance
      .get(apiUrl)
      .then((response) => {
        console.log("response", response);
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the Axios GET request:", error);
        setLoading(false);
      });
  }, []);

  const hwData = hWorker?.data || []; // Guard against missing properties

  console.log("hw", hwData);

  const columns = React.useMemo(
    () => [
      {
        Header: "S/n",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Carer Id",
        accessor: "carer_id",
      },
      {
        Header: "Phone Number",
        accessor: "mobile",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "Specialty",
        accessor: "specialty",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: StatusPillHw,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ActionImage,
      },
    ],
    []
  );

  return (
    <div className="min-h-screen bg-white text-black mt-6">
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-2 pt-4">
        <div className="">
          <h1 className="text-xl font-semibold font-Roboto ">Health Workers</h1>
        </div>
        <div className="mt-6">
          <Table columns={columns} data={hwData} />
        </div>
      </main>
    </div>
  );
}

export default Apps;
