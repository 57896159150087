import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { FaEye, FaFileAlt } from "react-icons/fa";
import { MdAddIcCall } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppointmentContext } from "./AppointmentProvider";
import { appointmentDataIcons, images } from "../../constants/icons";
import { reducerCases } from "../../manager/constants";
import { useStateProvider } from "../../manager/StateContext";
import ReportModal from "../Modal/ReportModal";

import {
  getAppointmentDuration,
  priceFormat,
  sendError,
} from "../../utils/helpers";
import { appointmentHeader } from "../../services/data/corporateData";

const AppointmentTable = ({ data, className }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [{ token }, dispatch] = useStateProvider();

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const {
    selectedBooking,
    errors,
    inputs,
    setInputs,
    previews,
    setPreviews,
    handleFileChange,
    handleInputChange,
    handleBlur,
    handleFileDelete,
    handleReportClick,
    validateInput,
    reportModal,
  } = useAppointmentContext();

  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleViewClick = (id, contract_id) => {
    navigate(`/health-worker-appointment/${contract_id}`);
  };

  const handleStartClick = async (id, contract_id) => {
    setIsLoading(true);
    dispatch({
      type: reducerCases.SET_MESSAGE,
      state_message: "Starting up meeting please wait...",
    });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/appointment-google/link/${id}`,
        config
      );

      const { data } = response;

      if (data?.data) {
        dispatch({
          type: reducerCases.SET_MESSAGE,
          state_message: "Meeting initialized and starting...",
        });

        setTimeout(() => {
          dispatch({
            type: reducerCases.SET_MESSAGE,
            state_message: "",
          });
          setIsLoading(false);

          window.open(data?.data?.link, "_blank");
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: reducerCases.SET_MESSAGE,
        state_message: "",
      });
      const message = sendError(error);
      console.log(message);
      toast.error("There was an error starting the meeting...");
    }
  };

  const dropdownOption = [
    {
      name: "View",
      icon: <FaEye size="10" />,
      function: handleViewClick,
    },
    {
      name: "Start Meeting",
      icon: <MdAddIcCall size="10" />,
      function: handleStartClick,
    },
    {
      name: "Report",
      icon: <FaFileAlt size="10" />,
      function: handleReportClick,
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownIndex(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex-1 w-full sm:w-[900px] mt-[10px] sm:mt-[30px]">
        <div className="bg-bgbutton/60 flex justify-between p-[20px] rounded-t-[8px] overflow-x-auto custom-scrollbar">
          <p className="font-semibold text-[16px] text-white">Appointments</p>
          <button className="bg-[#F2F2F2] w-[148px] p-[10px] rounded-lg flex justify-between ">
            <p className="text-[10px] font-medium"> Latest Appointment</p>
            <p className="mt-2 mr-2">
              <img src={appointmentDataIcons.dropdownIcon} alt="" />
            </p>
          </button>
        </div>

        <div className="overflow-x-auto overflow-y-auto custom-scrollbar h-screen">
          <table className="w-full sm:w-[900px]">
            <thead className="">
              <tr className="whitespace-nowrap bg-bgbutton text-white">
                {appointmentHeader &&
                  appointmentHeader.map((item, idx) => (
                    <td
                      key={idx}
                      className="text-[13px] px-5 font-medium whitespace-nowrap text-left"
                    >
                      {item.title}
                    </td>
                  ))}
              </tr>
            </thead>
            <tbody
              className="bg-white"
              // onClick={() => navigateToView(data)}
            >
              {data?.length > 0 ? (
                data?.map((item, idx) => (
                  <tr
                    className="whitespace-nowrap my-2 cursor-pointer"
                    key={idx}
                    onClick={() =>
                      navigate(
                        `/health-worker-appointment/${item?.contract_id}`
                      )
                    }
                  >
                    <td className="w-[170.757px] px-5">
                      <div className="flex items-center space-x-2 py-2">
                        <div>
                          <p className="text-[13px] font-medium">
                            {item?.patient?.first_name}{" "}
                            {item?.patient?.last_name}{" "}
                          </p>
                          <p className="text-[10px]">
                            {item?.service_type?.name}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-[10px] font-medium">
                      {item?.contract_id}
                    </td>
                    <td className="text-[10px] font-medium ">
                      {priceFormat(item?.consultation_price)}
                    </td>
                    <td className="text-[10px] font-medium ">
                      {item?.start_time}
                    </td>
                    <td className="text-[10px] font-medium">
                      {getAppointmentDuration(item?.start_time, item?.end_time)}{" "}
                      mins
                    </td>
                    <td className="text-[10px] font-medium">{item?.date}</td>
                    <td className="flex justify-start items-center">
                      <div className="mt-2">
                        {item?.telemedicine_confirm !== "1" ? (
                          <span className="bg-amber-500/10 px-2 py-1 rounded-full text-amber-500 text-xs">
                            Pending
                          </span>
                        ) : (
                          <span className="bg-green-500/10 px-2 py-1 rounded-full text-green-500 text-xs">
                            Completed
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="pl-6">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(idx);
                        }}
                        className="relative"
                      >
                        <img src={images.verticalIcon} alt="" />

                        {openDropdownIndex === idx && (
                          <div className="absolute top-0 right-3 z-50 bg-gray-100 rounded-lg w-[120px] px-3 py-2 shadow-sm">
                            <div>
                              {dropdownOption.map((option, index) => (
                                <div
                                  onClick={() => {
                                    option.function(
                                      item?._id,
                                      item?.contract_id
                                    );
                                    console.log("id", item?._id);
                                    console.log(
                                      "contract_id",
                                      item?.contract_id
                                    );
                                  }}
                                  key={index}
                                  className={`flex flex-row items-center py-1 ${
                                    index !== dropdownOption.length - 1
                                      ? "border-b border-white"
                                      : ""
                                  }`}
                                >
                                  {option.icon}
                                  <p className="text-[10px] font-semibold ml-2">
                                    {option.name}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="9"
                    className="text-center text-sm font-bold p-10"
                  >
                    You have no bookings yet. Once a client books you, the
                    details will appear here.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {reportModal && (
          <ReportModal
            id={selectedBooking}
            title="Appointment Report"
            description="Submit appointment report for patient"
            buttonText="Submit"
            onConfirm={validateInput}
            onClose={handleReportClick}
            isLoading={isLoading}
            inputs={inputs}
            setInputs={setInputs}
            previews={previews}
            setPreviews={setPreviews}
            handleInputChange={handleInputChange} //
            handleFileChange={handleFileChange} //
            handleBlur={handleBlur} //
            handleFileDelete={handleFileDelete} //
            errors={errors}
            selectedBooking={selectedBooking}
          />
        )}
      </div>
    </>
  );
};

export default AppointmentTable;
