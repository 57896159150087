import { createSlice } from "@reduxjs/toolkit";
import { EmergencyCode } from "../actions/formAction";

const initialState = {
    loading:false,
    error: null,
    success:false,
    code: null,

};

const EmergencySlice = createSlice({
    name: 'emergencyCode',
    initialState,
    reducers: {},
    setDatas: (state, { payload }) => {
        state.code = payload
    },

    extraReducers: {
        [EmergencyCode.pending]: (state) => {
            state.loading = true
            state.error =  null

        },

        [EmergencyCode.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.code = payload
        },

        [EmergencyCode.rejected]: (state, {payload}) => {
            state.loading = false;
            state.error = payload
        }

    }
    
})

export const { setDatas } = EmergencySlice.actions
export default EmergencySlice.reducer;
