import React, { useState } from "react";

import { useStateProvider } from "../../../../manager/StateContext";
import WithdrawModal from "../../../Modal/WithdrawModal";

import { priceFormat } from "../../../../utils/helpers";

const PaymentHealthWorker = () => {
  const [{ health_worker_detail }] = useStateProvider();
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleWithdrawlClick = () => {
    setWithdrawModal(!withdrawModal);
  };

  return (
    <div className="w-[230px]">
      <div>
        <div>
          <div className="flex justify-between  text-[12px]">
            <div>
              <p className="text-textgray py-2">Current Balance</p>
            </div>
            <div>
              <p className="text-[#333333] font-medium">
                {priceFormat(health_worker_detail?.carer_balance)}
              </p>
            </div>
          </div>

          <div className="flex justify-between  text-[12px]">
            <div>
              <p className="text-textgray py-2">Total Withdrawn</p>
            </div>
            <div>
              <p className="text-[#333333] font-medium">
                {priceFormat(health_worker_detail?.carer_balance)}
              </p>
            </div>
          </div>

          <div className="flex justify-between  text-[12px]">
            <div>
              <p className="text-textgray py-2">Pending Withdrawal</p>
            </div>
            <div>
              <p className="text-[#333333] font-medium">
                {priceFormat(health_worker_detail?.carer_balance)}
              </p>
            </div>
          </div>
          <div className="mt-2 flex justify-end">
            <button
              onClick={handleWithdrawlClick}
              className="bg-bgbutton px-2 py-1 rounded-full text-[10px] font-semibold text-white shadow-sm"
            >
              Withdraw
            </button>

            {withdrawModal && (
              <WithdrawModal
                isLoading={isLoading}
                setOpen={handleWithdrawlClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHealthWorker;
