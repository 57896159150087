import React, { ReactNode, useState } from "react";

export const Tooltips = ({ message, children }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="relative flex flex-col items-center group">
      <span className="flex justify-center" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {children}
      </span>
      <div className={`absolute whitespace-nowrap bottom-full flex flex-col items-center   group-hover:flex ${!show ? "hidden" : null}`}>
        <span className="relative z-10 py-4 w-auto h-auto text-Poppins text-sm leading-none text-white  bg-gray-600 px-2 rounded-md">
          {message}
        </span>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600" />
      </div>
    </div>
  );
};