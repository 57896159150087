import React from 'react'

const FormField = ({placeholder, name, label, type, value,  onChange, className }) => {
    return (
      <div>
        <label htmlFor={name} className="font-medium font-Poppins">
          {label}
        </label>
          <input
            placeholder={placeholder}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            className={className}
          />
      </div>
    );
  };

export default FormField;