import React from "react";
import { createBrowserRouter, createHashRouter, RouterProvider } from "react-router-dom";

import BroadcastMessage from "../pages/admin-broadcast/BroadcastMessage";
import AdminDashboard from "../pages/admin-dashboard/AdminDashboard";
import PhrDashboard from "../pages/admin-phr-dashboard/PhrDashboard";
import UserProfile from "../pages/admin-user-profile/UserProfile";
import AllHealthworkers from "../pages/admin-users/AllHealthworkers";
import AllOrganisation from "../pages/admin-users/AllOrganisation";
import AllUsers from "../pages/admin-users/AllUsers";
import ClientDashborad from "../pages/client-dashboard/ClientDashborad";
import CorpoporateAdmin from "../pages/corporate-dashboard/CorpoporateAdmin";
import CorporateAdminDashboard from "../pages/corporate-dashboard/CorporateAdminDashboard";
import CorporateEmergency from "../pages/corporate-dashboard/emergency";
import CorporateOrgProfile from "../pages/corporate-org-profile/CorporateOrgProfile";
import Dashboard from "../pages/dashboard/SuperAdminDashboard";
import SuperAdminLandingPage from "../pages/dashboard/SuperAdminLandingPage";
import Emergency from "../pages/emergency-health-info/Emergency";
import UserEmergency from "../pages/emergency-health-info/UserEmergency";
import HealthWorkerAppointment from "../pages/health-worker-dashboard/HealthWorkerAppointment";
import HealthWorkerAppointmentView from "../pages/health-worker-dashboard/HealthWorkerAppointmentView";
import HealthWorkerDashboard from "../pages/health-worker-dashboard/HealthWorkerDashboard";
import HealthWorkerEditProfile from "../pages/hw-profile-setup/HealthWorkerEditProfile";
import HealthWorkerProfile from "../pages/hw-profile-setup/HealthWorkerProfile";
import ProfileCompleted from "../pages/hw-profile-setup/ProfileCompleted";
//users routes
import HomePage from "../pages/Landing-page/HomePage";
import VerifyOtp from "../pages/Landing-page/VerifyOtp";
import MmbLogin from "../pages/super-admin-login/MmbLogin";
import AccountSetup from "../pages/user-account-setup/AccountSetup";
import { useAuth } from "../authProvider";
import { useStateProvider } from "../manager/StateContext";

import { ProtectedRoute } from "../utils/ProtectedRoute";

const RoutesManager = () => {
  const { token } = useAuth;

  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        //Routes for Super and Sub Admins
        { path: "/dashboard", element: <SuperAdminLandingPage /> },
        { path: "/services/phr", element: <PhrDashboard /> },
        { path: "/user-profile", element: <UserProfile /> },
        { path: "/corporate-landing-page", element: <CorpoporateAdmin /> },
        {
          path: "/corporate-admin-dashboard",
          element: <CorporateAdminDashboard />,
        },
        {
          path: "/corporate-admin-dashboard/emergency",
          element: <CorporateEmergency />,
        },
        {
          path: "/health-worker-dashboard",
          element: <HealthWorkerDashboard />,
        },
        {
          path: "/health-worker-appointment",
          element: <HealthWorkerAppointment />,
        },
        {
          path: "/health-worker-appointment/:id",
          element: <HealthWorkerAppointmentView />,
        },
        { path: "/all-users/user-profile/:id", element: <UserProfile /> },
        { path: "/corporate-landing-page", element: <CorpoporateAdmin /> },
        { path: "/super-admin-dashboard", element: <Dashboard /> },
        { path: "/client-dashboard", element: <ClientDashborad /> },
        { path: "/admin-dashboard", element: <AdminDashboard /> },
        { path: "/broadcast", element: <BroadcastMessage /> },
        { path: "/all-users", element: <AllUsers /> },
        { path: "/all-organisation", element: <AllOrganisation /> },
        { path: "/all-HealthWorkers", element: <AllHealthworkers /> },
        { path: "/set-up", element: <AccountSetup /> },
        { path: "/user/health-worker", element: <HealthWorkerProfile /> },
        { path: "/user/health-worker/:id", element: <HealthWorkerProfile /> },
        { path: "/health-worker/completed", element: <ProfileCompleted /> },
        { path: "/user/corporate", element: <CorporateOrgProfile /> },
        {
          path: "/health-workers/health-worker-profile/:id",
          element: <HealthWorkerProfile />,
        },
        {
          path: "/health-workers/health-worker-edit-profile/:id",
          element: <HealthWorkerEditProfile />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    //Routes for Admim
    { path: "/admin", element: <MmbLogin /> },
    { path: "/", element: <HomePage /> },
    { path: "/emergency", element: <Emergency /> },
    { path: "/emergency/user/:sessionId", element: <UserEmergency /> },
    { path: "/verify-otp", element: <VerifyOtp /> },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createHashRouter([
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default RoutesManager;
