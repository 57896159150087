import React, { useState, useEffect } from "react";

import ProfileSuccess from "../../components/Users/HealthWorkerProfile/ProfileSuccess";
import UserProfileHeader from "../../components/Users/ProfileSetup/UserProfileHeader";

const ProfileCompleted = () => {
  useEffect(() => {
    localStorage.removeItem("formData");
    localStorage.removeItem("appointmentData");
    localStorage.removeItem("bankData");
    localStorage.removeItem("kinData");
    localStorage.removeItem("consultationData");
    localStorage.removeItem("refData");
  }, []);

  return (
    <div>
      <UserProfileHeader Breadcrumb="Profile Setup Completed" />
      <div className="">
        <ProfileSuccess />
      </div>
    </div>
  );
}

export default ProfileCompleted