import React, { useState } from "react";
import axios from "axios";

import { reducerCases } from "../manager/constants";
import { useStateProvider } from "../manager/StateContext";

import { sendError } from "../utils/helpers";

const Selector = ({ type, title, data, inputs, handleChange, contract_id, selectedData }) => {
  const [{ stage_token }, dispatch] = useStateProvider();
  const [searchQuery, setSearchQuery] = useState("");
  const [items, setItems] = useState(data);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCreateData = async (value) => {
    try {
      const data = {
        name: value,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${stage_token}`,
        },
      };

      var url;

      if(type === 'drugs'){
        url = `${process.env.REACT_APP_API_LIVE_URL}/api/medication`;
      }else{
        url = `${process.env.REACT_APP_API_LIVE_URL}/api/medication-test`;
      }
    
      axios
        .post(url, { ...data }, config)
        .then((response) => {
          const { data } = response;
          console.log(data);
          dispatch({
            type: reducerCases.SET_MESSAGE_STATUS,
            message_status: {
              type: "success",
              message: data.message,
            },
          });
        })
        .catch((error) => {
          const message = sendError(error);
          console.log(message);
        });
    } catch (error) {
      const message = sendError(error);
      console.log(message);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const exists = items.some(
        (item) => item.name.toLowerCase() === searchQuery.toLowerCase()
      );
      if (!exists && searchQuery.trim() !== "") {
        const newItem = { name: searchQuery, value: searchQuery };
        setItems([...items, newItem]);
        handleCreateData(searchQuery);
        setSearchQuery("");
      }
    }
  };

  const filteredItems = items?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleUpdate = (name) => {
    const currentItems = inputs[type] || [];
    if (currentItems.includes(name)) {
      handleChange(
        currentItems.filter((item) => item !== name),
        type
      );
    } else {
      handleChange([...currentItems, name], type);
    }
  };

  return (
    <div className="mt-2">
      <h5 className="font-bold text-[12px] mb-1">{title}</h5>

      <div className="border border-gray-400 rounded-lg">
        <div className="">
          <input
            type="search"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            placeholder={`Search or press enter to add more ${
              title.toLowerCase().split(" ")[1]
            }`}
            className="block w-full px-2 py-1 text-base placeholder-sm font-normal text-gray-700  bg-clip-padding border-b-2 border-gray-300 rounded-md transition ease-in-out m-0 focus:border-b-2 focus:border-bgbutton outline-none duration-150 text-[10px]"
          />
        </div>

        <div className="p-2 h-[70px] overflow-y-auto custom-scrollbar grid grid-cols-3 sm:grid-cols-5 gap-2">
          {filteredItems?.map((item, index) => (
            <div key={index} className="flex items-center space-x-1">
              <div>
                <input
                  type="checkbox"
                  onChange={() => handleUpdate(item.name)}
                  checked={
                    (inputs[type] || []).includes(item.name) ||
                    (selectedData && selectedData.includes(item.name))
                  }
                />
              </div>
              <p className="font-bold text-[10px]">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Selector;
