import React, { useState } from "react";

import { ClockIcon } from "@heroicons/react/solid";

import { formatAppointmentTime } from "../../utils/helpers";
import { colors } from "../../utils/style";

const TimeModal = ({
  title,
  description,
  buttonText,
  onConfirm,
  onClose,
  data,
  handleOnChange,
  handleChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditFinish = (e) => {
    const { value } = e.target;
    var date = new Date(value);
    handleChange({
      ...data,
      to_date: date,
    });

    if (!isNaN(date.getTime())) {
      const formattedDate = date.toISOString().split("T")[0];
      console.log(formattedDate);

      handleChange((data) => ({
        ...data,
        to_date: formattedDate,
      }));
    } else {
      console.error("Invalid date");
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false);
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  return (
    <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
        <div className="mt-3">
          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-blue-100 rounded-full">
            <ClockIcon size={10} color={colors.primary} />
          </div>
          <div className="mt-2 text-center sm:ml-4 sm:text-left">
            <h4 className="text-md underline font-medium text-gray-800">{title}</h4>
            <p className="text-[12px] leading-relaxed text-gray-500">
              {description}
            </p>

            <div className="my-2">
              {data.from_date && (
                <p>
                  <b>From Date: </b>
                  {data.from_date}
                </p>
              )}

              {data.to_date !== "" && (
                <div className="mt-2">
                  {isEditing ? (
                    <div>
                      <label className="font-medium font-Poppins">
                        To Date
                      </label>
                      <input
                        name="to_date"
                        type="date"
                        className="block w-full px-3 py-2 text-base placeholder-sm font-normal text-gray-700  bg-clip-padding border border-gray-300 rounded-md transition ease-in-out m-0 focus:border-0 focus:ring-primary-700 duration-150"
                        value={data?.to_date}
                        onChange={(e) => handleEditFinish(e)}
                        onKeyPress={handleInputKeyPress}
                        onBlur={handleInputBlur}
                        autoFocus
                      />
                    </div>
                  ) : (
                    <div onClick={handleEditClick}>
                      <p>
                        <b>To Date: </b>
                        {data.to_date}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>

            <p className="text-xs text-red-500 text-left">
              Slide to change open and closing time{" "}
            </p>

            <div className="flex flex-row items-center justify-between mb-3">
              <div className="mr-3">
                <label className="text-sm font-light flex flex-col items-center">
                  <div className="mr-2 flex flex-row items-center space-x-2">
                    <p className="text-left text-[10px] font-semibold">
                      Opening Time:
                    </p>
                    <p> {formatAppointmentTime(data.from_time)}</p>
                  </div>
                  <div>
                    <input
                      type="range"
                      name="from_time"
                      min="0"
                      max="24"
                      step="1"
                      value={data.from_time}
                      onChange={handleOnChange}
                      className="h-2 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-bgbutton"
                    />
                  </div>
                </label>
              </div>

              <div>
                <label className="text-sm font-light flex flex-col items-center">
                  <div className="mr-2 flex flex-row items-center space-x-2">
                    <p className="text-left text-[10px] font-semibold">
                      Closing Time:
                    </p>
                    <p> {formatAppointmentTime(data.to_time)}</p>
                  </div>

                  <div>
                    <input
                      type="range"
                      name="to_time"
                      min="0"
                      max="24"
                      step="1"
                      value={data.to_time}
                      onChange={handleOnChange}
                      className="h-2 bg-gray-400 rounded-lg appearance-none cursor-pointer accent-bgbutton"
                    />
                  </div>
                </label>
              </div>
            </div>

            <div className="items-center gap-3 mt-3 sm:flex">
              <button
                className="w-full mt-2 p-2.5 flex-1 text-white bg-blue-600 rounded-md outline-none ring-offset-2 ring-blue-600 focus:ring-2"
                onClick={onConfirm}
              >
                {buttonText}
              </button>
              <button
                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeModal;
