import React, { useState } from "react";
import axios from "axios";
import toast, { LoaderIcon } from "react-hot-toast";
import { FaMoneyBill, FaTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";

import { reducerCases } from "../../manager/constants";
import { useStateProvider } from "../../manager/StateContext";
import StatusMessage from "../StatusMessage";
import TextInput from "../TextInput";

import { priceFormat, sendError } from "../../utils/helpers";

const WithdrawModal = ({ setOpen }) => {
  const [{ stage_token, health_worker_detail }, dispatch] = useStateProvider();

  const [generating, setGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    token: "",
    amount: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (
      parseInt(health_worker_detail?.carer_balance) <
        parseInt(formData.amount) ||
      parseInt(formData.amount) > parseInt(health_worker_detail?.carer_balance)
    ) {
      toast.error("Sorry you cannot perform this transaction");
    } else {
      Swal.fire({
        title: "Confirm withdrawal",
        text: `Are you sure you want to place a withdrawal of ${priceFormat(
          formData.amount
        )} from your account balance of ${priceFormat(
          health_worker_detail?.carer_balance
        )}?`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        cancelButtonColor: "red",
        confirmButtonColor: "#00aaff",
        customClass: {
          title: "swal2-title-custom",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            const config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${stage_token}`,
              },
            };

            setIsLoading(true);
            const url = `${process.env.REACT_APP_API_LIVE_URL}/api/provider-withdrawal`;
            axios
              .post(url, { ...formData }, config)
              .then((response) => {
                setIsLoading(false);
                const { data } = response;
                if (data.status === 100) {
                  toast.success(data.message);
                  setOpen();
                }
              })
              .catch((error) => {
                setIsLoading(false);
                const message = sendError(error);
                toast.error(message.message);
              });
          } catch (error) {
            setIsLoading(false);
            const message = sendError(error);
            toast.error(message.message);
          }
        }
      });
    }
  };

  const handleGenerate = async () => {
    try {
      setGenerating(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${stage_token}`,
        },
      };

      const url = `${process.env.REACT_APP_API_LIVE_URL}/api/withdrawal/code`;
      const response = await axios.post(url, { ...formData }, config);
      const { data } = response;

      if (data.status.code === 100) {
        setGenerating(false);
        dispatch({
          type: reducerCases.SET_MESSAGE_STATUS,
          message_status: {
            type: "success",
            message: data.status.desc,
          },
        });
      } else {
        setGenerating(false);
      }
    } catch (error) {
      setGenerating(false);
    }
  };

  return (
    <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
        <div className="mt-1">
          <div className="flex items-center justify-center flex-none w-10 h-10 mx-auto bg-blue-100 rounded-full">
            <FaMoneyBill size="20" color="#00aaff" />
          </div>
          <div className="mt-2 text-center sm:ml-4 sm:text-left">
            <h4 className="text-lg font-medium text-gray-800">
              Withdraw Earnings
            </h4>
            <p className="text-[12px] leading-relaxed text-gray-500">
              Hey Medical Expert! 👨‍⚕️👩‍⚕️ complete your withdrawal by filling the
              following!
            </p>

            <StatusMessage />

            <div className="my-2">
              <div>
                <div className="flex items-center space-x-3">
                  <div>
                    <button onClick={handleGenerate} className="button">
                      <div className="flex items-center space-x-3">
                        <p>
                          {generating && <LoaderIcon size="20" color="white" />}
                        </p>
                        <p>Generate Token</p>
                      </div>
                    </button>
                  </div>
                  <div className="flex-1">
                    <TextInput
                      placeholder="Enter Token"
                      type="text"
                      id="token"
                      name="token"
                      value={formData.token}
                      onChange={handleChange}
                      className="w-[150px] focus:ring-bgbutton focus:border-bgbutton"
                    />
                  </div>
                </div>

                <div className="mt-2">
                  <TextInput
                    label="Enter Amount"
                    type="number"
                    id="amount"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
                  />
                </div>

                <div className="flex items-center justify-evenly space-x-4 mt-4">
                  <button
                    onClick={handleSubmit}
                    className="flat-button"
                    type="submit"
                  >
                    <div className="flex items-center justify-center space-x-2">
                      {isLoading && <LoaderIcon size="20" color="white" />}
                      <p>Submit</p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute top-2 right-2">
          <button onClick={setOpen}>
            <FaTimesCircle size="20" color="red" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;
