import React, { useEffect, useState } from 'react'
import axios from 'axios';
import toast, { LoaderIcon } from 'react-hot-toast';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import Swal from "sweetalert2";

import { useStateProvider } from '../../manager/StateContext';
import TextInput from '../TextInput';

import { sendError, setNextTab } from '../../utils/helpers';
import { colors } from '../../utils/style';

const Pricing = ({
  activeTab,
  tab,
  setActiveTab,
  isLoading,
  setIsLoading,
  handlePrev,
}) => {
  const [{ stage_token }] = useStateProvider();

  const initailData = JSON.parse(localStorage.getItem("consultationData")) || {
    consultation_amount: "",
    duration: "15",
  };
  const [consultationData, setConsultationData] = useState(initailData);
   useEffect(() => {
     localStorage.setItem("consultationData", JSON.stringify(consultationData));
   }, [consultationData]);

  const handleConsultationChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setConsultationData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleUpdateConsultation = () => {
    Swal.fire({
      title: "Update Pricing",
      text: "Are you sure you want to update your pricing?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      cancelButtonColor: "red",
      confirmButtonColor: "#00aaff",
      customClass: {
        title: "swal2-title-custom",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${stage_token}`,
            },
          };

          setIsLoading(true);
          const url = `${process.env.REACT_APP_API_LIVE_URL}/api/telemedicine/hw-settings`;
          axios
            .put(url, { ...consultationData }, config)
            .then((response) => {
              setIsLoading(false);
              const { data } = response;
              if (data.status.code === 100) {
                toast.success(data.status.desc);
                setNextTab(activeTab, tab, setActiveTab);
              }
            })
            .catch((error) => {
              setIsLoading(false);
              const message = sendError(error);
              console.log(message);
              toast.error(message.message);
            });
        } catch (error) {
          setIsLoading(false);
          const message = sendError(error);
          toast.error(message.error);
        }
      }
    });
  };

  return (
    <div className="mx-5 flex-1">
      <h5 className="text-[15px] font-semibold">Pricing</h5>
      <p className="text-[12px]">
        Note 10% will be deducted from each consultation bookings.
      </p>

      <div className="my-2 grid grid-cols-1 sm:grid-cols-2 sm:gap-4">
        <div className="">
          <label className="text-[10px] font-bold">
            What's you minimum consultation time(mins)?
          </label>
          <select
            name="duration"
            value={consultationData.duration}
            onChange={handleConsultationChange}
            className="block w-full px-3 py-2 text-base placeholder-sm font-normal text-gray-700  bg-clip-padding border border-gray-300 rounded-md transition ease-in-out m-0 focus:ring-bgbutton duration-150"
          >
            <option value="">Select</option>
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="60">60</option>
            <option value="120">120</option>
          </select>
        </div>

        <div>
          <TextInput
            label="Minimum Consultation Fee(₦):"
            type="number"
            id="consultation_amount"
            name="consultation_amount"
            value={consultationData.consultation_amount}
            onChange={handleConsultationChange}
            className="w-[150px] focus:ring-bgbutton focus:border-bgbutton number-input"
          />
        </div>
      </div>

      <div className="flex items-center justify-between mt-4 w-full">
        <div className="flex cursor-pointer items-center space-x-2 mr-2">
          <div onClick={handlePrev} className="flex items-center space-x-1">
            <FaArrowAltCircleLeft size={25} color={colors.primary} />
            <p className="font-bold">Back</p>
          </div>
        </div>

        <button onClick={handleUpdateConsultation} className="button">
          <div className="flex items-center space-x-3">
            {isLoading && <LoaderIcon size="20" color="white" />}
            <p>Update</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Pricing