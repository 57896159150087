import { createSlice } from "@reduxjs/toolkit";
import { VerifyEmail } from "../auth/authAction/authAction";

const initialState = {
    loading:false,
    error: null,
    success:false,
    emailDetails: null,

};

const VerifyOTPSlice = createSlice({
    name: 'verifyUserEmail',
    initialState,
    reducers: {},
    setDatas: (state, { payload }) => {
        state.emailDetails = payload
    },

    extraReducers: {
        [VerifyEmail.pending]: (state) => {
            state.loading = true
            state.error =  null

        },

        [VerifyEmail.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.emailDetails = payload
        },

        [VerifyEmail.rejected]: (state, {payload}) => {
            state.loading = false;
            state.error = payload
        }

    }
    
})

export const { setDatas } = VerifyOTPSlice.actions
export default VerifyOTPSlice.reducer;
