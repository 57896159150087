import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { LoaderIcon } from "react-hot-toast";
import { FaBookMedical, FaCheckDouble, FaTimes, FaTrash } from "react-icons/fa";

import { healthWorkerIcons } from "../../constants/icons";
import { useStateProvider } from "../../manager/StateContext";
import Selector from "../Selector";
import StatusMessage from "../StatusMessage";
import TextareaInput from "../TextareaInput";

import { sendError } from "../../utils/helpers";

const ReportModal = ({
  title,
  description,
  buttonText,
  onConfirm,
  onClose,
  isLoading,
  inputs,
  setInputs,
  handleInputChange,
  handleFileChange,
  handleBlur,
  errors,
  selectedBooking,
  handleFileDelete,
}) => {
  const [{ drugs, tests, stage_token }] = useStateProvider();
  const [report, setReport] = useState({});

  const getSingleAppointmentDetail = useCallback(async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_LIVE_URL}/dashboard/telemedicine-booking/${selectedBooking}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${stage_token}`,
            },
          }
        )
        .then((response) => {
          const { data } = response;
          const reportData = data.reportDetails;
          reportData.upload = JSON.parse(reportData.upload);
          setReport(reportData);
          setInputs((prev) => ({
            ...prev,
            summary: reportData.summary,
            recommend: reportData.recommendation,
            drugs: reportData?.medication,
            test: reportData?.test,
          }));
        })
        .catch((error) => {
          const message = sendError(error);
          console.log(message);
        });
    } catch (error) {
      const message = sendError(error);
      console.log(message);
    }
  }, [selectedBooking, stage_token]);

  useEffect(() => {
    getSingleAppointmentDetail();
  }, [getSingleAppointmentDetail]);

  return (
    <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-xl p-4 mx-2 bg-white rounded-md shadow-lg h-[630px] overflow-y-auto custom-scrollbar">
        <div className="mt-1">
          <div className="flex items-center justify-center flex-none w-10 h-10 mx-auto bg-blue-100 rounded-full">
            <FaBookMedical size="20" color="#00aaff" />
          </div>
          <div className="mt-2 text-center sm:ml-4 sm:text-left">
            <h4 className="text-lg font-medium text-gray-800">
              {title} for {selectedBooking}
            </h4>
            <p className="text-[12px] leading-relaxed text-gray-500">
              {description}
            </p>

            <div>
              <StatusMessage />
            </div>

            <div className="my-2">
              <div className="mt-2">
                <TextareaInput
                  value={inputs?.summary}
                  label=""
                  name="summary"
                  type="text"
                  placeholder="Summary"
                  rows="1"
                  error={errors.summary}
                  onChange={(e) => handleInputChange(e.target.value, "summary")}
                  onBlur={(e) => handleBlur(e.target.value, "summary")}
                />
              </div>
              <div className="mt-2">
                <TextareaInput
                  value={inputs?.recommend}
                  label=""
                  name="recommend"
                  type="text"
                  placeholder="What is your recommendation?"
                  rows="2"
                  error={errors.recommend}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "recommend")
                  }
                  onBlur={(e) => handleBlur(e.target.value, "recommend")}
                />
              </div>

              <div className="">
                <div>
                  <label className="font-semibold max-w-xl text-[10px] text-black">
                    <span className="text-[#D60000]">*</span>
                    <i> Attach Report Document </i>
                    <span>(Select multiple documents once)</span>
                  </label>
                  <div className="bg-[#F3F3F3] w-full h-[50px] border-[1px] rounded-xl m-auto">
                    <label
                      htmlFor="fileInput-license"
                      className="cursor-pointer"
                    >
                      <img
                        src={healthWorkerIcons.dashicons}
                        className="px-[10rem] py-2 items-center"
                        alt=""
                      />
                      <p className="text-center text-[#47A7FD] text-sm cursor-auto"></p>
                    </label>
                    <input
                      id="fileInput-license"
                      name="upload[]"
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  <h1 className="text-red-500 text-[10px] py-2">
                    PDF, JPEG & PNG are accepted. File should be within 10mb.
                  </h1>

                  <div className="grid grid-cols-2 items-center relative gap-2">
                    {inputs.upload.map((file, index) => {
                      const isImage = file.type.startsWith("image/");
                      return (
                        <div
                          key={index}
                          className="relative flex items-center gap-3"
                        >
                          <div className="">
                            <FaCheckDouble size="10" color="blue" />
                          </div>
                          {isImage ? (
                            <div>
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`file-preview-${index}`}
                                width={50}
                                height={50}
                                className="rounded-lg"
                              />
                            </div>
                          ) : (
                            <p className="text-[8px]">{file.name}</p>
                          )}
                          <div className="">
                            <FaTrash
                              size={10}
                              color="red"
                              onClick={() => handleFileDelete(index)}
                              className="cursor-pointer"
                            />
                          </div>
                          {index % 2 === 0 &&
                            index !== inputs.upload.length - 1 && (
                              <div className="absolute right-0 top-0 h-full w-[1px] bg-gray-300"></div>
                            )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="">
                <Selector
                  type="drugs"
                  title="Prescribe Medication"
                  data={drugs}
                  inputs={inputs}
                  handleChange={handleInputChange}
                  contract_id={selectedBooking}
                  selectedData={inputs?.drugs}
                />

                <Selector
                  type="test"
                  title="Recommend Test"
                  data={tests}
                  inputs={inputs}
                  handleChange={handleInputChange}
                  contract_id={selectedBooking}
                  selectedData={inputs?.test}
                />
              </div>
            </div>

            <div className="items-center gap-3 mt-3 flex">
              <button
                className="w-full mt-2 p-2.5 flex-1 text-white bg-blue-600 rounded-md outline-none ring-offset-2 ring-blue-600 focus:ring-2"
                onClick={onConfirm}
              >
                {isLoading ? (
                  <div className="flex justify-center">
                    <LoaderIcon size={20} color="white" />
                  </div>
                ) : (
                  <p> {buttonText}</p>
                )}
              </button>
              <button
                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-red-600 p-2 rounded-full"
        >
          <FaTimes size="12" color="white" />
        </button>
      </div>
    </div>
  );
};

export default ReportModal;
